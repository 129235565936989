import { useState } from "react";
import '../css/cseDeptStyle.css';
const MechDeptPage = () => {

  const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
  const [peopleTab, setPeopleTab] = useState("Faculty");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    console.log(tabId)
  }

  const handlePeopleTabClick = (tabId) => {
    setPeopleTab(tabId);
    console.log(tabId)
  }
  return (
    <><div className="dept-wrapper">
      <div className="container">
        <section className="py-4 header">
          <header>
            {/* */}
            {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" className="img-fluid"/>*/}
            <img
              src="https://nitw.ac.in/api/static/files/Mechanical_Dept_banner_2023-10-11-16-5-29.jpg" className="img-fluid" />
          </header>
          <div className="row">
            <div className="col-md-3">
              {/* Tabs nav */}
              <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                aria-orientation="vertical">
                {/*<a className="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                <a className={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                  role="tab" aria-controls="v-pills-visionandmission"
                  aria-selected="false">
                  <span>About</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                  id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                  aria-controls="v-pills-academics" aria-selected="false">
                  <span>Academic Programmes</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                  role="tab" aria-controls="v-pills-Faculty" aria-selected="false">
                  <span>People </span>
                </a>


                <a className={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                  role="tab" aria-controls="v-pills-NotableAlumni"
                  aria-selected="false">
                  <span>Notable Alumni </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                  id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                  aria-controls="v-pills-Students" aria-selected="false">
                  <span>Students Society </span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                  role="tab"
                  aria-controls="v-pills-AcademicResearchGroup" aria-selected="false"> <span>Academic Research
                    Group</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                  role="tab" aria-controls="v-pills-Awards"
                  aria-selected="false"> <span>Awards and Honours</span> </a>
                {/*<a className="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected="false"><span>Research Scholars</span></a>*/}
                <a className={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                  aria-controls="v-pills-labs" aria-selected="false">
                  <span>Laboratory & Facilities</span>
                </a>
                <a className={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                  role="tab" aria-controls="v-pills-PhotoGallery"
                  aria-selected="false"> <span>Photo Gallery</span> </a>

              </div>
              <div className="head_of_dept"> <a href="https://erp.nitw.ac.in/ext/profile/me-yrk"
                target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/me-yrk" alt="" className="img-fluid" width="190" height="220" />
                <h6>Prof. Y Ravi Kumar</h6>
              </a>
                <p className="justified-text"> Head of the Department <br /> <a href="mailto:mech_hod@nitw.ac.in"
                  style={{ color: "#808080" }}>mech_hod@nitw.ac.in</a> <br /> 9490165343 </p>
              </div>
            </div>
            <div className="col-md-9">
              {/* Tabs content */}
              <div className="tab-content" id="v-pills-tabContent">
                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                <div className={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                  role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                  <div className="intro-section">
                    {/* <h4 className=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}
                    <p className="justified-text">

                      The Department of Mechanical Engineering is one of the oldest departments of the National Institute of Technology, Warangal (NITW). Established as one of the major departments of the Institute, since its inception in 1959, the Department of Mechanical Engineering has been actively engaged in teaching and research in diverse fields of Mechanical Engineering. With excellent faculty, the Department of Mechanical Engineering offers Under Graduate (B.Tech.) and Post Graduate (M.Tech) in Automobile Engineering, Computer Integrated Manufacturing, Additive Manufacturing, Machine Design, Manufacturing engineering, Materials and System Engineering Design & Thermal Engineering and research (Ph.D) programmes.

                    </p>
                  </div>
                  {/* NUMBERING START*/}
                  <div class="row nirf-ranking-counter text-center">



                    <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                      <div class="">
                        <h2 class="mb-0"><span class="counter" style={{ color: "#c20315" }}>53</span></h2>

                        <h6 class="mt-0"><b>Faculty</b></h6>
                      </div>
                    </div>

                    <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                      <div class="">
                        <h2 class="mb-0"><span class="counter" style={{ color: "#c20315" }}>21</span></h2>
                        <h6 class="mt-0"><b>Staff</b></h6>
                      </div>
                    </div>

                    <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                      <div class="">
                        <h2 class="mb-0"><span class="counter" style={{ color: "#c20315" }}>98</span></h2>
                        <h6 class="mt-0"><b>PhD Research Scholars</b></h6>
                      </div>
                    </div>


                    <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                      <div class="">
                        <h2 class="mb-0"><span class="counter" style={{ color: "#c20315" }}>553</span></h2>
                        <h6 class="mt-0"><b>Undergraduate Students</b></h6>
                      </div>
                    </div>


                    <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                      <div class="">
                        <h2 class="mb-0"><span class="counter" style={{ color: "#c20315" }}>282</span></h2>
                        <h6 class="mt-0"><b>Postgraduate Students</b></h6>
                      </div>
                    </div>


                    <div class="col-lg-2 col-6 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1200">
                      <div class="">
                        <h2 class="mb-0"><span class="counter" style={{ color: "#c20315" }}>969</span></h2>
                        <h6 class="mt-0"><b>Publications</b></h6>
                      </div>
                    </div>



                  </div>
                  <div className="row vision-mission-section">
                    <div className="col-md-6 vision-section">
                      <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/vision.png" className="img-fluid mr-3"
                        alt="" /> Vision</h4>
                      <p className="  mb-4">To be a global knowledge hub in mechanical engineering education, research, entrepreneurship and industry outreach services.</p> <img src="CSEDeptAssets/images/cse.jpg" alt=""
                        className="img-fluid" />
                    </div>
                    <div className="col-md-6 mission-section">
                      <h4 className="mt-2 mb-2"><img src="CSEDeptAssets/images/mission.png" className="img-fluid mr-3"
                        alt="" /> Mission</h4>
                      <p className="  mb-2">Impart quality education and training to nurture globally competitive mechanical engineers.</p>
                      <p className="  mb-2">Provide vital state-of-the-art research facilities to create, interpret, apply and disseminate knowledge.</p>
                      <p className="  mb-2">Develop linkages with world class educational institutions and R&D organizations for excellence in teaching, research and consultancy services.</p>
                    </div>
                  </div>
                  <div className="text-center">
                    <h4 className="mt-2 mb-2 notice-board1">
                      <a href="https://www.nitw.ac.in/page/?url=/NoticeBoardME/MechanicalEngineering" > Notices/Announcements</a>
                    </h4>
                  </div>
                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                  aria-labelledby="v-pills-academics-tab">
                  {/*<h4 className="mb-4">Academic Programmes</h4>*/}
                  {/*<div className="row">
  <div className="col-md-12">
    <h3 className="mb-3">PhD</h3>
  </div>
</div>*/}
                  <div className="row">


                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>B.Tech</h3>
                            <p className="justified-text">

                              B. Tech. in Mechanical Engineering is the flagship programme of the Department. The graduate mechanical engineers apply scientific principles to the analysis, design and synthesis of various products and processes. The range of products include: automobiles, airplanes, electronics, surgical instruments, toys, building products, sports and recreational equipment etc. In all the cases, mechanical engineers design the products with the required functionality to high quality standards at the best possible price and in ways that are environmentally-friendly.

                            </p>

                            <p className="justified-text">

                              B. Tech. in Mechanical Engineering is existing from the inception of the Institute in the year 1959. This programme is of 4 years duration (8 semesters). The course work in the first two semesters is common to all branches of engineering. The core Mechanical Engineering courses are introduced from the 3rd semester onwards. Besides, elective courses are offered from 5thsemester onwards. The electives permit one to choose his/her area of interest within the broad areas. The laboratories are equipped with state of the art equipment to impart necessary hands-on practice. The students also carry out project work during the last two semesters based on the knowledge acquired.

                            </p>



                          </div>
                          <p className="more"> <a className="more-info-link"
                            href="https://nitw.ac.in/api/static/files/Mechanical_Engineering_2023-10-9-15-59-33.pdf" target="_blank"> B.Tech Scheme and Syllabus <i className="fa fa-angle-right"></i> </a> </p>

                        </div>
                      </div>
                    </div>


                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            {/* <h3>Postgraduate Program</h3>
                                                        <p className="justified-text">The Department of Chemical Engineering offers two Postgraduate Programs – M. Tech. in Chemical Engineering and M.Tech. in Systems and Control Engineering. From 2021-2022 academic year, the department has started a PG Diploma course in Systems and Control Engineering .</p> */}
                            {/*<img src="CSEDeptAssets/images/mtech1.jpg" alt="" className="img-fluid"/>*/}
                            <h3>M.Tech (Automobile Engineering) </h3>
                            <p className="justified-text">
                              The programme was started in the academic year 2008-2009. The programme covers the basic subjects related to Automobile Engineering such as Prime Movers, Automobile Body Structures, Vehicle Dynamics, Automotive Materials, Sensors and Actuators and Manufacturing Processes, Advanced CAD and so on. The first two semesters cover course work facilitating the students to learn both fundamentals and recent advances in various core and elective subjects supported by laboratory courses, which include Automotive Engines Laboratory, CAD Laboratory and Simulation Laboratory. The third and fourth semesters are earmarked for dissertation Work. Students are generally encouraged to do their projects in the industry, wherever there are chances of exposure to various avenues in Automobile Engineering. Those, who work in the Institute, take up both theoretical (analytical or numerical) and experimental dissertation works.                                                         </p>

                          </div>
                          <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/media/uploads/2021/08/29/5_automobile-engineering.pdf"
                            target="_blank">M.Tech (Automobile Engineering) Scheme and Syllabus <i
                              className="fa fa-angle-right"></i> </a> </p>

                          {/* <p className="more"> <a className=" more-info-link"
                                                        href="CSEDeptAssets/docs/re-reivsed-m-tech-cse-syllabus-20-8-21v10.pdf"
                                                        target="_blank"> M.Tech CSE Scheme and Syllabus <i
                                                            className="fa fa-angle-right"></i> </a> </p> */}

                        </div>
                      </div>
                    </div>


                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>M.Tech (Computer Integrated Manufacturing)</h3>
                            <p className="justified-text"> Individual processes to exchange information with each other and initiate actions. Through the integration of computers, manufacturing can be faster and less error-prone, although the main advantage is the ability to create automated manufacturing processes. In a CIM system functional areas such as design, analysis, simulation, planning, and purchasing, cost accounting, and scheduling, inventory control, and distribution, product life cycle management, supply chain management are linked through the computer with factory floor functions such as materials handling and management, providing direct control and monitoring of all the operations. To achieve automated manufacturing CIM uses technologies like FMS, ASRS, AGV, Robotics, Mechatronics, automated conveyor systems and computer aided techniques like CAD, CAE, CAM, CAPP, CAQ, PPC, ERP, a business system integrated by a common database. After through discussions with Industries and experts, the need for a graduate course on C.I.M is recognized and thus an M.Tech program in C.I.M is introduced in the MED in the year 2000 with due permission of AICTE.
                            </p>

                          </div>
                          <p className="more"> <a className="more-info-link"
                            href="https://nitw.ac.in/media/uploads/2021/08/29/3_computer-integraed-manufacturing.pdf"
                            target="_blank"> M.Tech (Computer Integrated Manufacturing) Scheme and Syllabus<i className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>M.Tech (Additive Manufacturing)</h3>
                            <p className="justified-text">
                              Additive Manufacturing is an emerging technology revolutionizing the manufacturing industry today. This technology is employed to build products in almost every sector. One of the striking features of this technology is that complexity of part is no more a concern during manufacturing. This technology is expected to grow rapidly in future.
                            </p>

                            <p className="justified-text">
                              The Department started M. Tech. in “Additive Manufacturing” for the first time in India in the year 2014 in collaboration with Central Manufacturing Technology Institute (CMTI), Bangalore. CMTI is an R & D organization and plays the role of being a catalyst and a key player in manufacturing technology growth in the country.
                            </p>
                          </div>
                          <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/media/uploads/2021/08/29/7_additive-manufacturing.pdf"
                            target="_blank"> M.Tech (Additive Manufacturing) Scheme and Syllabus   <i
                              className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>M.Tech (Machine Design)</h3>
                            <p className="justified-text">
                              The global industries have realized that the ability to consistently define and deliver products to the market place more rapidly and efficiently than their competitors can become a source of sustainable competitive advantage. These companies have moved their view to product design and development capability as an end to end business process that can be greatly enhanced through reengineering and can be continuously improved through total quality management.                                                         </p>

                            <p className="justified-text">
                              In this environment in the year 2006 the Department of Mechanical Engineering NIT Warangal started an M.Tech Programme titled Machine Design to prepare the human resources.                                                         </p>

                            <p className="justified-text">
                              The first two semesters cover course work facilitating the students to learn both fundamentals and recent advances in various core and elective subjects supported by laboratory courses, which include Numerical Simulation Laboratory, CAD Laboratory, Computer Aided Analysis Laboratory and Rapid Prototyping Laboratory. The third and fourth semesters are earmarked for Dissertation Work. Students are generally encouraged to do their projects in industries, wherever there are chances of exposure to various avenues. Those, who work in the Institute, take up both theoretical (analytical or numerical) and experimental dissertation works. The students are able to publish their work in journals or conferences of repute.

                            </p>

                            <p className="justified-text">

                              The primary objective of the programme is to train the students in Machine Design field by imparting broad based training on the state of the art engineering design methods analysis tools and manufacturing processes. With this training we expect the students to become powerful practitioners of product designers. The knowledge gained by the students in this course is an effective and practical, immediately applicable to today’s design challenges in the industry.

                            </p>
                          </div>



                          <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/media/uploads/2021/08/29/4_machine-design.pdf"
                            target="_blank"> M.Tech (Machine Design) Scheme and Syllabus   <i
                              className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>M.Tech (Manufacturing Engineering)</h3>
                            <p className="justified-text">
                              Manufacturing engineers apply scientific principles to the production of various products. The range of products include: automobiles, airplanes, tractors, electronics, surgical instruments, toys, building products, sports and recreational equipment etc. In all the cases, manufacturing engineers design the products with the required functionality, to high quality standards at the best possible price and in ways that are environmentally-friendly.
                            </p>
                            <p className="justified-text">
                              The Department started M.Tech in “Design and Production of Machine Tools” in the year 1966 and it was renamed as Manufacturing Engineering in the year 2000. In the first two semesters the students study relevant core courses and a good number of elective courses. The electives permit one to choose his/her area of interest within the broad spectrum of courses .The laboratories are equipped with state of the art equipment to impart necessary hands-on practice. Last two semesters are dedicated for the dissertation work relevant to the course.
                            </p>
                          </div>



                          <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/media/uploads/2021/08/29/2_manufacturing-engineering.pdf"
                            target="_blank"> M.Tech (Manufacturing Engineering) Scheme and Syllabus   <i
                              className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>M.Tech (Materials And Systems Engineering Design)</h3>
                            <p className="justified-text">
                              The Department of Mechanical Engineering, NIT Warangal in the year 2013 has started M.Tech. programme in Materials and System Engineering Design(MSED) in collaboration with Nonferrous Materials Technology Development Centre (NFTDC), a research laboratory based at Hyderabad. NFTDC is an Advanced Technology Centre, actively involved in high technology research in the areas such as (i) aerospace (ii) energy (conventional and nonconventional), (iii) automotive, (iv) biomedical, (v) process engineering and (vi) environment sectors.                                                        </p>
                            <p className="justified-text">
                              The basic core competence of the M.Tech. programme is (a) Design, (b) Advanced Materials & Manufacturing and (c) Controls and Instrumentation which have been integrated in all its research projects to render technology solutions in an end to end paradigm.</p>
                            <p className="justified-text">
                              The primary objective of the programme is to train the student in selection of material and its processing, and control systems used in system engineering design such as energy systems: fuel cells / battery, automatic system: ECU of hybrid vehicle, Biomedical test rig of knee simulator and so on.

                            </p>
                            <p className="justified-text">
                              The present programme being of joint nature has to be of full time nature only with mandatory course work at NITW for first two semesters followed by third and fourth semester project work at NFTDC. The Lab sessions will be conducted at NFTDC.

                            </p>
                          </div>

                          <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/media/uploads/2021/08/29/6_materials-and-systems-engineering-design.pdf"
                            target="_blank"> M.Tech (Materials And Systems Engineering Design) Scheme and Syllabus   <i
                              className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>





                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>M.Tech (Thermal Engineering)</h3>
                            <p className="justified-text">
                              This programme was being offered with the title “Design and Production of IC Engines and Gas Turbines” till the year 2000. The programme emphasizes application of fundamental principles of Thermal Engineering for various applications, which include Steam Power Plants, Gas Turbine Power Plants, Petroleum Refineries, Solar Appliances, Cooling of Electronic Equipment and Devices, Automobiles, I. C. Engines, Refrigeration Plants, Air-Conditioning Systems, Cold Storage Units, Propulsion Systems and so on. The first two semesters cover course work facilitating the students to learn both fundamentals and recent advances in various core and elective subjects supported by laboratory courses, which include Computational Fluid Dynamics Laboratory, Thermal Engineering Laboratory and Energy Systems Laboratory. The third and fourth semesters are earmarked for Dissertation Work. Students are generally encouraged to do their projects in industries, wherever there are chances of exposure to various avenues in Thermal Engineering. Those, who work in the Institute, take up both theoretical (analytical or numerical) and experimental dissertation works. The students are able to publish their work in journals or conferences of repute.

                            </p>

                          </div>

                          <p className="more"> <a className="more-info-link" href="https://nitw.ac.in/media/uploads/2021/08/29/1_thermal-engineering.pdf"
                            target="_blank"> M.Tech (Thermal Engineering) Scheme and Syllabus   <i
                              className="fa fa-angle-right"></i> </a> </p>
                        </div>
                      </div>
                    </div>











                    <div className="col-md-6 col-sm-12">
                      <div className="card-4">
                        <div className="card-detail">
                          <div className="head-bx">
                            <h3>Ph.D</h3>
                            <p className="justified-text">
                              The department offers full time and part time Ph.D programmes in Mechanical Engineering. The admission to Ph.D programme takes place in both odd and even semesters of an academic year. The notification for the Ph.D admissions is advertised in the institute website as well as in leading national news papers.  The minimum qualification and other details for the aspiring students  can be obtained from the advertisement .  The candidates are selected through a written exam and/or interview. All the regular Ph.D students are paid stipend by the Institute. The department has been recognized for Ph.D programme under QIP.
                            </p>

                            {/*<img src="CSEDeptAssets/images/phd.jpg" alt="" className="img-fluid"/>*/}
                          </div>
                          {/* <p className="more"> <a className="buttton more-info-link"
                                                        href="CSEDeptAssets/docs/phd-rules-and-regulations-2021-22.pdf"
                                                        target="_blank"> Rules and Regulations of Ph.D. Program <i
                                                            className="fa fa-angle-right"></i> </a> </p> */}
                        </div>
                      </div>
                    </div>

                  </div>

                </div>




                <div className={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                  aria-labelledby="v-pills-Phd-tab">
                  <h4 className=" mb-4">Phd</h4> <a href="" target="_blank"> <span className="highlight">P</span>h.D.
                    Students List ( Full / Part Time) <i className="fa fa-link " aria-hidden="true"></i> </a>
                </div>
                <div className={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                  aria-labelledby="v-pills-Faculty-tab">
                  <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">


                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-faculty"
                      aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }}>Faculty</a>




                    {/* <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                                            id="nav-AdjunctFaculty-tab" data-toggle="tab" 
                                            role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty</a>  */}
                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                      id="nav-AdjunctFaculty-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-AdjunctFaculty" aria-selected="false" onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty/ Visiting Professor</a>


                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                      id="nav-NonTeachingStaff-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-NonTeachingStaff" aria-selected="false" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}>Non-Teaching
                      Staff</a>


                    <a className={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                      data-toggle="tab" role="tab"
                      aria-controls="nav-ResearchScholars" aria-selected="false" onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a>


                  </div>


                  <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                    style={{ marginTop: "5%", marginLeft: "1%" }}>
                    <div className={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                      aria-labelledby="nav-faculty-tab">
                      {/*<h4 className=" mb-4">Faculty</h4>*/}
                      {/* <a href="" target="_blank"><span className="highlight">F</span>aculty Profiles <i className="fa fa-external-link" aria-hidden="true"></i></a>*/}
                      {/* <section id="profile-info">

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16278"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16278/16278.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16278"
                                    target="_blank">Prof. Adepu Kumar</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9492783067 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:adepu_kumar7@nitw.ac.in">adepu_kumar7@nitw.ac.in</a> </p>
                                  <p className="justified-text">Friction stir welding/Processing, Additive Manufacturing, Electrical Discharge Machining of Titanium and super alloys , Fabrication and characterisation of Micro and Nano composites.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-anant"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17063/17063.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-anant"
                                    target="_blank">Prof. Anant Kumar Rai</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9548285567 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:anant@nitw.ac.in  ">anant@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Fluid mechanics, Hydraulic turbines, Turbomachinery, Hydropower, Renewable Energy, Material wear testing, Sediment Transport.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-avbabu"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16270/16270.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-avbabu"
                                    target="_blank">Prof. A. Veeresh Babu </a>
                                  </h5> <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9441120203<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:avbabu@nitw.ac.in "> avbabu@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text">  IC Engines, Alternate Fuels, Emissions control, Fuel cells, Refrigeration and Air-conditioning</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-satishben"
                              target="_blank" className="img-fluid"> <img
                                src="https://wsdc.nitw.ac.in/facultynew/media/16439/16439.jpg" alt="" className="img-fluid" width="150" height="180" /> </a>
                            </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-satishben"
                                    target="_blank">Prof. B. Satish Ben</a> </h5>
                                  <span>Professor </span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9848065254 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:satishben@nitw.ac.in ">satishben@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text"> Structural Health Monitoring, FRP Composites, Self-healing polymer composites, and Adhesive Bonding</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-bangaru"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16257/16257.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-bangaru"
                                    target="_blank">Prof. Bangarubabu Popuri</a> </h5>
                                  <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9440206342 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:bangaru@nitw.ac.in ">bangaru@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Mechanical Vibrations; Vibration Control; Finite Element Analysis; Mechanism Science; Engineering Design, Rotor Dynamics, Vehicle Dynamics</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-vpcm80"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16436/16436.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-vpcm80"
                                    target="_blank">Prof. Chandramohan V. P.</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i>  8332969329 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vpcm80@nitw.ac.in">vpcm80@nitw.ac.in </a> </p>
                                  <p className="justified-text">(i) Solar drying (ii) Solar Updraft Tower (iii) Computational Fluid Dynamics (iv) Convection and conduction heat transfer (v) Drying and Simultaneous solution of heat and mass transfer (vi) Renewable Energy Sources
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17022"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17022/17022.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17022"
                                    target="_blank">Prof. Chinige Sampath Kumar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8331071528 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:drsam@nitw.ac.in ">drsam@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">High Heat Flux Cooling Applications; Jet Impingement Cooling; Porous Media; Lattice Boltzmann Method.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-djayakrishna"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16435/16435.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-djayakrishna"
                                    target="_blank">Prof. Devanuri Jaya Krishna</a> </h5>
                                  <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969369 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:djayakrishna@nitw.ac.in"> jayakrishna@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> Phase Change Materials;Utilization of Solar energy;Computational Fluid Dynamics (CFD);Thermal Energy Storage;Heat transfer in porous media;Thermal management of batteries/electronic components
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17020"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17020/17020.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17020"
                                    target="_blank">Prof. Gangadharudu Talla</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9440747257  <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:gtalla@nitw.ac.in ">gtalla@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Modern machining processes, Hybrid machining, Modelling and optimization. </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16260"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16260/16260.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16260"
                                    target="_blank">Prof. G. Amba Prasad Rao</a> </h5>
                                  <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969315 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:gap@nitw.ac.in">gap@nitw.ac.in</a>
                                  </p>
                                  <p className="justified-text"> IC Engines; Engine Exhaust emission control;Alternate fuels;HCCI Combustion.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16277"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16277/16277.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16277"
                                    target="_blank">Prof. G. Naga Srinivasulu</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9440509659 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:gns@nitw.ac.in">gns@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text">PEM Fuel Cells; I.C.Engines; Heat Transfer and Turbo machines</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16266"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16266/16266.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16266"
                                    target="_blank">Prof. Gupta G R K</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9392231833 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:gupta@nitw.ac.in ">gupta@nitw.ac.in
                                    </a> </p>
                                  <p className="justified-text"> CFD, Turbomachines, Jet propulsion.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>






                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16533"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16533/16533.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16533"
                                    target="_blank">Prof. Gujjala Raghavendra</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9985803317 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:raghavendra.gujjala@nitw.ac.in  "> raghavendra.gujjala@nitw.ac.in
                                    </a> </p>
                                  <p className="justified-text"> Composites, Nanomaterials, Tribology, Polymer, FGM.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>











                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-gaurav"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17018/17018.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-gaurav"
                                    target="_blank">Prof. Gaurav Kumar Sharma</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9611241015 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:gaurav@nitw.ac.in ">gaurav@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text">- </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17076"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17076/17076.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17076"
                                    target="_blank">Prof. Hari Krishna Chilukoti</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9014029382 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:chk@nitw.ac.in ">chk@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> Nanoscale heat transfer, Mass transfer, Interfacial phenomenon, 2D materials, Evaporation, Molecular dynamics, Phase change materials. </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16420"
                              target="_blank"> <img src="https://nitw.ac.in/api/static/files/Dr._Hari_Kumar_Voruganti_2023-10-27-17-13-46.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16420"
                                    target="_blank">Prof. Hari Kumar Voruganti</a>
                                  </h5> <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8985929103 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:harikumar@nitw.ac.in  "> harikumar@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Shape and Topology Optimization for Design, Geometric Modeling for CAD, Finite Element / Isogeometric Analysis, Robotics (Robot Kinematics and Path Planning), Artificial Intelligence for Design.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16275"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16275/16275.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16275"
                                    target="_blank">Prof. Joseph Davidson M</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969324 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:jd@nitw.ac.in  ">
                                      jd@nitw.ac.in   </a> </p>
                                  <p className="justified-text">Metal Forming, CAD/CAM, Wire Arc Additive Manufacturing. </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16513"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16513/16513.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16513"
                                    target="_blank">Prof. Karthik Balasubramanian</a>
                                  </h5> <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969370
                                    <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:karthikb@nitw.ac.in"> karthikb@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text">Microchannel Flow Boiling; Phase Change Heat Transfer; Microfluidics; Thermal management; Solar Thermal; Solar PV and Hybrid systems; HVAC systems.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16419"
                              target="_blank"> <img src="https://nitw.ac.in/api/static/files/Dr._Kiran_Kumar_K_2024-3-5-18-10-37.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16419"
                                    target="_blank">Prof. Kiran Kumar K</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9494153851
                                    <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:kiran@nitw.ac.in  "> kiran@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Thermal Engineering;Heat Transfer; Nanofluids; Thermal Management of Electronics, Refrigeration & Air-Conditioning; CFD; Non-Conventional Energy sources. </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16251"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16251/16251.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16251"
                                    target="_blank">Prof. Kotha Madhu Murthy</a> </h5>
                                  <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9492416560
                                    <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:madhu@nitw.ac.in  "> madhu@nitw.ac.in  </a> </p>
                                  <p className="justified-text"> Thermal Engineering; IC Engines; Alternate Fuels; Entrepreneurship; Industrial and Small Enterprise Management</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16253"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16253/16253.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16253"
                                    target="_blank">Prof. Krishnanand Lanka</a>
                                  </h5> <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i>  8702459757 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: lanka@nitw.ac.in  ">  lanka@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> Industrial Engineering ; CAD/CAM ; Supply Chain Management ; Additive Manufacturing.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17017"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17017/17017.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17017"
                                    target="_blank">Prof. Manjaiah M</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i>  9740847669 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: Manjaiah.m@nitw.ac.in  ">  Manjaiah.m@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> Metal Additive Manufacturing; Advanced Manufacturing processes ; Post processing of AM parts; Shape Memory Alloys.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17068"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17068/17068.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17068"
                                    target="_blank">Prof. Marrapu Bhargava</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9004669353 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:bhargava@nitw.ac.in "> bhargava@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Metal forming - sheet metal forming- formability evaluation and prediction; Hydroforming; Formability at elevated temperatures; Mechanical behaviour of materials; Heat treatment processes; Material model development</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16261"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16261/16261.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16261"
                                    target="_blank">Prof. N. Selvaraj</a>
                                  </h5> <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969316 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:selva@nitw.ac.in"> selva@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Modeling and Simulation, Flexible Manufacturing System, CNC Technology, Machine Tool, Additive Manufacturing, Composite Materials, and Pull systems</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>





                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-rnrao"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16263/16263.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-rnrao"
                                    target="_blank">Prof. Narasimha Rao R</a>
                                  </h5> <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969373 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:rnrao@nitw.ac.in "> rnrao@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Tribology, Kinematics & Dynamics,  Machine Design, Vibrations, FEM, Advanced Composites</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-subhash"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16273/16273.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-subhash"
                                    target="_blank">Prof. P Subhash Chandra Bose</a>
                                  </h5> <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969405 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:subhash@nitw.ac.in"> subhash@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> 1. Machining of high temperature and super alloys2. CNC technologies3. Optimization techniques4. Fabrication and machining of ceramic composites5. Additive manufacturing 6. Laser Assisted Machining of MMC, Ceramics Composite, Super Alloys. 7. Characterization of composites</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16522"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16522/16522.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16522"
                                    target="_blank">Prof. P Vamsi Krishna</a>
                                  </h5> <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9440843609 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vamsikrishna@nitw.ac.in "> vamsikrishna@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Solid lubricants in machining process, Nano cutting fluids, Vibration Assisted Machining, Modeling and Simulation of Manufacturing Processes, Composite Materials</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17066"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17066/17066.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17066"
                                    target="_blank">Prof. Prasanth Anand Kumar Lam</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9840845194 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:prasanth_anand@nitw.ac.in  "> prasanth_anand@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Computational Fluid Dynamics, Turbulence, Aerodynamics, Fluid-Structure Interaction, Biofluid Mechanics, Heat Transfer Augmentation and Refrigeration & Air-Conditioning</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>





                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-ravipeetala"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17095/17095.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-ravipeetala"
                                    target="_blank">Prof. Ravi Kumar Peetala</a>
                                  </h5> <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9884889299 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: ravipeetala@nitw.ac.in ">  ravipeetala@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Compressible flows, CFD, Thermal Management of Li-Battery and Design and fabrication of thin film sensors for hypersonic flow application.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-pravi"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16264/16264.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-pravi"
                                    target="_blank">Prof. Ravi Kumar Puli</a>
                                  </h5> <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969317<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: pravi@nitw.ac.in ">  pravi@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text">  I.C. Engines, Alternate Fuels, Design of Air Car, Fuel Cell Car (Solar/Hydrogen), Flying car, Engine Simulation, Lean burn combustion, Adiabatic engine, CNG & HCNG engine, Exhaust gas re-circulation, Turbocharger engines.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-pallekonda"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17090/17090.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-pallekonda"
                                    target="_blank">Prof. Ramesh Babu Pallekonda</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9390269830



                                    <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:pallekonda@nitw.ac.in   "> pallekonda@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text"> Machine Design Experimental Aerodynamics Underwater Force Measurement Vibration Analysis of Systems Bio-Medical Application of Shock Waves.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>










                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-abrahams"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17019/17019.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-abrahams"
                                    target="_blank">Prof. Satyanand Abraham</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9820727846<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:abrahams@nitw.ac.in  ">  abrahams@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text">  Heat transfer enhancement techniques and devices, thermal management of electronic package, gas turbine components and vehicle battery, waste heat recovery, surface wettability.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>







                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16262"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16262/16262.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16262"
                                    target="_blank">Prof. Srinadh K V S</a>
                                  </h5> <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 08702462341<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: kvsaisrinath@nitw.ac.in  ">  kvsaisrinath@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text"> Mechanical behavior of materials, Manufacturing process.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>







                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-snl.nammi"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17089/17089.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-snl.nammi"
                                    target="_blank">Prof. Srinagalakshmi Nammi</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9498065663<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: snl.nammi@nitw.ac.in   ">  snl.nammi@nitw.ac.in     </a>
                                  </p>
                                  <p className="justified-text">  Laser Machining, Texturing, Advanced Micro Machining, Optical Sensing, Mechatronics</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-sneni"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16248/16248.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-sneni"
                                    target="_blank">Prof. Srinivasa Rao S</a>
                                  </h5> <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 08702462336<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: sneni@nitw.ac.in"> sneni@nitw.ac.in     </a>
                                  </p>
                                  <p className="justified-text"> Two-phase flow, I.C.Engines, Experimental Heat Transfer, Solar Energy</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17016"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17016/17016.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17016"
                                    target="_blank">Prof. Shivraman</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9760613653 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: shivraman@nitw.ac.in">  shivraman@nitw.ac.in     </a>
                                  </p>
                                  <p className="justified-text"> Wire arc additive manufacturing, Machine learning in welding and AM, Friction stir welding and processing; Welding and welding metallurgy; wear behavior of materials; m</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16280"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16280/16280.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16280"
                                    target="_blank">Prof. Srikanth Korla</a>
                                  </h5> <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969327 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: korla@nitw.ac.in ">  korla@nitw.ac.in     </a>
                                  </p>
                                  <p className="justified-text"> Structural Health Monitoring, Vibration Analysis and Energy Harvesting, Fiber Metal Laminates, Product Design </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16268"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16268/16268.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16268"
                                    target="_blank">Prof. Suresh Babu V</a>
                                  </h5> <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9000416646<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:  vsbabu@nitw.ac.in ">   vsbabu@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text"> Machine Dynamics, Analysis of Mechanism, Composite Material, Functional Graded Materials, Condition Monitoring and Fault Diagnosis</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-sureshp"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16901/16901.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-sureshp"
                                    target="_blank">Prof. Suresh Periyannan</a>
                                  </h5> <span> Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8248754482<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: sureshp@nitw.ac.in ">  sureshp@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> 1. Distributed-Reconfigurable (1D-3D) and Integrated macro/microsensors design.2. Non-Destructive Testing and Wave Propagation.3. Temperature-dependent materials property measurement of solids/fluids.4. Also, interest in Advanced Energy.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16438"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16438/16438.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16438"
                                    target="_blank">Prof. Syed Ismail</a>
                                  </h5> <span> Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969367<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: syedismail7@nitw.ac.in "> syedismail7@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Tribology; Surface Texturing; Lubrication; Nano-lubricant; Surface Coatings; Composites; Laser Ablation</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-cspr"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16249/16249.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-cspr"
                                    target="_blank">Prof. Surya Prakash Rao Ch</a>
                                  </h5> <span> Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 08702462302<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: cspr@nitw.ac.in "> cspr@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text"> Manufacturing: include CAD/CAM, CIM, Modeling & Simulation, Evolutionary Computation, Metal Cutting and Metal-Matrix Composites.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>





                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16272"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16272/16272.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16272"
                                    target="_blank">Prof. T. Sadasiva Rao</a>
                                  </h5> <span> Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9440035300<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: sadasiv@nitw.ac.in  "> sadasiv@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> Manufacturing Technology, Foundry Technology.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-thamarai29"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17021/17021.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-thamarai29"
                                    target="_blank">Prof. Thamarai Selvan</a>
                                  </h5> <span> Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 7901070374<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:  thamarai29@nitw.ac.in   ">  thamarai29@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">  Computational Solid Mechanics, Contact Mechanics, FEM, FGM, Layer Substrate Systems.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16276"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16276/16276.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16276"
                                    target="_blank">Prof. V Vasu</a>
                                  </h5> <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8019789214<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: vasu@nitw.ac.in"> vasu@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> Nano lubricants, Nanocomposites, Nano tribology , Robotics ,Mechatronics & IIoT, Smart Manufacturing, Predictive Maintenance of Machine Tools (RUL).</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16936"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16936/16936.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16936"
                                    target="_blank">Prof. Venkatesh Gudipadu</a>
                                  </h5> <span> Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 7093901757<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: venkatesh@nitw.ac.in "> venkatesh@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Machine Learning applications in manufacturing, Abrasive Flow Machining; Magnetic Abrasive finishing; Microwave Material processing; Hybrid Machining; Advanced Manufacturing Processes; Surface Engineering, Computational Intelligence in Manufacturing.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17105"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17105/17105.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17105"
                                    target="_blank">Prof. Vijay Choyal</a>
                                  </h5> <span> Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i>9303505188<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: vijaychoyal@nitw.ac.in  "> vijaychoyal@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text"> Computational materials science, Machine learning, Multiscale modeling of materials, Smart materials and structures, Batteries, Nanomechanics of Carbon and Boron Nitride (BN) based nanostructures</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16258"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16258/16258.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16258"
                                    target="_blank">Prof. Venu Gopal A</a>
                                  </h5> <span> Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9493318537<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: venu@nitw.ac.in  "> venu@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text"> Conventional and Un-Conventional Machining, Coatings for High-Speed Machining, Rapid Prototyping, Metrology.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>





                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16283"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16283/16283.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16283"
                                    target="_blank">Prof. V. Rajesh Khana Raju</a>
                                  </h5> <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 08702462316<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vrkraju@nitw.ac.in "> vrkraju@nitw.ac.in     </a>
                                  </p>
                                  <p className="justified-text"> Fluid Dynamics; Heat Transfer; CFD; Combustion; Microfluidics</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>









                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16269"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16269/16269.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16269"
                                    target="_blank">Prof. Y. Ravi Kumar</a>
                                  </h5> <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9440868867<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:yrk@nitw.ac.in "> yrk@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text">  Additive Manufacturing; 3D Printing and its Medical Applications</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>













                      </section> */}
                      <section id="profile-info">

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-adepu_kumar7
"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16278/16278.jpg
" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-adepu_kumar7
"
                                    target="_blank">Prof. Adepu Kumar</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9492783067 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:adepu_kumar7@nitw.ac.in">adepu_kumar7@nitw.ac.in</a> </p>
                                  <p className="justified-text">Friction stir welding/Processing, Additive Manufacturing, Electrical Discharge Machining of Titanium and super alloys , Fabrication and characterisation of Micro and Nano composites.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-anant"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17063/17063.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-anant"
                                    target="_blank">Prof. Anant Kumar Rai</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9548285567 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:anant@nitw.ac.in  ">anant@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Fluid mechanics, Hydraulic turbines, Turbomachinery, Hydropower, Renewable Energy, Material wear testing, Sediment Transport.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-avbabu"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16270/16270.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-avbabu"
                                    target="_blank">Prof. A. Veeresh Babu </a>
                                  </h5> <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9441120203<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:avbabu@nitw.ac.in "> avbabu@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text">  IC Engines, Alternate Fuels, Emissions control, Fuel cells, Refrigeration and Air-conditioning</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-venu"
                              target="_blank"> <img src="https://nitw.ac.in/api/static/files/Prof._A._Venu_Gopal_2024-3-21-15-51-16.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-venu"
                                    target="_blank">Prof. A. Venu Gopal</a>
                                  </h5> <span> Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9493318537<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: venu@nitw.ac.in  "> venu@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text"> Conventional and Un-Conventional Machining, Coatings for High-Speed Machining, Rapid Prototyping, Metrology.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-satishben"
                              target="_blank" className="img-fluid"> <img
                                src="https://wsdc.nitw.ac.in/facultynew/media/16439/16439.jpg" alt="" className="img-fluid" width="150" height="180" /> </a>
                            </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-satishben"
                                    target="_blank">Prof. B. Satish Ben</a> </h5>
                                  <span>Professor </span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9848065254 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:satishben@nitw.ac.in ">satishben@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text"> Structural Health Monitoring, FRP Composites, Self-healing polymer composites, and Adhesive Bonding</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-bangaru"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16257/16257.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-bangaru"
                                    target="_blank">Prof. Bangarubabu Popuri</a> </h5>
                                  <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9440206342 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:bangaru@nitw.ac.in ">bangaru@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Mechanical Vibrations; Vibration Control; Finite Element Analysis; Mechanism Science; Engineering Design, Rotor Dynamics, Vehicle Dynamics</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-vpcm80"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16436/16436.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-vpcm80"
                                    target="_blank">Prof. Chandramohan V. P.</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i>  8332969329 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vpcm80@nitw.ac.in">vpcm80@nitw.ac.in </a> </p>
                                  <p className="justified-text">(i) Solar drying (ii) Solar Updraft Tower (iii) Computational Fluid Dynamics (iv) Convection and conduction heat transfer (v) Drying and Simultaneous solution of heat and mass transfer (vi) Renewable Energy Sources
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-drsam"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17022/17022.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-drsam"
                                    target="_blank">Prof. Chinige Sampath Kumar</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8331071528 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:drsam@nitw.ac.in ">drsam@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">High Heat Flux Cooling Applications; Jet Impingement Cooling; Porous Media; Lattice Boltzmann Method.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-djayakrishna"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16435/16435.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-djayakrishna"
                                    target="_blank">Prof. Devanuri Jaya Krishna</a> </h5>
                                  <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969369 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:djayakrishna@nitw.ac.in"> djayakrishna@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> Phase Change Materials;Utilization of Solar energy;Computational Fluid Dynamics (CFD);Thermal Energy Storage;Heat transfer in porous media;Thermal management of batteries/electronic components
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-gtalla"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17020/17020.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-gtalla"
                                    target="_blank">Prof. Gangadharudu Talla</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9440747257  <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:gtalla@nitw.ac.in ">gtalla@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Modern machining processes, Hybrid machining, Modelling and optimization. </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-gaurav"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17018/17018.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-gaurav"
                                    target="_blank">Prof. Gaurav Kumar Sharma</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9611241015 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:gaurav@nitw.ac.in ">gaurav@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text">- </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-gap"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16260/16260.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-gap"
                                    target="_blank">Prof. G. Amba Prasad Rao</a> </h5>
                                  <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969315 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:gap@nitw.ac.in">gap@nitw.ac.in</a>
                                  </p>
                                  <p className="justified-text"> IC Engines; Engine Exhaust emission control;Alternate fuels;HCCI Combustion.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-gns"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16277/16277.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-gns"
                                    target="_blank">Prof. G. Naga Srinivasulu</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9440509659 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:gns@nitw.ac.in">gns@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text">PEM Fuel Cells; I.C.Engines; Heat Transfer and Turbo machines</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16266"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16266/16266.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16266"
                                    target="_blank">Prof. Gupta G R K</a> </h5>
                                  <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9392231833 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:gupta@nitw.ac.in ">gupta@nitw.ac.in
                                    </a> </p>
                                  <p className="justified-text"> CFD, Turbomachines, Jet propulsion.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}






                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-raghavendra.gujjala"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16533/16533.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-raghavendra.gujjala"
                                    target="_blank">Prof. Gujjala Raghavendra</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9985803317 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:raghavendra.gujjala@nitw.ac.in  "> raghavendra.gujjala@nitw.ac.in
                                    </a> </p>
                                  <p className="justified-text"> Composites, Nanomaterials, Tribology, Polymer, FGM.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>














                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-chk"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17076/17076.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-chk"
                                    target="_blank">Prof. Hari Krishna Chilukoti</a> </h5>
                                  <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9014029382 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:chk@nitw.ac.in ">chk@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> Nanoscale heat transfer, Mass transfer, Interfacial phenomenon, 2D materials, Evaporation, Molecular dynamics, Phase change materials. </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-harikumar"
                              target="_blank"> <img src="https://nitw.ac.in/api/static/files/Dr._Hari_Kumar_Voruganti_2023-10-27-17-13-46.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-harikumar"
                                    target="_blank">Prof. Hari Kumar Voruganti</a>
                                  </h5> <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8985929103 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:harikumar@nitw.ac.in  "> harikumar@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Shape and Topology Optimization for Design, Geometric Modeling for CAD, Finite Element / Isogeometric Analysis, Robotics (Robot Kinematics and Path Planning), Artificial Intelligence for Design.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-jd"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16275/16275.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-jd"
                                    target="_blank">Prof. Joseph Davidson M</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969324 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:jd@nitw.ac.in  ">
                                      jd@nitw.ac.in   </a> </p>
                                  <p className="justified-text">Metal Forming, CAD/CAM, Wire Arc Additive Manufacturing. </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-karthikb"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16513/16513.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-karthikb"
                                    target="_blank">Prof. Karthik Balasubramanian</a>
                                  </h5> <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969370
                                    <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:karthikb@nitw.ac.in"> karthikb@nitw.ac.in </a>
                                  </p>
                                  <p className="justified-text">Microchannel Flow Boiling; Phase Change Heat Transfer; Microfluidics; Thermal management; Solar Thermal; Solar PV and Hybrid systems; HVAC systems.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-kiran"
                              target="_blank"> <img src="https://nitw.ac.in/api/static/files/Dr._Kiran_Kumar_K_2024-3-5-18-10-37.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-kiran"
                                    target="_blank">Prof. Kiran Kumar K</a> </h5>
                                  <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9494153851
                                    <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:kiran@nitw.ac.in  "> kiran@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Thermal Engineering;Heat Transfer; Nanofluids; Thermal Management of Electronics, Refrigeration & Air-Conditioning; CFD; Non-Conventional Energy sources. </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-madhu"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16251/16251.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-madhu"
                                    target="_blank">Prof. Kotha Madhu Murthy</a> </h5>
                                  <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9492416560
                                    <br /> <i className="fa fa-envelope" aria-hidden="true"></i>
                                    <a href="mailto:madhu@nitw.ac.in  "> madhu@nitw.ac.in  </a> </p>
                                  <p className="justified-text"> Thermal Engineering; IC Engines; Alternate Fuels; Entrepreneurship; Industrial and Small Enterprise Management</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-lanka"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16253/16253.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-lanka"
                                    target="_blank">Prof. Krishnanand Lanka</a>
                                  </h5> <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i>  8702459757 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: lanka@nitw.ac.in  ">  lanka@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> Industrial Engineering ; CAD/CAM ; Supply Chain Management ; Additive Manufacturing.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-kpyadav"
                              target="_blank"> <img src="https://nitw.ac.in/api/static/files/kpy_2025-1-8-10-31-28.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-kpyadav"
                                    target="_blank">Prof. Krishna Prakash Yadav</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9451134843 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: Manjaiah.m@nitw.ac.in  ">  kpyadav@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">Multibody Dynamics, Robotics, Control Systems, Nonlinear Dynamics and Control, AI/ML, Vibration Analysis</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-manjaiah.m"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17017/17017.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-manjaiah.m"
                                    target="_blank">Prof. Manjaiah M</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i>  9740847669 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: Manjaiah.m@nitw.ac.in  ">  Manjaiah.m@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> Metal Additive Manufacturing; Advanced Manufacturing processes ; Post processing of AM parts; Shape Memory Alloys.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-mnareshkumar"
                              target="_blank"> <img src="https://erp.nitw.ac.in/ext/profile/getUserImage/me-mnareshkumar" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-mnareshkumar"
                                    target="_blank">Prof. Maroju Naresh Kumar</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 7569574698 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:mnareshkumar@nitw.ac.in "> mnareshkumar@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Physics-Informed Modelling of Manufacturing Processes, Ultrasonic Vibration Assisted Manufacturing Processes, Smart & Virtual Manufacturing (Digital Twin), Machinetool Dynamics & Control and  Applied AI & ML in manufacturing & Hybrid Machining</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-bhargava"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17068/17068.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-bhargava"
                                    target="_blank">Prof. Marrapu Bhargava</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9004669353 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:bhargava@nitw.ac.in "> bhargava@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Metal forming - sheet metal forming- formability evaluation and prediction; Hydroforming; Formability at elevated temperatures; Mechanical behaviour of materials; Heat treatment processes; Material model development</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-selva"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16261/16261.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-selva"
                                    target="_blank">Prof. N. Selvaraj</a>
                                  </h5> <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969316 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:selva@nitw.ac.in"> selva@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Modeling and Simulation, Flexible Manufacturing System, CNC Technology, Machine Tool, Additive Manufacturing, Composite Materials, and Pull systems</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>





                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-rnrao"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16263/16263.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-rnrao"
                                    target="_blank">Prof. Narasimha Rao R</a>
                                  </h5> <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969373 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:rnrao@nitw.ac.in "> rnrao@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Tribology, Kinematics & Dynamics,  Machine Design, Vibrations, FEM, Advanced Composites</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-subhash"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16273/16273.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-subhash"
                                    target="_blank">Prof. P Subhash Chandra Bose</a>
                                  </h5> <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969405 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:subhash@nitw.ac.in"> subhash@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> 1. Machining of high temperature and super alloys2. CNC technologies3. Optimization techniques4. Fabrication and machining of ceramic composites5. Additive manufacturing 6. Laser Assisted Machining of MMC, Ceramics Composite, Super Alloys. 7. Characterization of composites</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-vamsikrishna"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16522/16522.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-vamsikrishna"
                                    target="_blank">Prof. P Vamsi Krishna</a>
                                  </h5> <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9440843609 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vamsikrishna@nitw.ac.in "> vamsikrishna@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Solid lubricants in machining process, Nano cutting fluids, Vibration Assisted Machining, Modeling and Simulation of Manufacturing Processes, Composite Materials</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-prasanth_anand"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17066/17066.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-prasanth_anand"
                                    target="_blank">Prof. Prasanth Anand Kumar Lam</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9840845194 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:prasanth_anand@nitw.ac.in  "> prasanth_anand@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Computational Fluid Dynamics, Turbulence, Aerodynamics, Fluid-Structure Interaction, Biofluid Mechanics, Heat Transfer Augmentation and Refrigeration & Air-Conditioning</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>





                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-ravipeetala"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17095/17095.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-ravipeetala"
                                    target="_blank">Prof. Ravi Kumar Peetala</a>
                                  </h5> <span>Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9884889299 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: ravipeetala@nitw.ac.in ">  ravipeetala@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Compressible flows, CFD, Thermal Management of Li-Battery and Design and fabrication of thin film sensors for hypersonic flow application.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-pravi"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16264/16264.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-pravi"
                                    target="_blank">Prof. Ravi Kumar Puli</a>
                                  </h5> <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969317<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: pravi@nitw.ac.in ">  pravi@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text">  I.C. Engines, Alternate Fuels, Design of Air Car, Fuel Cell Car (Solar/Hydrogen), Flying car, Engine Simulation, Lean burn combustion, Adiabatic engine, CNG & HCNG engine, Exhaust gas re-circulation, Turbocharger engines.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-pallekonda"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17090/17090.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-pallekonda"
                                    target="_blank">Prof. Ramesh Babu Pallekonda</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9390269830



                                    <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:pallekonda@nitw.ac.in   "> pallekonda@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text"> Machine Design Experimental Aerodynamics Underwater Force Measurement Vibration Analysis of Systems Bio-Medical Application of Shock Waves.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>










                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-abrahams"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17019/17019.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-abrahams"
                                    target="_blank">Prof. Satyanand Abraham</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9820727846<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:abrahams@nitw.ac.in  ">  abrahams@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text">  Heat transfer enhancement techniques and devices, thermal management of electronic package, gas turbine components and vehicle battery, waste heat recovery, surface wettability.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>







                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-kvsaisrinath"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16262/16262.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-kvsaisrinath"
                                    target="_blank">Prof. Srinadh K V S</a>
                                  </h5> <span>Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 08702462341<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: kvsaisrinath@nitw.ac.in  ">  kvsaisrinath@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text"> Mechanical behavior of materials, Manufacturing process.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>







                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-snl.nammi"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17089/17089.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-snl.nammi"
                                    target="_blank">Prof. Srinagalakshmi Nammi</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9498065663<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: snl.nammi@nitw.ac.in   ">  snl.nammi@nitw.ac.in     </a>
                                  </p>
                                  <p className="justified-text">  Laser Machining, Texturing, Advanced Micro Machining, Optical Sensing, Mechatronics</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-sneni"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16248/16248.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-sneni"
                                    target="_blank">Prof. Srinivasa Rao S</a>
                                  </h5> <span>Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 08702462336<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: sneni@nitw.ac.in"> sneni@nitw.ac.in     </a>
                                  </p>
                                  <p className="justified-text"> Two-phase flow, I.C.Engines, Experimental Heat Transfer, Solar Energy</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-shivraman"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17016/17016.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-shivraman"
                                    target="_blank">Prof. Shivraman</a>
                                  </h5> <span>Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9760613653 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: shivraman@nitw.ac.in">  shivraman@nitw.ac.in     </a>
                                  </p>
                                  <p className="justified-text"> Wire arc additive manufacturing, Machine learning in welding and AM, Friction stir welding and processing; Welding and welding metallurgy; wear behavior of materials; m</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-korla"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16280/16280.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-korla"
                                    target="_blank">Prof. Srikanth Korla</a>
                                  </h5> <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969327 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: korla@nitw.ac.in ">  korla@nitw.ac.in     </a>
                                  </p>
                                  <p className="justified-text"> Structural Health Monitoring, Vibration Analysis and Energy Harvesting, Fiber Metal Laminates, Product Design </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-vsbabu"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16268/16268.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-vsbabu"
                                    target="_blank">Prof. Suresh Babu V</a>
                                  </h5> <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9000416646<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:  vsbabu@nitw.ac.in ">   vsbabu@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text"> Machine Dynamics, Analysis of Mechanism, Composite Material, Functional Graded Materials, Condition Monitoring and Fault Diagnosis</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-sureshp"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16901/16901.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-sureshp"
                                    target="_blank">Prof. Suresh Periyannan</a>
                                  </h5> <span> Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8248754482<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: sureshp@nitw.ac.in ">  sureshp@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> 1. Distributed-Reconfigurable (1D-3D) and Integrated macro/microsensors design.2. Non-Destructive Testing and Wave Propagation.3. Temperature-dependent materials property measurement of solids/fluids.4. Also, interest in Advanced Energy.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-syedismail7"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16438/16438.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-syedismail7"
                                    target="_blank">Prof. Syed Ismail</a>
                                  </h5> <span> Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969367<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: syedismail7@nitw.ac.in "> syedismail7@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Tribology; Surface Texturing; Lubrication; Nano-lubricant; Surface Coatings; Composites; Laser Ablation</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-cspr"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16249/16249.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-cspr"
                                    target="_blank">Prof. Surya Prakash Rao Ch</a>
                                  </h5> <span> Professor (HAG)</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 08702462302<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: cspr@nitw.ac.in "> cspr@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text"> Manufacturing: include CAD/CAM, CIM, Modeling & Simulation, Evolutionary Computation, Metal Cutting and Metal-Matrix Composites.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>





                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-sadasiv"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16272/16272.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-sadasiv"
                                    target="_blank">Prof. T. Sadasiva Rao</a>
                                  </h5> <span> Associate Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9440035300<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: sadasiv@nitw.ac.in  "> sadasiv@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> Manufacturing Technology, Foundry Technology.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-thamarai29"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17021/17021.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-thamarai29"
                                    target="_blank">Prof. Thamarai Selvan</a>
                                  </h5> <span> Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 7901070374<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:  thamarai29@nitw.ac.in   ">  thamarai29@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text">  Computational Solid Mechanics, Contact Mechanics, FEM, FGM, Layer Substrate Systems.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-vasu"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16276/16276.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-vasu"
                                    target="_blank">Prof. V Vasu</a>
                                  </h5> <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8019789214<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: vasu@nitw.ac.in"> vasu@nitw.ac.in  </a>
                                  </p>
                                  <p className="justified-text"> Nano lubricants, Nanocomposites, Nano tribology , Robotics ,Mechatronics & IIoT, Smart Manufacturing, Predictive Maintenance of Machine Tools (RUL).</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-venkatesh"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16936/16936.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-venkatesh"
                                    target="_blank">Prof. Venkatesh Gudipadu</a>
                                  </h5> <span> Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 7093901757<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: venkatesh@nitw.ac.in "> venkatesh@nitw.ac.in   </a>
                                  </p>
                                  <p className="justified-text"> Machine Learning applications in manufacturing, Abrasive Flow Machining; Magnetic Abrasive finishing; Microwave Material processing; Hybrid Machining; Advanced Manufacturing Processes; Surface Engineering, Computational Intelligence in Manufacturing.</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-vijaychoyal"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17105/17105.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-vijaychoyal"
                                    target="_blank">Prof. Vijay Choyal</a>
                                  </h5> <span> Assistant Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i>9303505188<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto: vijaychoyal@nitw.ac.in  "> vijaychoyal@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text"> Computational materials science, Machine learning, Multiscale modeling of materials, Smart materials and structures, Batteries, Nanomechanics of Carbon and Boron Nitride (BN) based nanostructures</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>








                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-vrkraju"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16283/16283.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-vrkraju"
                                    target="_blank">Prof. V. Rajesh Khana Raju</a>
                                  </h5> <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 08702462316<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:vrkraju@nitw.ac.in "> vrkraju@nitw.ac.in     </a>
                                  </p>
                                  <p className="justified-text"> Fluid Dynamics; Heat Transfer; CFD; Combustion; Microfluidics</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>









                        <div className="col-md-12 profile-info-individual">
                          <div className="row">
                            <div className="col-md-3"> <a
                              href="https://erp.nitw.ac.in/ext/profile/me-yrk"
                              target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16269/16269.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </a> </div>
                            <div className="col-md-9">
                              <div className="card ">
                                <div className="data">
                                  <h5> <a href="https://erp.nitw.ac.in/ext/profile/me-yrk"
                                    target="_blank">Prof. Y. Ravi Kumar</a>
                                  </h5> <span> Professor</span>
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9440868867<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:yrk@nitw.ac.in "> yrk@nitw.ac.in    </a>
                                  </p>
                                  <p className="justified-text">  Additive Manufacturing; 3D Printing and its Medical Applications</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </section>
                    </div>

                    <div className={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                      aria-labelledby="nav-AdjunctFaculty-tab">
                      <div className="col-md-12 profile-info-individual">
                        <div className="row">
                          <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                            <a href="" target="_blank">

                              <img src="https://nitw.ac.in/api/static/files/mech_vfaculty_2024-8-16-19-34-59.jpg" alt=""
                                className="img-fluid" height="180" style={{ border: "5px solid #edc512" }} />
                            </a>
                          </div>
                          <div className="col-md-9">
                            <div className="card ">
                              <div className="data">
                                <h5> <a href=""
                                  target="_blank">Dr. A. Venugopal Rao </a>
                                </h5>
                                <p className="contact-info"> Adjunct Professor<br /><i className="fa fa-phone"
                                  aria-hidden="true"></i> 9908714567 <br /> <i
                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                      href="mailto: avenugopal@nitw.ac.in">  avenugopal@nitw.ac.in   </a>
                                </p>
                                {/* <p className="justified-text">Marketing and IT</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 


                    <div className={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                      aria-labelledby="nav-NonTeachingStaff-tab">
                      <div className="row academic_group">
                        {/* <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._R._Gopinath_2023-11-3-10-52-3.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. R. Gopinath</h6> <span>Sr. Superintendent</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332669545 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:rgopi@nitw.ac.in">rgopi@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        {/* <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._N._Kishore_Kumar_2023-11-3-10-54-9.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. N. Kishore Kumar</h6>
                                <span>Jr.Asst</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969508  <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:nkkishore@nitw.ac.in">nkkishore@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._A._Sambaiah_2023-11-3-10-56-4.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. A. Sambaiah</h6>
                                <span>Jr.Asst (UG)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 6302379476 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:asambaiah@nitw.ac.in">asambaiah@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._R._Ilaiah_2023-11-3-10-57-28.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. R. Ilaiah</h6>
                                <span>Technician (UG)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969350 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:-">-</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Dr.Rangilal_Bhukya_2024-2-27-12-55-50.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Dr.Rangilal Bhukya</h6>
                                <span>Technical Assistant ( SG-I)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969335 <br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:rangilal@nitw.ac.in">rangilal@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._Ramesh_K_(Sr.)_2023-11-3-11-9-13.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. Ramesh K (Sr.)</h6>
                                <span>Sr. Tech.Asst. (SG-II)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969336<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:rameshagm@nitw.ac.in">rameshagm@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._Yella_Swamy_K._2023-11-3-11-10-41.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. Yella Swamy K.</h6>
                                <span>Technical Asst.</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969334<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:yellaswamy@nitw.ac.in">yellaswamy@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._M._Narsimha_2023-11-3-11-12-27.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. M. Narsimha</h6>
                                <span>Sr. Technical Asst.</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8019418954<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:malothunarsimha@gmail.com">malothunarsimha@gmail.com</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._D._Ravi_Teja_2023-11-3-11-13-54.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. D. Ravi Teja</h6>
                                <span>Technical Asst.</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9494554568<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ravitejad@nitw.ac.in">ravitejad@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._Ramchander_Y_2023-11-3-11-15-10.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. Ramchander Y</h6>
                                <span>Technical Asst.</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969337<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ramchandaryedulapuram@gmail.com">ramchandaryedulapuram@gmail.com</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        {/* <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._Srinivasulu_N_2023-11-3-11-16-34.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. Srinivasulu N</h6>
                                <span>Technical Asst.</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969340<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:-">-</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}




                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._S._Ravi_2023-11-3-11-17-51.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. S. Ravi</h6>
                                <span>Technical Asst.</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969664<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ravis@nitw.ac.in">ravis@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>





                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._R._Ravinder_2023-11-3-11-19-16.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. R. Ravinder</h6>
                                <span>Technical Asst.</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969302<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ravinderr@nitw.ac.in">ravinderr@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>






                        {/* <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._D._Sunil_Kumar_2023-11-3-11-20-45.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. D. Sunil Kumar</h6>
                                <span>Technician (SG-II)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969304<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:sunild@nitw.ac.in">sunild@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}




                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._Ramesh_M_2023-11-3-11-22-5.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. Ramesh M</h6>
                                <span>Technician (SG-II)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969342<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:rameshm@nitw.ac.in">rameshm@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._Rajamouli_A_2023-11-3-11-23-25.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. Rajamouli A</h6>
                                <span>Technician (SG-II)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969343<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:rajamouli@nitw.ac.in">rajamouli@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>






                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._Ramulu_B_2023-11-3-11-24-43.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. Ramulu B</h6>
                                <span>Technician (SG-II)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969344<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:ramurpli@nitw.ac.in">ramurpli@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._Kumara_Swamy_M_2023-11-3-11-26-24.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. Kumara Swamy M</h6>
                                <span>Technician (SG-II)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969345<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:mkswamy@nitw.ac.in">mkswamy@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._A._Sathish_Kumar_2023-11-3-11-27-45.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. A. Sathish Kumar</h6>
                                <span>Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9052297840<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:adluris@nitw.ac.in">adluris@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>





                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._K._Rohith_2023-11-3-11-29-0.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. K. Rohith</h6>
                                <span>Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 7799228733<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kandakatlar@nitw.ac.in">kandakatlar@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        {/* <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._K._Appanna_Dora_2023-11-3-11-30-17.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. K. Appanna Dora</h6>
                                <span>Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 7842199435<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:koyyaa@nitw.ac.in">koyyaa@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}





                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._B._D._Jagath_Kumar_2023-11-3-11-31-57.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. B. D. Jagath Kumar</h6>
                                <span>Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 7207371186<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:jagathkumar04@nitw.ac.in">jagathkumar04@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Smt._R._Pavani_2023-11-3-11-33-6.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Smt. R. Pavani</h6>
                                <span>Technician</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8008325314<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:pavaniramisetti@nitw.ac.in">pavaniramisetti@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._MD._Aziz_Khan_2023-11-3-11-34-28.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. MD. Aziz Khan</h6>
                                <span>Technician (UG)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 8332969613<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:azeezkhanjuly1965@nitw.ac.in">azeezkhanjuly1965@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div className="col-md-6">
                          <div className="card">
                            <div className="d-flex align-items-center">
                              <div className="image"> <img src="https://nitw.ac.in/api/static/files/Shri._A._Kumara_Swamy_2023-11-3-11-35-57.jpg" alt=""
                                className="img-fluid" width="150" height="180" /> </div>
                              <div className="ml-3 w-100">
                                <h6 className="mb-0 mt-0">Shri. A. Kumara Swamy</h6>
                                <span>Technician (UG)</span>
                                <div className=" mt-2">
                                  <p className="contact-info"> <i className="fa fa-phone"
                                    aria-hidden="true"></i> 9985438366<br /> <i
                                      className="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:-">-</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>











                      </div>

                    </div>



                    <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                      aria-labelledby="nav-ResearchScholars-tab">
                      {/*<h4 className="">Ph.D. Awardee</h4>
                     <p className="justified-text">
                      <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf" target="_blank">
                        <span className="highlight">L</span>ist of Ph.D. Awardees <i className="fa fa-link " aria-hidden="true"></i>
                      </a>
                    </p>*/}






                      <div className="row phd-std-row">

                        {/* 		

        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img ">

              <img src="CSEDeptAssets/images/phd1.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b></b>
                  </p>
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Vishnu Vardhan</h6>
            <span>Part-Time Research Scholar</span>
          </div>
        </div>
        
    
        
    
         <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd2.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Computational Neuroscience</b>
                  </p>
                
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Lavanya Madhuri Bollipo</h6>
            <span>Full-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  9989685689 <br/>  <a href="mailto:lavanyasandeep@gmail.com">lavanyasandeep@gmail.com</a> </p>
                  
            
          </div>
        </div>
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img"> 
              <img src="CSEDeptAssets/images/phd3.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Cryptography</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">T Preethi</h6>
            <span>Full-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  7893543695
     <br/>  <a href="mailto:preethi3737@gmail.com" >preethi3737@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd4.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Social Network Analysis</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Abhilash M H</h6>
            <span>Full-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  7893543695
     <br/>  <a href="mailto:abhi.6882@gmail.com" >abhi.6882@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        <div className="col-md-3  col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd5.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Distributed Algorithms</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">S Sathyanarayanan</h6>
            <span>Full-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  9487673636
     <br/>  <a href="mailto:mail2satviv@gmail.com" >mail2satviv@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd6.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Big Data Analytics</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">G Spoorthy</h6>
            <span>Full-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  9701296256
     <br/>  <a href="mailto:sspoorthy6@gmail.com" >sspoorthy6@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd7.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Computer Vision and Image
    Processing</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Kanaparthi Suresh Kumar</h6>
            <span>Full-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  9885174480
     <br/>  <a href="mailto:sureshkonline@gmail.com" >sureshkonline@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd8.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Big Data Analytics</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Chaitanya Kanchibhotla</h6>
            <span>Part-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  9949943938
     <br/>  <a href="mailto:reachchaitanyak@gmail.com" >reachchaitanyak@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd9.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Software Defined Networks </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Somesula Manoj Kumar</h6>
            <span>Full-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info">  9642628253 
     <br/>  <a href="mailto:manuroyal060@gmail.com" >manuroyal060@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd10.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Mobile computing and security </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">D Mallikarjun Reddy </h6>
            <span>Full-Time Research Scholar (IDRBT)  </span>
            <hr/>
            
            <p className="contact-info">  7207558884  
     <br/>  <a href="mailto:arjun753016@gmail.com" >arjun753016@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd11.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Privacy Preserving Data Publishing </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Hemkumar D </h6>
            <span>Full-Time Research Scholar</span>
            <hr/>
            
            <p className="contact-info">  9591259817  
     <br/>  <a href="mailto:dhkmedar@gmail.com" >dhkmedar@gmail.com </a> </p>
            
          </div>
        </div>
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd12.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Machine Learning </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">N. Satya Krishna </h6>
            <span>Full-Time Research Scholar (IDRBT) </span>
            <hr/>
            
            <p className="contact-info">  8341502855  
     <br/>  <a href="mailto:satya.krishna.nunna@gmail.com" >satya.krishna.nunna@gmail.com  </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd13.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Service Oriented Architecture </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Vinay Raj  </h6>
            <span>Full-Time Research Scholar   </span>
            <hr/>
            
            <p className="contact-info">  9963850192  
     <br/>  <a href="mailto:vinayrajlodh@gmail.com" >vinayrajlodh@gmail.com  </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd14.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Cryptography  </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">B Satish Babu  </h6>
            <span>Full-Time Research Scholar   </span>
            <hr/>
            
            <p className="contact-info">  9885968533  
     <br/>  <a href="mailto:satishbabu4u@gmail.com" >satishbabu4u@gmail.com  </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd15.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Big Data Analytics  </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Avinash Potluri</h6>
            <span>Full-Time Research Scholar (IDRBT)    </span>
            <hr/>
            
            <p className="contact-info">  9490909404  
     <br/>  <a href="mailto:potluri.avinash1@gmail.com" >potluri.avinash1@gmail.com </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd16.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b></b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Amdewar Godavari Ramlu </h6>
            <span>Full-Time Research Scholar      </span>
            <hr/>
            
            <p className="contact-info"><a href="mailto:amdewar_g@yahoo.co.in" >amdewar_g@yahoo.co.in </a> </p>
            
          </div>
        </div>
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd17.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b></b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Gudeme Jaya Rao</h6>
            <span>Full-Time Research Scholar (IDRBT)     </span>
            
            
          </div>
        </div>
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd18.jpg" className="img-fluid image"  alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Graph Algorithms   </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Chakradhar Padamutham</h6>
            <span>Full-Time Research Scholar   </span>
            <hr/>
            
            <p className="contact-info">  8686780637   </p>
            
          </div>
        </div>
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd19.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Cancellable Biometrics   </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Mahesh Kumar Morampudi</h6>
            <span>Full-Time Research Scholar (IDRBT)    </span>
            <hr/>
            
            <p className="contact-info">  8522983826  
     <br/>  <a href="mailto:morampudimahesh@gmail.com" >morampudimahesh@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd20.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Data Mining and Machine Learning  </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">K.V.T.K.N. Prashanth</h6>
            <span>Full-Time Research Scholar       </span>
            <hr/>
            
            <p className="contact-info">  8978567535  
     <br/>  <a href="mailto:kasturiprashanth@gmail.com" >kasturiprashanth@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd21.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Application Security in Computer Networks  </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">P.V. Shalini</h6>
            <span>Full-Time Research Scholar (IDRBT)  </span>
            <hr/>
            
            <p className="contact-info">  8374046101  
     <br/>  <a href="mailto:pvshalini@gmail.com" >pvshalini@gmail.com </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd22.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Software Engineering and Machine Learning</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">B. Uma Maheswara Sharma</h6>
            <span>Full-Time Research Scholar   </span>
            <hr/>
            
            <p className="contact-info">  9542954304  
     <br/>  <a href="mailto:sarma.hcu@gmail.com" >sarma.hcu@gmail.com </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd23.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Bioinformatics, Big Data Analytics</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Chandra Mohan D</h6>
            <span>Full-Time Research Scholar    </span>
            <hr/>
            
            <p className="contact-info">  9032796381   
     <br/>  <a href="mailto:chandu.nitw44@gmail.com" >chandu.nitw44@gmail.com  </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd24.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Wireless vehicular sensor networks </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Satish Vemireddy</h6>
            <span>Full-Time Research Scholar    </span>
            <hr/>
            
            <p className="contact-info">  7382758996   
     <br/>  <a href="mailto:svreddyiitb@gmail.com" >svreddyiitb@gmail.com  </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd25.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Image Processing  </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Sweta Panigrahi</h6>
            <span>Full-Time Research Scholar    </span>
            <hr/>
            
            <p className="contact-info">  9523685956   
     <br/>  <a href="mailto:Sweta.panigrahi6@gmail.com" >Sweta.panigrahi6@gmail.com   </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd26.jpg"  className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Location Based Services for Network Applications   </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Sanjib Kumar Raul</h6>
            <span>Full-Time Research Scholar    </span>
            <hr/>
            
            <p className="contact-info">  7008701931    
     <br/>  <a href="mailto:Sanjib.roul@gmail.com" >Sanjib.roul@gmail.com    </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd27.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Security & Privacy   </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Vinod Kumar Mahor</h6>
            <span>Part-Time Research Scholar    </span>
            <hr/>
            
            <p className="contact-info">  9440745665     
     <br/>  <a href="mailto:Vinod.mahor@gmail.com" >Vinod.mahor@gmail.com    </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd28.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Data Mining   </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Ravi Kanth Kotha</h6>
            <span>Full-Time Research Scholar (IDRBT)     </span>
            <hr/>
            
            <p className="contact-info">  8939668158     
     <br/>  <a href="mailto:ravikanth1027@gmail.com" >ravikanth1027@gmail.com    </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd29.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>IOT and Big Data   </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Priyanka Parimi</h6>
            <span>Full-Time Research Scholar      </span>
            <hr/>
            
            <p className="contact-info">  8897804040     
     <br/>  <a href="mailto:priyanka.parimi@gmail.com" >priyanka.parimi@gmail.com    </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd30.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Biometrics   </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Mukku Nisanth Kartheek</h6>
            <span>Full-Time Research Scholar (IDRBT)</span>
            <hr/>
            
            <p className="contact-info">  9443168421      
     <br/>  <a href="mailto:nisanthkartheek@gmail.com" >nisanthkartheek@gmail.com     </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd31.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Information Security   </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Cheetoori Jayanth Babu</h6>
            <span>Full-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info">  9989710345      
     <br/>  <a href="mailto:jayanth_babu786@student.nitw.ac.in" >jayanth_babu786@student.nitw.ac.in     </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd32.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Image Processing   </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Debanjan Pathak</h6>
            <span>Full-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info">  9002505683       
     <br/>  <a href="mailto:debanjan21pathak@gmail.com" >debanjan21pathak@gmail.com    </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd33.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Big Data Analytics   </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Sachin Sood</h6>
            <span>Part-Time Research Scholar   </span>
            <hr/>
            
            <p className="contact-info">  9490956589        
     <br/>  <a href="mailto:sachinsood@yahoo.com" >sachinsood@yahoo.com   </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd34.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Data Mining   </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Chilamul Narsinhachari Vyankatachari</h6>
            <span>Part-Time Research Scholar   </span>
            <hr/>
            
            <p className="contact-info">  9490956253        
     <br/>  <a href="mailto:chnchary2012@gmail.com" >chnchary2012@gmail.com   </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd35.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Privacy Preserving of Data in Cloud Computing    </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">V Umasankara Rao</h6>
            <span>Full-Time Research Scholar (IDRBT)  </span>
            <hr/>
            
            <p className="contact-info">  8500266233        
     <br/>  <a href="mailto:shankarit54@gmail.com" >shankarit54@gmail.com   </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd36.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Bioinformatics</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Amilpur Santhosh</h6>
            <span>Full-Time Research Scholar (IDRBT)  </span>
            <hr/>
            
            <p className="contact-info">  8466928626        
     <br/>  <a href="mailto:santosh0511@gmail.com" >santosh0511@gmail.com   </a> </p>
            
          </div>
        </div>
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd37.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Security</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Manchala Pravali</h6>
            <span>Full-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info">  8465970174        
     <br/>  <a href="mailto:pravali.m@nitw.ac.in " >pravali.m@nitw.ac.in    </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd38.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Data Mining with Big Data</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">U Shivani Sri Varshini</h6>
            <span>Full-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info">  9866585482       
     <br/>  <a href="mailto:shivanisrivarshini.u@gmail.com" >shivanisrivarshini.u@gmail.com </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd39.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Machine learning, Data science, big data, spiking neural network </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Vishnu Kumar</h6>
            <span>Full-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info">  8459242253        
     <br/>  <a href="mailto:vksingh843025@gmail.com" >vksingh843025@gmail.com </a> </p>
            
          </div>
        </div>
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd40.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Image Processing</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Venkatesh Akula</h6>
            <span>Full-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info">  9494038143        
     <br/>  <a href="mailto:venkateshakula.mv@gmail.com" >venkateshakula.mv@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd41.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Big Data</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Praveen Guguglothu</h6>
            <span>Full-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info">  9652301729         
     <br/>  <a href="mailto:ramjijyothi@gmail.com" >ramjijyothi@gmail.com</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd42.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Internet of Things and Block chain</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Bhukya Padma</h6>
            <span>Full-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info">  8143683822         
     <br/>  <a href="mailto:padmajyo@student.nitw.ac.in" >padmajyo@student.nitw.ac.in</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd43.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Medical imaging</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Murukessan</h6>
            <span>Full-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info">  87547 99558         
     <br/>  <a href="mailto:muruap87@student.nitw.ac.in" >muruap87@student.nitw.ac.in</a> </p>
            
          </div>
        </div>
      
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd44.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Biometrics</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Dilip Kumar Vallabhadas</h6>
            <span>Full-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info">  97030 42113         
     <br/>  <a href="mailto:vdilip@student.nitw.ac.in" >vdilip@student.nitw.ac.in</a> </p>
            
          </div>
        </div>
       
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd45.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Computer Vision and Machine Learning </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Medipelly Rampavan</h6>
            <span>Full-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info">  93982 10479          
     <br/>  <a href="mailto:rampavan58@student.nitw.ac.in" >rampavan58@student.nitw.ac.in  </a> </p>
            
          </div>
        </div>
       
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd46.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Soft Computing Techniques </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">G Punnam Chander</h6>
            <span>Full-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info">  97005 85826         
     <br/>  <a href="mailto:punnamchander@student.nitw.ac.in" >punnamchander@student.nitw.ac.in</a> </p>
            
          </div>
        </div>
       
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd47.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Distributed Data Mining and Data Analytics  </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Dheeraj Kodati</h6>
            <span>Part-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info">  90309 78791          
     <br/>  <a href="mailto:dheerajkodaticse@student.nitw.ac.in" >dheerajkodaticse@student.nitw.ac.in</a> </p>
            
          </div>
        </div>
       
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd48.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Data Mining,Big Data Analytics </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">P Ushashree</h6>
            <span>Part-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info">  9912056225           
     <br/>  <a href="mailto:up720075@student.nitw.ac.in" >up720075@student.nitw.ac.in</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd49.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Image Processing</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Ala Harika </h6>
            <span>Part-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info">  9676614771           
     <br/>  <a href="mailto:ah720077@student.nitw.ac.in" >ah720077@student.nitw.ac.in</a> </p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd50.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>wireless networks</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Dubba Sudha </h6>
            <span>Full-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info">  7893170336           
     <br/>  <a href="mailto:ds720078@student.nitw.ac.in" >ds720078@student.nitw.ac.in</a> </p>
            
          </div>
        </div>
       
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd51.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Machine learning</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Hemraj Singh  </h6>
            <span>IDRBT</span>
            <hr/>
            
            <p className="contact-info">  7895817663           
     <br/>  <a href="mailto:hs720079@student.nitw.ac.in" >hs720079@student.nitw.ac.in </a> </p>
            
          </div>
        </div>
   
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd52.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Software Testing and Program Analysis </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Golla Monika Rani </h6>
            <span>Full-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info">  7378880412           
     <br/>  <a href="mailto:gm720080@student.nitw.ac.in" >gm720080@student.nitw.ac.in </a> </p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd53.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Big Data, Internet of Things  </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Utukuru Sairam  </h6>
            <span>Part-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info">  8143364133            
     <br/>  <a href="mailto:us720081@student.nitw.ac.in" >us720081@student.nitw.ac.in </a> </p>
            
          </div>
        </div>
       
        
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd54.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Machine Learning, Deep Learning</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Nabendu Bhui </h6>
            <span>Full-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info"> 8370823809            
     <br/>  <a href="mailto:nb720082@student.nitw.ac.in" >nb720082@student.nitw.ac.in </a> </p>
            
          </div>
        </div>
     
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd55.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Bioinformatics </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Banoth Prasad   </h6>
            <span>Part-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info"> 9989370473            
     <br/>  <a href="mailto:bp720083@student.nitw.ac.in" >bp720083@student.nitw.ac.in </a> </p>
            
          </div>
        </div>
       
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd56.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Soft computing </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Chilukamari Rajesh  </h6>
            <span>Full-Time Research Scholar    </span>
            <hr/>
            
            <p className="contact-info"> 9492933548            
     <br/>  <a href="mailto:cr720084@student.nitw.ac.in" >cr720084@student.nitw.ac.in </a> </p>
            
          </div>
        </div>
       
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd57.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Big Data, High Performance Computing, Machine Learning, Deep Learning  </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Yelleti Vivek  </h6>
            <span>Full-Time Research Scholar (IDRBT) </span>
            <hr/>
            
            <p className="contact-info"> 96529221176            
     <br/>  <a href="mailto:yv720085@student.nitw.ac.in" >yv720085@student.nitw.ac.in </a> <br/>
     <a href="mailto:vivekvvy3@gmail.com" >vivekvvy3@gmail.com</a></p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd58.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>IoT </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Aguru Aswini Devi </h6>
            <span>Full-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info"> 9493241602           
     <br/> <a href="mailto:Aa720086@student.nitw.ac.in" >Aa720086@student.nitw.ac.in</a></p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd59.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Cloud Computing, Fog Computing, Algorithms, Computer Networks</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Thanedar Md Asif  </h6>
            <span>Full-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info"> 9494802627           
     <br/> <a href="mailto:tm720089@student.nitw.ac.in" >tm720089@student.nitw.ac.in</a></p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd60.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Data mining, Data Analytics, Machine learning, IOT </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Dasari A Rachana  </h6>
            <span>Part-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info"> 8886895588            
     <br/> <a href="mailto:dasari.rachana@gmail.com" >dasari.rachana@gmail.com</a></p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd61.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Cryptography, distributed computing, IOT </b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> M Prakash  </h6>
            <span>Full-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info"> 9110569954            
     <br/> <a href="mailto:mp721084@student.nitw.ac.in" >mp721084@student.nitw.ac.in</a></p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd62.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Machine Learning, Cyber Security</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Gautam Rampalli  </h6>
            <span>Part-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info"> 9963310199            
     <br/> <a href="mailto:gr721085@student.nitw.ac.in" >gr721085@student.nitw.ac.in</a></p>
            
          </div>
        </div>
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd63.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Machine Learning</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Banovoth Raja Sekhar  </h6>
            <span>Full-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info"> 8985385800            
     <br/> <a href="mailto:br721086@student.nitw.ac.in" >br721086@student.nitw.ac.in</a></p>
            
          </div>
        </div>
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd64.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Soft Computing</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title">Satya Shekar Varma P   </h6>
            <span>Part-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info"> 9666665578            
     <br/> <a href="mailto:ss721087@student.nitw.ac.in" >ss721087@student.nitw.ac.in</a></p>
            
          </div>
        </div>
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd65.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Machine Learning</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Challa Vijaya M Lakshmi   </h6>
            <span>Part-Time Research Scholar  </span>
            <hr/>
            
            <p className="contact-info"> 7674023663            
     <br/> <a href="mailto:cv721088@student.nitw.ac.in" >cv721088@student.nitw.ac.in</a></p>
            
          </div>
        </div>
        
        
        
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd66.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Machine Learning, Soft Computing</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Srinivas Arukonda   </h6>
            <span>Full-Time Research Scholar </span>
            <hr/>
            
            <p className="contact-info">  6309060423            
     <br/> <a href="mailto:sa721089@student.nitw.ac.in" > sa721089@student.nitw.ac.in  </a></p>
            
          </div>
        </div>
        
        
        
        
        
        
        <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
          <div className="phd-std-member">
            <div className="phd-std-img">
              <img src="CSEDeptAssets/images/phd67.jpg" className="img-fluid image" alt=""/>
              <div className="overlay">
                <div className="std-details ">
                   <p className="justified-text">
                    Research Area : <b>Machine Learning, Deep Learning, Information Security</b>
                  </p>
                 
                  
                  
                </div>
              </div>
            </div>
            <h6 className="std-title"> Sheelam Tharun Reddy   </h6>
            <span>Part-Time Research Scholar   </span>
            <hr/>
            
            <p className="contact-info">  9652655149           
     <br/> <a href="mailto:tharunreddy536@gmail.com" > tharunreddy536@gmail.com  </a></p>
            
          </div>
        </div>
        
         */}




                      </div>



                    </div>
                  </div>
                </div>
                {/*<div className="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 className=" mb-4">Non-Teaching Staff</h4><table className="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                <div className={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                  aria-labelledby="v-pills-Students-tab">
                  <div className="row std-society">
                    <div className="col-md-3 col-6">
                      <div className="card "> <a
                        href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16280"
                        target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16280/16280.jpg" alt="" className="img-fluid" width="150" height="180" />
                      </a>
                        <div className="data"> <a
                          href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16280"
                          target="_blank">
                          <h6>Prof. Srikanth Korla</h6> <span> Faculty Adviser<br></br>M. E. A</span>
                          <hr />
                        </a>
                          <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 8332969327<br /> <i
                            className="fa fa-envelope" aria-hidden="true"></i> <a
                              href="mailto:korla@nitw.ac.in  "
                              style={{ color: "#808080" }}>korla@nitw.ac.in </a> </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-6">
                      <div className="card "> <a
                        href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16936"
                        target="_blank"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16936/16936.jpg" alt="" className="img-fluid" width="150" height="180" />
                      </a>
                        <div className="data"> <a
                          href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16936"
                          target="_blank">
                          <h6>Prof. Venkatesh Gudipadu</h6> <span> Faculty  Treasurer <br></br>M. E. A </span>
                          <hr />
                        </a>
                          <p className="justified-text"> <i className="fa fa-phone" aria-hidden="true"></i> 7093901757 <br /> <i
                            className="fa fa-envelope" aria-hidden="true"></i> <a
                              href="mailto:venkatesh@nitw.ac.in "
                              style={{ color: "#808080" }}>venkatesh@nitw.ac.in </a> </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                  aria-labelledby="v-pills-NotableAlumni-tab">


                  <div className="row notable-alumni">
                    <div className="col-md-3 col-6">
                      <div className="card "> <img src="https://nitw.ac.in/api/static/files/Shri_Janakiram_Nanduri_2023-11-3-11-50-19.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Shri Janakiram Nanduri</h6>

                          <p className="justified-text"><b>Batch :</b> <br />
                            <b>Present Position :</b> President, M/s. GKC Projects
                          </p>

                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="https://nitw.ac.in/api/static/files/Shri_D._Murali_2023-11-3-11-51-16.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Shri D. Murali</h6>
                          <p className="justified-text">
                            <b>Present Position :</b> Former President (Projects), Reliance Industries

                          </p>

                        </div>
                      </div>
                    </div>




                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="https://nitw.ac.in/api/static/files/Shri_Ramajogaiah_Sastry_2023-11-3-11-52-7.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Shri Ramajogaiah Sastry</h6>
                          <p className="justified-text">
                            <b>Present Position :</b> Leading Telugu Film Lyricist

                          </p>

                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="https://nitw.ac.in/api/static/files/Shri_Sunand_Sharma_2023-11-3-11-53-4.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Shri Sunand Sharma</h6>
                          <p className="justified-text">
                            <b>Present Position :</b> President, Alstom, India.

                          </p>

                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="https://nitw.ac.in/api/static/files/Shri_S.P.Y._Reddy_(late)_2023-11-3-11-53-45.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Shri S.P.Y. Reddy (late)</h6>
                          <p className="justified-text">
                            <b>Present Position :</b> former M.P., Entrepreneur, Nandi pipes.

                          </p>

                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="https://nitw.ac.in/api/static/files/Dr._P._V._Kishore_2023-11-3-11-54-38.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Dr. P. V. Kishore</h6>
                          <p className="justified-text">
                            <b>Present Position :</b> Professor, Mechanical Engineering, Stevens Institute of Technology, New Jersey, USA
                          </p>

                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="https://nitw.ac.in/api/static/files/Shri_P._Laxmi_Narayana_2023-11-3-11-55-49.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Shri P. Laxmi Narayana</h6>
                          <p className="justified-text">
                            <b>Present Position :</b> Former Joint Director – CBI
                          </p>

                        </div>
                      </div>
                    </div>



                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="https://nitw.ac.in/api/static/files/Dr._P._Seshu_2023-11-3-11-56-50.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Dr. P. Seshu</h6>
                          <p className="justified-text">
                            <b>Present Position :</b> Director, IIT Dharwad
                          </p>

                        </div>
                      </div>
                    </div>


                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="https://nitw.ac.in/api/static/files/Dr._P._V._Rao_2023-11-3-11-57-37.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Dr. P. V. Rao</h6>
                          <p className="justified-text">
                            <b>Present Position :</b> Dean, Planning & Development, IIT Delhi
                          </p>

                        </div>
                      </div>
                    </div>





                    <div className="col-md-3 col-6">
                      <div className="card ">
                        <img src="https://nitw.ac.in/api/static/files/Dr._M._Ramgopal_2023-11-3-11-58-21.jpg" alt="" className="img-fluid" />
                        <div className="data">
                          <h6>Dr. M. Ramgopal</h6>
                          <p className="justified-text">
                            <b>Present Position :</b> Professor, IIT Kharagpur
                          </p>

                        </div>
                      </div>
                    </div>












                  </div>
                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                  aria-labelledby="v-pills-AcademicResearchGroup-tab">
                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Production/Manufacturing Engineering
                        </h4>
                      </header>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16269/16269.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Y. Ravi Kumar</h6>
                            <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9440868867 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:yrk@nitw.ac.in ">yrk@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16249/16249.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Surya Prakash Rao Ch</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9885238699 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto: cspr@nitw.ac.in "> cspr@nitw.ac.in  </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>










                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16253/16253.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Krishnanand Lanka</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969311 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto: lanka@nitw.ac.in "> lanka@nitw.ac.in  </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>















                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16258/16258.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Venu Gopal A</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i>  9493318537 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:venu@nitw.ac.in ">venu@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16261/16261.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. N . Selvaraj</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969316 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:selva@nitw.ac.in">selva@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16262/16262.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Srinadh K V S </h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9959876835 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:kvsaisrinath@nitw.ac.in ">kvsaisrinath@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16278/16278.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Adepu Kumar</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9492783067 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:adepu_kumar7@nitw.ac.in">adepu_kumar7@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16275/16275.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Joseph Davidson M</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969324 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:jd@nitw.ac.in ">jd@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16273/16273.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. P Subhash Chandra Bose</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i>  8332969405 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:subhash@nitw.ac.in ">subhash@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16522/16522.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. P Vamsi Krishna</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9440843609 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:vamsikrishna@nitw.ac.in ">vamsikrishna@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16272/16272.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. T. Sadasiva Rao</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i>  9440035300 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:sadasiv@nitw.ac.in ">sadasiv@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16936/16936.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Venkatesh Gudipadu</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 7093901757 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:venkatesh@nitw.ac.in ">venkatesh@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17068/17068.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Marrapu Bhargava</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9004669353 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:bhargava@nitw.ac.in ">bhargava@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17016/17016.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Shivraman </h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9760613653 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:shivraman@nitw.ac.in ">shivraman@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17017/17017.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Manjaiah M</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9740847669 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:Manjaiah.m@nitw.ac.in ">Manjaiah.m@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17020/17020.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Gangadharudu Talla</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9440747257 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:gtalla@nitw.ac.in ">gtalla@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17089/17089.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Srinagalakshmi Nammi </h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i>9498065663<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:snl.nammi@nitw.ac.in ">snl.nammi@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>


                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Thermal Engineering</h4>
                      </header>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16435/16435.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Devanuri Jaya Krishna</h6>
                            <span>Coordinator</span>

                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969369 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:djayakrishna@nitw.ac.in ">djayakrishna@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16251/16251.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Kotha Madhu Murthy</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9492416560 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:madhu@nitw.ac.in ">madhu@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16264/16264.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Ravi Kumar Puli</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969317 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:pravi@nitw.ac.in"> pravi@nitw.ac.in  </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16260/16260.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. G. Amba Prasad Rao</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969315 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:gap@nitw.ac.in"> gap@nitw.ac.in  </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>








                    {/* <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16266/16266.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Gupta G R K</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9392231833 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:gupta@nitw.ac.in"> gupta@nitw.ac.in  </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}







                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16270/16270.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. A. Veeresh Babu</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9441120203 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:avbabu@nitw.ac.in "> avbabu@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>










                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16277/16277.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. G. Naga Srinivasulu</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9440509659 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:gns@nitw.ac.in"> gns@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://nitw.ac.in/api/static/files/Dr._Kiran_Kumar_K_2024-3-5-18-10-37.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Kiran Kumar K </h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9494153851 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:kiran@nitw.ac.in ">kiran@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16283/16283.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. V. Rajesh Khana Raju</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 08702462316 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:vrkraju@nitw.ac.in ">vrkraju@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16436/16436.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Chandramohan V. P.</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969329 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:vpcm80@nitw.ac.in">vpcm80@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16513/16513.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Karthik Balasubramanian</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969370 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:karthikb@nitw.ac.in">karthikb@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17095/17095.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Ravi Kumar Peetala</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9884889299 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:ravipeetala@nitw.ac.in ">ravipeetala@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17063/17063.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Anant Kumar Rai</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9548285567 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:anant@nitw.ac.in ">anant@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17076/17076.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Hari Krishna Chilukoti</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9014029382<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:chk@nitw.ac.in ">chk@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17019/17019.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Satyanand Abraham</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9820727846   <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto: abrahams@nitw.ac.in "> abrahams@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17066/17066.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Prasanth Anand Kumar Lam </h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9840845194 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:prasanth_anand@nitw.ac.in ">prasanth_anand@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17022/17022.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Chinige Sampath Kumar </h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8331071528 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:drsam@nitw.ac.in ">drsam@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>




                  <div className="row academic_group">
                    <div className="col-md-12">
                      <header>
                        <h4>Machine Design </h4>
                      </header>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16280/16280.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Srikanth Korla</h6>
                            <span>Coordinator</span>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969327 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:korla@nitw.ac.in ">korla@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16257/16257.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Bangarubabu Popuri</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9440206342 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:bangaru@nitw.ac.in ">bangaru@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16268/16268.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Suresh Babu V</h6>

                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9000416646 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto: vsbabu@nitw.ac.in "> vsbabu@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16263/16263.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Narasimha Rao R</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969373<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:rnrao@nitw.ac.in">rnrao@nitw.ac.in</a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16276/16276.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. V Vasu </h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8019789214<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto: vasu@nitw.ac.in"> vasu@nitw.ac.in</a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>




                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16420/16420.jpg" alt="" className="img-fluid" width="150" height="180" />
                          </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Hari Kumar Voruganti</h6>

                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8985929103 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:harikumar@nitw.ac.in ">harikumar@nitw.ac.in </a> </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16438/16438.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Syed Ismail</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8332969367 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:syedismail7@nitw.ac.in ">syedismail7@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16439/16439.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. B. Satish Ben</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9848065254 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:satishben@nitw.ac.in ">satishben@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16533/16533.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Gujjala Raghavendra</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9985803317 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:raghavendra.gujjala@nitw.ac.in ">raghavendra.gujjala@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/16901/16901.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Suresh Periyannan</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 8248754482 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:sureshp@nitw.ac.in ">sureshp@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17018/17018.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Gaurav Kumar Sharma</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 9611241015 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:gaurav@nitw.ac.in ">gaurav@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17021/17021.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Thamarai Selvan</h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 7901070374 <br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:thamarai29@nitw.ac.in ">thamarai29@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="card">
                        <div className="d-flex align-items-center">
                          <div className="image"> <img src="https://wsdc.nitw.ac.in/facultynew/media/17090/17090.jpg" alt=""
                            className="img-fluid" width="150" height="180" /> </div>
                          <div className="ml-3 w-100">
                            <h6 className="mb-0 mt-0">Prof. Ramesh Babu Pallekonda </h6>
                            <div className=" mt-2">
                              <p className="contact-info"> <i className="fa fa-phone"
                                aria-hidden="true"></i> 6033298652<br /> <i
                                  className="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:pallekonda@nitw.ac.in ">pallekonda@nitw.ac.in </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>



                  </div>






                </div>



                {/* <div className={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                                            aria-labelledby="nav-AdjunctFaculty-tab">
                                
                                                    <div className="col-md-12 profile-info-individual">
                                                    <div className="row">
                                                       
                                                    <div className="col-md-9">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Tata_Narasinga_Rao_2023-10-9-16-55-33.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0"> Prof. Tata Narasinga Rao </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:director@arci.res.in">director@arci.res.in, tata@arci.res.in</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        <div className="col-md-9">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Pisupati_V_Sharma_2023-10-9-16-56-57.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0"> Prof. Pisupati V Sharma </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:sxp17@psu.edu">sxp17@psu.edu</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                        <div className="col-md-9">
                                            <div className="card">
                                                <div className="d-flex align-items-center">
                                                    <div className="image"> <img src="https://nitw.ac.in/api/static/files/Prof._Ajay_K_Dalai_2023-10-9-16-57-53.jpg" alt=""
                                                        className="img-fluid"  width="150" height="180"/> </div>
                                                    <div className="ml-3 w-100">
                                                        <h6 className="mb-0 mt-0"> Prof. Ajay K Dalai </h6>
                                                        <div className=" mt-2">
                                                            <p className="contact-info"> <i
                                                                    className="fa fa-envelope" aria-hidden="true"></i> <a
                                                                        href="mailto:ajay.dalai@usask.ca">ajay.dalai@usask.ca</a>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>


                                            </div>
                                            </div>














                                            </div> */}
                <div className={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                  aria-labelledby="nav-ResearchScholars-tab">
                  <div className="row phd-std-row">
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Ajmeera_Suresh_2023-11-10-11-23-5.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>IC Engines, Alternate fuels </b>
                              </p>

                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Ajmeera Suresh</h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />

                        <p className="contact-info">  9493217930
                          <br /> <span> <a href="mailto:suresh22@student.nitw.ac.in" >suresh22@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>

                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Mantri_Sandeep_Kumar_2023-11-10-11-24-28.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Pulsating Heat Pipe </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Mantri Sandeep Kumar </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />

                        <p className="contact-info"> 8919522189
                          <br /> <span> <a href="mailto:ms721035@student.nitw.ac.in" >ms721035@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Balla_Sai_Kumar_2023-11-10-11-25-48.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Additive Manufacturing </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Balla Sai Kumar </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />

                        <p className="contact-info"> 9581158235
                          <br /> <span> <a href="mailto:sb712072@student.nitw.ac.in" >sb712072@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Navam_Shrivastava_2023-11-10-11-31-30.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Analysis of flow characteristics and hydro-abrasive erosion in Pelton turbine components  </b>
                              </p>


                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Navam Shrivastava </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />

                        <p className="contact-info"> 8770491395
                          <br /> <span> <a href="mailto:ns720040@student.nitw.ac.in" >ns720040@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>

                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Kumar_Raja_Gudaru_2023-11-10-11-32-30.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Manufacturing  </b>
                              </p>



                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Kumar Raja Gudaru</h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />

                        <p className="contact-info"> 7382113353
                          <br /> <span> <a href="mailto:gkumarraja9686@gmail.com" >gkumarraja9686@gmail.com</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Venkata_Vijaya_Kumar_P_2023-11-10-11-33-19.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Hybrid Joining Techniques (composite materials + metals) </b>
                              </p>

                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Venkata Vijaya Kumar P</h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />

                        <p className="contact-info"> 7901395417
                          <br /> <span> <a href="mailto:vv721051@student.nitw.ac.in" >vv721051@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Nikhil_Bharat_2023-11-10-11-35-12.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Nanocomposites, Metal Matrix Composite, Laser Assisted Machining, Advanced Manufacturing Technology  </b>
                              </p>



                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Nikhil Bharat</h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />

                        <p className="contact-info"> 7488656868
                          <br /> <span> <a href="mailto:nikhilbharat@student.nitw.ac.in" >nikhilbharat@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Angoth_Vinod_Kumar_2023-11-10-11-35-59.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Composite materials   </b>
                              </p>



                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Angoth Vinod Kumar</h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />

                        <p className="contact-info"> 9951062293
                          <br /> <span> <a href="mailto:av21merer08@student.nitw.ac.in" >av21merer08@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Uday_Kumar_Jonnala_2023-11-10-11-37-28.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Additive Manufacturing in Medical Applications   </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Uday Kumar Jonnala</h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />

                        <p className="contact-info"> 7842177006
                          <br /> <span> <a href="mailto:uj712083@student.nitw.ac.in" >uj712083@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Dudla_Kishore_babu_2023-11-10-11-38-18.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Additive Manufacturing Biomedical applications   </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Dudla Kishore babu</h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />

                        <p className="contact-info">9676653137
                          <br /> <span> <a href="mailto:dk23mer1r12@student.nitw.ac.in" >dk23mer1r12@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>





                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Pranaya_Joshi_Chandole_2023-11-10-11-39-27.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Bio-composite materials  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Pranaya Joshi Chandole </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />

                        <p className="contact-info">9030371797
                          <br /> <span> <a href="mailto:pc21merer04@student.nitw.ac.in" >pc21merer04@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>


                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Gamidi_Kartheek_2023-11-10-11-40-5.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Advanced machining Techniques </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Gamidi Kartheek </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9014745098
                          <br /> <span> <a href="mailto:gk718139@student.nitw.ac.in" >gk718139@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Segu_Sriprada_2023-11-10-11-42-30.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Production </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Segu Sriprada  </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9440586864
                          <br /> <span> <a href="mailto:ss712073@student.nitw.ac.in" >ss712073@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Banoth_Srinu_2023-11-10-11-43-12.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Manufacturing, Machining </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Banoth Srinu</h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9962286853
                          <br /> <span> <a href="mailto:Bs22mer1r09@student.nitw.ac.in" >Bs22mer1r09@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Koteswara_Rao_Unnam_2023-11-10-11-50-35.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Vibration isolators </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Koteswara Rao Unnam</h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9550819282
                          <br /> <span> <a href="mailto:koti4unnam@gmail.com" >koti4unnam@gmail.com</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Gurram_Raghavendra_Reddy_2023-11-10-11-53-19.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Fuel cells</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Gurram Raghavendra Reddy </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">8897269292
                          <br /> <span> <a href="mailto:gr23mer1r06@student.nitw.ac.in" >gr23mer1r06@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Amit_Kumar_Panigrahy_2023-11-10-11-54-6.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Aerodynamics</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Amit Kumar Panigrahy </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">8249893160
                          <br /> <span> <a href="mailto:ak22mer1p04@student.nitw.ac.in" >ak22mer1p04@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Manjunath_Hn_2023-11-10-11-54-58.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Heat Transfer(Battery Thermal Management)</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Manjunath Hn. </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">7026661155
                          <br /> <span> <a href="mailto:mh712063@student.nitw.ac.in" >mh712063@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Mahammod_Babar_Pasha_2023-11-10-11-55-49.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Triboinformatics-based Analysis of Magnesium alloys and Composites</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Mahammod Babar Pasha  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9704557339
                          <br /> <span> <a href="mailto:pashamd@student.nitw.ac.in" >pashamd@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>






                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Bondada_Yeswanth_Yadav_2023-11-10-11-56-38.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>PEM Fuel cell</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Bondada Yeswanth Yadav  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">7659052581
                          <br /> <span> <a href="mailto:by21merer10@student.nitw.ac.in" >by21merer10@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>





                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Ali_Ahmed_2023-11-10-11-57-39.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Thermal Engineering </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Ali Ahmed  </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9490015758
                          <br /> <span> <a href="mailto:aa721046@student.nitw.ac.in" >aa721046@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Banoth_Swapna_2023-11-10-11-58-33.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Metal matrix composites </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Banoth Swapna   </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">7082640114
                          <br /> <span> <a href="mailto:bswapna@student.nitw.ac.in" >bswapna@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>






                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Mudar_Taha_2023-11-10-13-18-22.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Solar Energy  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Mudar Taha    </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">6301581680
                          <br /> <span> <a href="mailto:mt22mer2f01@student.nitw.ac.in" >mt22mer2f01@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Bandaru_Venkatasivaprasadreddy_2023-11-10-13-19-47.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Composite Materials  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Bandaru Venkatasivaprasadreddy    </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9666525378
                          <br /> <span> <a href="mailto:bv23mer1p01@student.nitw.ac.in" >bv23mer1p01@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Gara_Dheeraj_Kumar_2023-11-10-13-20-43.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Bioactive glass materials </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Gara Dheeraj Kumar  </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9700749752
                          <br /> <span> <a href="mailto:dk720045@student.nitw.ac.in" >dk720045@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>


                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Jayasimha_Reddy_Kodandapuram_2023-11-10-13-21-44.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>IC Engines </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Jayasimha Reddy Kodandapuram </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9966367889
                          <br /> <span> <a href="mailto:jr720046@student.nitw.ac.in" >jr720046@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>





                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Mood_Harish_2023-11-10-15-6-16.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>IC engine with alternate fuels  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Mood Harish </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9490317311
                          <br /> <span> <a href="mailto:mh21merer13@student.nitw.ac.in" >mh21merer13@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>





                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Suresh_Goka_2023-11-10-15-7-33.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Additive Manufacturing  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Suresh Goka</h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9492417464
                          <br /> <span> <a href="mailto:sg21merer06@student.nitw.ac.in" >sg21merer06@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Sk_Md_Khaja_2023-11-10-15-9-0.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Composites </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Sk Md Khaja</h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9589851851
                          <br /> <span> <a href="mailto:skmdkhaja@gmail.com" >skmdkhaja@gmail.com</a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Jakirahemed_Md_2023-11-10-15-9-51.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Wire arc additive manufacturing of Functionally graded materials </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Jakirahemed Md</h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9848697767
                          <br /> <span> <a href="mailto:jm712064@student.nitw.ac.in" >jm712064@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Vishal_Ramdas_Warule_2023-11-10-15-10-40.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Thermal management of battery system  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Vishal Ramdas Warule</h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">8087525660
                          <br /> <span> <a href="mailto:wv23mer1r09@student.nitw.ac.in" >wv23mer1r09@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Malleswari_Karanam_2023-11-10-15-11-27.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Supply Chain  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Malleswari Karanam </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9948063549
                          <br /> <span> <a href="mailto:mk720049@student.nitw.ac.in" >mk720049@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Bhukya_Balaji_2023-11-10-15-12-8.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>IC Engines  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Bhukya Balaji </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">7780299096
                          <br /> <span> <a href="mailto:balu0410@student.nitw.ac.in" >balu0410@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Divya_Pasunuti_2023-11-10-15-12-57.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Additive Manufacturing </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Divya Pasunuti </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9177953563
                          <br /> <span> <a href="mailto:pasunuti.divya@gmail.com" >pasunuti.divya@gmail.com</a> </span></p>

                      </div>
                    </div>


                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Pittala_Anil_Kumar_2023-11-10-15-13-39.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Functional graded materials  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Pittala Anil Kumar  </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">7702649006
                          <br /> <span> <a href="mailto:Pa22mer2p09@student.nitw.ac.in " >Pa22mer2p09@student.nitw.ac.in </a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/B_Amarendhar_Rao_2023-11-10-15-15-54.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Mechanical Engineering  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">B Amarendhar Rao   </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9063027204
                          <br /> <span> <a href="mailto:amarendhar.rao@gmail.com  " >amarendhar.rao@gmail.com  </a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Vishnu_Prasad_K_R_2023-11-10-15-16-40.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Computer Aided Design and Additive Manufacturing  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Vishnu Prasad K R </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9495512528
                          <br /> <span> <a href="mailto:vp721047@student.nitw.ac.in" >vp721047@student.nitw.ac.in </a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Manjunatha_2023-11-10-15-17-27.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Machine Design  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Manjunatha </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">8247410690
                          <br /> <span> <a href="mailto:mm23mer1r14@student.nitw.ac.in" >mm23mer1r14@student.nitw.ac.in </a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Vivek_Kumar_Misha_2023-11-10-15-18-38.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>CI engine combustion</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Vivek Kumar Misha </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">7223829029
                          <br /> <span> <a href="mailto:vk721044@student.nitw.ac.in" >vk721044@student.nitw.ac.in </a> </span></p>

                      </div>
                    </div>


                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Ramesh_S_2023-11-10-15-19-26.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Thermal Management of Electronics</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Ramesh S </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9949290464
                          <br /> <span> <a href="mailto:rs22mer2p04@student.nitw.ac.in" >rs22mer2p04@student.nitw.ac.in </a> </span></p>

                      </div>
                    </div>





                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Anil_Kumar_S_2023-11-10-15-20-11.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Fuel Cells</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Anil Kumar S </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9290678008
                          <br /> <span> <a href="mailto:ak23mer1p04@student.nitw.ac.in" >ak23mer1p04@student.nitw.ac.in </a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Rahul_Datta_2023-11-10-15-20-55.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Production Engineering (Mechanical Engineering)</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Rahul Datta </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">7797759580
                          <br /> <span> <a href="mailto:rd721041@student.nitw.ac.in" >rd721041@student.nitw.ac.in </a> </span></p>

                      </div>
                    </div>





                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/K_A_Srinivasa_Raghavan_2023-11-10-15-22-1.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Micro Combustion</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">K A Srinivasa Raghavan </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9483141564
                          <br /> <span> <a href="mailto:Kasr719063@student.nitw.ac.in" >Kasr719063@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Sudarsi_Divya_2023-11-10-15-22-44.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>On Lasers</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Sudarsi Divya </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">7842312105
                          <br /> <span> <a href="mailto:sd23mer1r03@student.nitw.ac.in" >sd23mer1r03@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Duddela_Tejashwini_2023-11-10-15-24-8.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Thermal</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Duddela Tejashwini  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">7013751781
                          <br /> <span> <a href="mailto:dt23mer1r11@student.nitw.ac.in" >dt23mer1r11@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>


                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Sujoy_Kumar_Dolui_2023-11-10-15-24-58.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Mechanical Engineering</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Sujoy Kumar Dolui </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9963294005
                          <br /> <span> <a href="mailto:sujoykdolui@gmail.com" >sujoykdolui@gmail.com</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Anil_Kumar_Maheshavaram_2023-11-10-17-8-13.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Additive manufacturing </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Anil Kumar Maheshavaram  </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9989000646
                          <br /> <span> <a href="mailto:vv23mer1p05@student.nitw.ac.in" >vv23mer1p05@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Desai_Sathya_Narayana_Rao_2023-11-10-17-9-20.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Solar Drying</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Desai Sathya Narayana Rao  </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">7995467316
                          <br /> <span> <a href="mailto:ds23mer1p08@student.nitw.ac.in" >ds23mer1p08@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>

                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Locherla_Daloji_2023-11-10-17-9-58.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>BioGlasses</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Locherla Daloji  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">8897292561
                          <br /> <span> <a href="mailto:ld22mer1r07@student.nitw.ac.in" >ld22mer1r07@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Veerla_Sai_Surendra_2023-11-10-17-10-54.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Wire Arc Additive Manufacturing</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Veerla Sai Surendra  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">8121234850
                          <br /> <span> <a href="mailto:vs22mer1q02@student.nitw.ac.in" >vs22mer1q02@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Chinchedu_Raghuvaran_2023-11-10-17-11-47.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Oscillating heat pipe </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Chinchedu Raghuvaran  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">7702407153
                          <br /> <span> <a href="mailto:chinchedu@student.nitw.ac.in" >chinchedu@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Banka_Jagadeesh_2023-11-10-17-12-52.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Centrifugal Slurry Pump </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Banka Jagadeesh  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">79573151506
                          <br /> <span> <a href="mailto:jagadeesh_jrf0922@nitw.ac.in" >jagadeesh_jrf0922@nitw.ac.in</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Muniraju_M_2023-11-10-17-13-58.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Near dry EDM </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Muniraju M  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9731997233
                          <br /> <span> <a href="mailto:mm721037@student.nitw.ac.in" >mm721037@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Bhukya_Shobhan_2023-11-10-17-14-46.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Abrasive flow finishing (Advanced manufacturing) </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Bhukya Shobhan </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9177309902
                          <br /> <span> <a href="mailto:bhukyashobhan25@gmail.com" >bhukyashobhan25@gmail.com</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/K_Vijaya_Bhaskar_Reddy_2023-11-10-17-15-35.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Joining </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">K Vijaya Bhaskar Reddy  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">7416425885
                          <br /> <span> <a href="mailto:kvbr61@student.nitw.ac.in" >kvbr61@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Ravula_Sai_Charan_2023-11-10-17-19-0.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Thermal </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Ravula Sai Charan   </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9491246983
                          <br /> <span> <a href="mailto:rs21merer01@student.nitw.ac.in" >rs21merer01@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Shyamsunder_J_2023-11-10-17-19-54.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Laser Technology </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Shyamsunder J  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9908551199
                          <br /> <span> <a href="mailto:sj22mer2r03@student.nitw.ac.in" >sj22mer2r03@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Motru_deepak_2023-11-10-17-20-47.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Machine design </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Motru deepak  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9295559997
                          <br /> <span> <a href="mailto:share2deepu@gmail.com" >share2deepu@gmail.com</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Bharat_Kumar_Talluri_2023-11-10-17-21-30.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Composite Materials  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Bharat Kumar Talluri </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9502980399
                          <br /> <span> <a href="mailto:bk721055@student.nitw.ac.in" >bk721055@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>
                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/A_Naresh_2023-11-10-17-22-19.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Production  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">A Naresh </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9550007169
                          <br /> <span> <a href="mailto:an721048@student.nitw.ac.in" >an721048@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>

                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Karmankar_Rahul_Ganpat_2023-11-10-17-23-11.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Machine design  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Karmankar Rahul Ganpat </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">7083001080
                          <br /> <span> <a href="mailto:kr23mer1r07@student.nitw.ac.in" >kr23mer1r07@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>

                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Nageswar_Bhukya_2023-11-10-17-24-4.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Fuel cell </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Nageswar Bhukya </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info"> 9948228246
                          <br /> <span> <a href="mailto:nag.nit9@gmail.com" >nag.nit9@gmail.com</a> </span></p>

                      </div>
                    </div>

                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Bhumpelly_Saiprasad_2023-11-10-17-24-56.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Manufacturing </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Bhumpelly Saiprasad </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info"> 8074651277
                          <br /> <span> <a href="mailto:bs23mer1r05@student.nitw.ac.in" >bs23mer1r05@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>






                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Satish_Jain_2023-11-10-17-25-37.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Bioactive glass, Scaffolds, Composites</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Satish Jain</h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info"> 7277427037
                          <br /> <span> <a href="mailto:satishjain@student.nitw.ac.in" >satishjain@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Gunishetty_Sumithra_2023-11-10-17-26-25.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Composites for joining applications</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Gunishetty Sumithra</h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info"> 8179140572
                          <br /> <span> <a href="mailto:gs712066@student.nitw.ac.in" >gs712066@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>





                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Abbadi_charan_reddy_2023-11-10-17-27-17.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Polymer composites</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Abbadi charan reddy</h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info"> 8328528298
                          <br /> <span> <a href="mailto:ac21merep22@student.nitw.ac.in" >ac21merep22@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>





                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/A._Sreekanth_2023-11-10-17-28-16.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>3D Printing, Post Processing of A.M. Components</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">A. Sreekanth</h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info"> 9652032966
                          <br /> <span> <a href="mailto:as23mer1p03@student.nitw.ac.in" >as23mer1p03@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Shivank_Adarsh_Tyagi_2023-11-10-17-28-56.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Additive Manufacturing</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Shivank Adarsh Tyagi</h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info"> 9624970836
                          <br /> <span> <a href="mailto:sa721043@student.nitw.ac.in" >sa721043@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>





                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Guduri_Moshe_Babu_2023-11-10-17-29-51.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Micro drilling of Ti6Al4V alloy</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Guduri Moshe Babu</h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info"> 9989719928
                          <br /> <span> <a href="mailto:23mer1r13@student.nitw.ac.in" >23mer1r13@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Banoth_Manoj_Kumar_2023-11-10-17-30-33.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Electric Discharge Machining</b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Banoth Manoj Kumar</h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info"> 9652556606
                          <br /> <span> <a href="mailto:bm22mer1r05@student.nitw.ac.in" >bm22mer1r05@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Sriramula_sampoorna_2023-11-10-17-31-14.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Composites </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Sriramula sampoorna</h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info"> 9959594774
                          <br /> <span> <a href="mailto:ss23mer1r02@student.nitw.ac.in" >ss23mer1r02@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Pradeep_Kumar_Prathipati_2023-11-10-17-31-55.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Production technology </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Pradeep Kumar Prathipati</h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info"> 9618422757
                          <br /> <span> <a href="mailto:pradeep@rguktn.ac.in" >pradeep@rguktn.ac.in</a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Maraboina_Raju_2023-11-10-17-33-39.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Ultrasonic assisted magnetic abrasive finishing  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Maraboina Raju </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info"> 7013950542
                          <br /> <span> <a href="mailto:raju.mara1@gmail.com" >raju.mara1@gmail.com</a> </span></p>

                      </div>
                    </div>










                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Monex_Sharma_2023-11-10-17-35-12.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Robotics and Optimization  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Monex Sharma </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info"> 9373422506
                          <br /> <span> <a href="mailto:msharma@student.nitw.ac.in" >msharma@student.nitw.ac.in</a> </span></p>

                      </div>
                    </div>





                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Alli_Anil_Kumar_2023-11-10-17-35-57.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Alternate fuels  </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Alli Anil Kumar  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info"> 8919638603
                          <br /> <span> <a href="mailto:anilkumar927@student.nitw.ac.in " >anilkumar927@student.nitw.ac.in </a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Jagadeeswara_Reddy._E_2023-11-10-17-37-24.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Battery Thermal Management </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Jagadeeswara Reddy. E  </h6>
                        <span>Part-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9940031480
                          <br /> <span> <a href="mailto:JE712069@student.nitw.ac.in " >JE712069@student.nitw.ac.in </a> </span></p>

                      </div>
                    </div>




                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Boddeti_Jagadish_2023-11-10-17-38-5.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Battery Thermal Management </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Boddeti Jagadish  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">7404663397
                          <br /> <span> <a href="mailto:bj22mer2r05@student.nitw.ac.in" >bj22mer2r05@student.nitw.ac.in </a> </span></p>

                      </div>
                    </div>





                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Chouhan_Balaji_2023-11-10-17-38-50.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Alternative fuels </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Chouhan Balaji  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">8008040301
                          <br /> <span> <a href="mailto:Bc712065@student.nitw.ac.in" >Bc712065@student.nitw.ac.in </a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Ippaka_Naveen_Kumar_2023-11-10-17-39-37.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Thermal Energy </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Ippaka Naveen Kumar  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">7906812080
                          <br /> <span> <a href="mailto:in21merer05@student.nitw.ac.in " >in21merer05@student.nitw.ac.in  </a> </span></p>

                      </div>
                    </div>



                    <div className="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                      <div className="phd-std-member">
                        <div className="phd-std-img">
                          <img src="https://nitw.ac.in/api/static/files/Vanam_Nagendra_2023-11-10-17-40-38.jpg" className="img-fluid image" alt="" width="150" height="180" />
                          <div className="overlay">
                            <div className="std-details ">
                              <p className="justified-text">
                                Research Area : <b>Minichannels </b>
                              </p>
                            </div>
                          </div>
                        </div>
                        <h6 className="std-title">Vanam Nagendra  </h6>
                        <span>Full-Time Research Scholar </span>
                        <hr />
                        <p className="contact-info">9642214326
                          <br /> <span> <a href="mailto:nv712082@student.nitw.ac.in" >nv712082@student.nitw.ac.in </a> </span></p>

                      </div>
                    </div>








                  </div>



                </div>

                <div className={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                  aria-labelledby="v-pills-labs-tab">
                  <div className="row laboratory-facilities">




                    <div className="details">

                      <h3>Central Workshop</h3>
                      <p className="justified-text">

                        The central workshop, a central facility, was merged with the Department of Mechanical Engineering in the year 1992. The Workshop consisting of Fitting Shop, Welding shop, Carpentry shop, Power Tools shop, Foundry Shop, Machine Shop and House wiring shop.

                      </p>

                      <p className="justified-text">

                        The facilities are used for the conduction of regular sessional classes as a part of the curriculum for students at various levels pertaining to different departments. In addition to housing many conventional machines and equipment it also has most modern fabrication and production units in tune with the recent technological developments. Some of these include Electro Chemical Machine, EDM, Tig and Mig welding machines, Tool and cutter Grinding Machine, Mitutoyo Tool Makers Microscope, Kistler 3 component force measurement Dynamometer, Variable Speed Precision Lathe, Tool Monitoring Equipment during machining and CNC Engraving Machine etc.</p>
                      <p className="justified-text">

                        Steps are afoot for further modernization of the central workshop utilizing the funds made available through MHRD / MODROBS / TEQIP etc.</p>


                      <p className="justified-text">

                        Workshop facilities are extended to facilitate execution of different projects / research work at UG, PG and Doctoral level, under the guidance of the respective guides.


                      </p>



                      <p className="justified-text">

                        Some of the facilities available in the Central Workshops.

                      </p>
                    </div>
                    <img src="https://nitw.ac.in/api/static/files/Central_Workshop_2023-11-13-12-44-9.jpg" alt="" className="img-fluid mb-5" />




                    <h3>Design Engg. Labs</h3>

                    <h3>Computational Research Lab</h3>

                    <p className="justified-text">
                      To facilitate the smooth running of lab course of UG and PG that require computational resource and also project works of the students and faculty.                             </p>

                    <p className="justified-text">
                      Lab Coverage: Modeling, Simulation and Analysis: Solid Modeling, Programming, DIN/ISO programming, CNC training.
                    </p>

                    {/* <img src="https://nitw.ac.in/api/static/files/Central_Workshop_2023-11-13-12-44-9.jpg" alt="" className="img-fluid mb-5"/>   */}


                    <h3>Kinematics Lab</h3>

                    <h4> List of Experiments</h4>

                    <p className="justified-text">
                      <b> A Kinematic Models:    </b>                         </p>


                    <p className="justified-text">
                      <b> Inversions of four bar and Slider Crank Mechanism:</b> Four bar Mechanism, Elliptical Tracer, Scotch yoke mechanism, Oldham coupling, Crank oscillating drive, slider crank, Crank and slotted link mechanism
                    </p>

                    <p className="justified-text">
                      <b>  Lower pairs: </b>Pantograph Mechanism, Peaucellier drive, Watt’s Mechanism, Cycloidal motion                                 </p>


                    <p className="justified-text">
                      <b>      Gears and Gear Trains:</b> Spur gear (single and Multi-Stage), Helical Gear, Bevel gear, Herringbone gear, Worm gear, Rack and pinion arrangement;
                    </p>



                    <p className="justified-text">
                      Compound gear train, Reverted gear train, Epicyclic gear train, sun and planet gear
                    </p>


                    <p className="justified-text">
                      <b> Cams and Clutches:</b>Geneva Drive, radial, cylindrical and face cams; Centrifugal clutch, Cone clutch and dog clutch
                    </p>

                    <p className="justified-text">
                      <b> Belt drives: </b>Flat Belt drive, Belt drive, Chain Drive, Friction wheel drive, multi stage and contra rotation belt drives, Frictional wheel drive
                    </p>





                    <p className="justified-text">
                      <b> B Dynamics:    </b>                         </p>

                    <p className="justified-text">
                      <b> Undamped free vibrations-I:    </b>    </p>
                    <p className="justified-text">
                      Estimate the acceleration due to gravity using Bifilar Pendulum<br></br>
                      Determine the mass moment of inertia of a given object using trifilar pendulum
                      <br></br>
                      Compound Pendulum (Cg and MI)
                      <br></br>
                      <b>  Undamped free vibrations-II: </b>
                      <br></br>
                      Find fn of (i) a bar resting on a cylindrical surface (ii) a semi cylindrical shell resting on a Horizontal surface
                      <br></br>
                      Locate centre of mass G and the MI about G of a CR
                      <br></br>
                      <b>Damped Free Vibrations I:</b>Determine the viscous damping coefficient of a given viscous damper;<br>
                      </br>
                      Estimate the damping in a given vibrating spring through logarithmic decrement
                      <br>
                      </br>
                      <b>
                        Damped Free Vibrations II:
                      </b>
                      <br>
                      </br>
                      Determine the coefficient of Friction Between Two surfaces using Coulomb damping equipment
                      <br></br>
                      <b>Two degree of Freedom System:</b>
                      <br></br>Coupled pendulum: Determine the natural frequencies of a coupled pendulum through normal mode of vibrations and Beat Phenomenon
                      <b>Cantilever Beam:</b>
                      <br></br>Examine the mode shapes of a Cantilever using Vibration Exciter power oscillator setup
                      <b>Balancing Apparatus:</b>
                      <br></br>
                      Demonstration of imbalance vibration at different speeds and perform balancing operation
                      <b>Universal Vibration System:</b>
                      <br></br>
                      Experiments with pendulums, bar-type oscillators, damped and undamped resonance, absorber effect in multi-mass oscillators
                      <b>Whirling of Shafts Experiment: </b>
                      <br></br>
                      Investigation of Laval rotor (critical speed, self-alignment), Natural modes on a shaft with continuous mass distribution (varying bearing clearances, shaft diameters and lengths)


                    </p>




                    <img src="https://nitw.ac.in/api/static/files/Kinematic_Models_2023-11-13-17-20-47.jpg" alt="" className="img-fluid mb-5" />



                    <img src="https://nitw.ac.in/api/static/files/B_Dynamics_2023-11-13-17-35-51.jpg" alt="" className="img-fluid mb-5" />




                    <h3>Laser Processing Lab</h3>

                    <h6><a href="https://nitw.ac.in/api/static/files/Laser_Processing_Lab_2023-11-13-17-40-5.pdf"> For Further details about the Lab, Please click Here</a></h6>
                    <h7> Nd: YAG Laser</h7>

                    <img src="https://nitw.ac.in/api/static/files/Laser_Processing_Lab_2023-11-13-17-42-50.jpg" alt="" className="img-fluid mb-5" />

                    <p className="justified-text">

                      Laser texturing is a process that alters a material's surface properties by modifying its texture and roughness. The laser beam creates micropatterns on the surface through laser ablation, removing layers with micrometer precision and perfect repeatability, typical patterns include dimples, grooves, and free forms. Laser texturing changes surface morphology and surface chemistry of the solid surface thus modifying surface wettability.

                    </p>



                    <h4>Motorised Micro Motion Stage</h4>

                    <p className="justified-text">
                      <b>Specifications:</b>
                      <b> Drive details:</b> <br></br>
                      0.4 mm pitch lead screw<br></br>
                      Positioning accuracy- 0.001mm<br></br>
                      Repeatability- 0.001 mm<br></br>
                      Resolution- 0.000125 mm     <br></br>
                      Speed- 1.6 mm/s<br></br>

                      <b> Stage Range:</b><br></br>

                      X-axis: 27157.1875 μm<br></br>
                      Y-axis: 29377.5 μm<br></br>
                      Z-axis: 26743.4375 μm<br></br>

                      The motorized micromotion stage is used for linear movement along with three directions, viz. x, y, and z-axis of the specimen which is placed on the micromotion stage.

                    </p>

                    <h3>Laser Power/Energy meter</h3>

                    <img src="https://nitw.ac.in/api/static/files/Laser_Power_Energy_meter_2023-11-13-17-55-35.jpg" alt="" className="img-fluid mb-5" />

                    <p className="justified-text">
                      The laser power/energy is measured by allowing the laser beam to strike onto the energy sensor which measures over a wide range of wavelengths, beam diameter, average power levels and repetition rates. The laser power/energy meter displays the readings such as average power, beam diameter etc. which is connected to energy sensor.


                    </p>


                    <h3>Precision Polarizer Rotator with Linear Movement</h3>


                    <img src="https://nitw.ac.in/api/static/files/Precision_Polarizer_Rotator_with_Linear_Movement_2023-11-13-18-1-32.jpg" alt="" className="img-fluid mb-5" />


                    <p className="justified-text">

                      Precision rotary adjustments are possible with this mount. Plate/sheet polarizers are held between two threaded rings inside a threaded bore. The kinematic design of this mount provides fine linear adjustments in two planes for the mounted polarizer.

                    </p>



                    <h3>Lenses and Accessories</h3>
                    <b>Bi-Convex Lens:</b>

                    <img src="https://nitw.ac.in/api/static/files/Lenses_and_Accessories_2023-11-13-18-12-4.jpg" alt="" className="img-fluid mb-5" />




                    <p className="justified-text">

                      Bi-Convex Lenses are positive spherical lenses having convex surfaces on both sides. These lenses find applications in collecting, focusing, and collimating light.  The laser beam size of 8 mm is converged up to 150-200 µm dimple surface texture onto the specimen by using a 75 mm focal length lens, similarly by using a 100 mm FL lens a dimple of 200-300 µm, a 200 mm FL lens a dimple of 300-400 µm and a 500 mm FL lens, we can texture a dimple of size 500- 600 µm.

                    </p>

                    <br></br>
                    <img src="https://nitw.ac.in/api/static/files/Prism_Holder_and_Lens_Mount_2023-11-13-18-19-6.jpg" alt="" className="img-fluid mb-5" />


                    <p className="justified-text">
                      <b>Breadboard: </b>Breadboards are used mainly to construct small optical setups. The thickness of the breadboard is 60mm. There are tapped holes of 5 mm.
                      <br></br>

                      <b>Right Angle Prisms: </b>Right Angle Prisms are used to redirect a beam of light at 90 degrees from the incident direction or used as a 180° beam retroreflector.           <br></br>


                      <b>Prism Holder and Lens Mount: </b>Prism holder is used for holding the prism which will direct the laser beam on the specimen by deflecting 900. Bi-convex lens is mounted on the Lens mount.<br>
                      </br>
                    </p>



                    <h3>Machine Dynamics Lab</h3>

                    <p className="justified-text">


                      <a href="https://drive.google.com/file/d/0B5VjI8YmohsMZWlMMU1hcHBoNVk/view?resourcekey=0-x2IopjNlK4RQrzT__wkhjg">Lab Information</a> <br></br>
                      Breadboards are used mainly to construct small optical setups. The thickness of the breadboard is 60mm. There are tapped holes of 5 mm.
                      <br></br>

                      This laboratory is mainly to give a practical exposure to the students who are indeed first step of planning theory of vibrations. Most of the experimental setup are being fabricated by the students only. The student is made to physically experience basic concepts such as normal mode vibrations, natural frequency, mode shapes, beat phenomenon, different types of damping.        <br></br>

                      There are equipment such as Electrodynamic exciter, Portable vibration analyzer which enables the student to do some advanced experiments.<br>
                      </br>

                      Apart from the experiments on vibrations the lab is also equipped with rotor balancing machine, twin cylinder locomotive balancing machine, gyroscope demonstration, portable balancing machine. It is planned to procure sophisticated experimental setups in the coming years.
                    </p>





                    <h3>Nano Composite Material Lab</h3>

                    <p className="justified-text">
                      <img src="https://nitw.ac.in/api/static/files/Nano_Composite_Material_Lab_2023-11-14-10-40-52.jpg" alt="" className="img-fluid mb-5" />
                      <br></br>
                      <b>1. Tubular Furnace</b>
                      <br></br>

                      The CTF single zone and TZF 3-zone wire wound tube furnaces use a wire element that is wound directly onto a fixed diameter integral ceramic work tube. The heated length is divided into 3 zones. An extended uniform zone in the mid-section of the work tube is achieved with the use of end zone controllers which track the centre zone for temperature and compensate for the loss of heat from the tube furnace ends. The use of an additional slide-in work tube protects the integral work tube and heating element. It provides a maximum operating temperature of 1200oc.  <br></br>

                      1. This is an extremely flexible arrangement; work tubes are available in different diameters and materials (quartz, ceramic or metal).<br>
                      </br>

                      2. All types of materials can be heated In the tubular furnace
                      <br></br>
                      3. Tube Furnace can used to conduct syntheses and purifications of inorganic compounds and occasionally in organic synthesis.
                      <br>
                      </br>
                      4. Tube furnaces can also be used for thermolysis reactions
                      <br>
                      </br>

                      5.Specific Delayed start / stop  timer function is useful for safety operation.
                    </p>





                    <h3>2. Planetary Ball Mill</h3>

                    <p className="justified-text">
                      <img src="https://nitw.ac.in/api/static/files/Planetary_Ball_Mill_2023-11-14-11-3-11.jpg" alt="" className="img-fluid mb-5" />
                      <br></br>

                      <br></br>

                      Planetary Ball Mills are used wherever the highest degree of fineness is required. In addition to well-proven mixing and size reduction processes, these mills also meet all technical requirements for colloidal grinding and provide the energy input necessary for mechanical alloying. The extremely high centrifugal forces of a planetary ball mill result in very high pulverization energy and therefore short grinding times. The PM 100 is a convenient benchtop model with 1 grinding station.

                      <br></br>
                      <b>Capabilities</b> <br></br>

                      1. Provide a powerful and quick grinding down to nano range.<br>
                      </br>

                      2. Provide two different grinding modes (dry and wet)
                      <br></br>
                      3. Results are reproducible due to energy and speed control
                      <br>
                      </br>

                    </p>


                    <h3>Tribology Laboratory</h3>

                    <p className="justified-text">
                      <img src="https://nitw.ac.in/api/static/files/Air_Jet_Erosion_Test_Rig_2023-11-14-11-17-25.jpg" alt="" className="img-fluid mb-5" />
                      <br></br>
                      <b>1.Air Jet Erosion Test Rig</b>
                      <br></br>

                      This rig consists of an air compressor, a particle feeder, an air particle mixing and accelerating chamber. Dry compressed air is mixed with silica sand abrasive particles, which are fed at a constant rate from a conveyor belt in to the mixing chamber and then accelerated by passing the mixture through a converging nozzle. These accelerated particles impact the specimen at various angles. Required pressure can be adjusted with the pressure regulator fitted with the machine.
                      <br></br>
                      <b>Capabilities</b> <br></br>

                      1. The air jet erosion tester is used to test the erosion resistance of solid materials to a stream of gas containing abrasive particles.<br>
                      </br>

                      2. Materials such as metals, ceramics, minerals, polymers, composites, abrasive and coatings can be tested with this instrument.
                      <br></br>
                      3. The test specimen, temperature, angle of incidence of the jet stream and abrasive particulate speed can be varied to simulate actual conditions.
                      <br>
                      </br>


                    </p>



                    <h3>Production Engg. Labs</h3>

                    <h3>Additive Manufacturing Lab</h3>
                    <p className="justified-text">
                      <img src="https://nitw.ac.in/api/static/files/Additive_Manufacturing_Laboratory_2023-11-14-11-23-29.jpg" alt="" className="img-fluid mb-5" />
                      <b>Objective:</b>

                      <br></br>
                      The additive Manufacturing Laboratory at NIT Warangal is to provide the students with an active learning environment for fabricating three dimensional (3D) models directly from the computer aided design (CAD) data.  Students use the laboratory to model and fabricate metal/polymer complex shaped objected that are used in the engineering and medical applications.
                      <br></br>

                      <b>Lab Coverage:</b>
                      <br></br>
                      Modelling and converting CAD models into FAB3 files, Simulation of process parameters using 3DXpert software, Fabrication of CAD models using DMP Flex100 (Metal 3D Printer) for medical and engineering applications. Post processing equipment for powder preparation, extract the un-sintered powder, removal of supports and final part from substrate, and relieving internal stresses in printed part.
                      <br></br>
                      Modelling and converting CAD models into STL files, Simulation of process parameters using Catalyst software, Fabrication of CAD models using Fused Deposition Modelling (FDM) machine using polymers, Converting CT/MRI scan data using MIMICS Software to fabricate the Bio-models. Post processing equipment for support removal and surface finishing.
                      <br></br>
                      Data acquired in the form of point cloud date via laser scanning using Einscan/Sense 3D system software and converting CAD models into STL files, Simulation of process parameters using Cura/ Flash Print software, Fabrication of CAD models using Fused Deposition Modelling (FDM). Post processing equipment for support removal and surface finishing.

                    </p>

                    <h3>3DXpert Software</h3>

                    <p className="justified-text">
                      <img src="https://nitw.ac.in/api/static/files/3DXpert_Software_2023-11-14-11-29-25.jpg" alt="" className="img-fluid mb-5" />

                      3DXpert is the most advanced and complete software solution for Metal Additive Manufacturing. It offers part preparation, positioning, supports, analysis, printability checks and tray management, etc. Printable lightweight and surface texture lattice structures, Integrated Build Simulation. And also, capability allows the user to set different scanning strategies for different volumes of the part.

                    </p>

                    <img src="https://nitw.ac.in/api/static/files/Sand_Blaster_Bead_Blaster_2023-11-14-11-40-40.jpg" alt="" className="img-fluid mb-5" />

                    <h3>Powder Sieve Station</h3>

                    <p className="justified-text">


                      The vibratory sieve shakers of the series AS 200 are used in research & development, quality control of raw materials, interim and finished products as well as in production monitoring. The sieve shaker is used to agitate the used material and collect those that can be re-used. Here, we expose the sample to all the openings in a shield in a manner that will be expedite the passing the particles smaller than the openings.
                    </p>



                    <h3>Oven</h3>

                    <p className="justified-text">


                      It is consisting of three platforms to pre and post heat treatment of powder to remove moisture absorption inside the powder.

                    </p>



                    <h3>Vacuum Cleaner</h3>

                    <p className="justified-text">


                      The modular design makes it extremely easy to service and upgrade in the field. The simple design makes it easy to clean and sanitize. It needs less maintenance and is suitable for continuous use. It is generally employed in AM lab to remove un-sintered powder from the built part.
                    </p>


                    <h3>Sand Blaster/Bead Blaster</h3>

                    <p className="justified-text">


                      It is the operation of forcibly propelling a stream of abrasive material against a surface under high pressure to smooth a rough surface and shape a surface by removing contaminants. A pressurized fluid typically compressed air, or a centrifugal wheel is used to propel the blasting material.</p>





                    <h3>Band Saw</h3>
                    <img src="https://nitw.ac.in/api/static/files/Band_Saw_2023-11-14-12-25-50.jpg" alt="" className="img-fluid mb-5" />

                    <p className="justified-text">

                      A band saw is a power saw with a long sharp blade consists of a continuous bond of toothed metal stretched between two or more wheels to cut material. They are using in wood working, metal working and plumbing, but may cut a variety of materials. Here, band saw will be used to cut the parts from the plate using a 0.9 mm blade.

                    </p>





                    <h3>FDM Machine</h3>
                    <img src="https://nitw.ac.in/api/static/files/FDM_Machine_2023-11-14-12-35-39.jpg" alt="" className="img-fluid mb-5" />

                    <p className="justified-text">

                      Fused Deposition Modeling (FDM) is a free-form fabrication technology developed by Stratasys®, USA. A plastic filament is unwound from a coil and supplies material to an extrusion nozzle. The nozzle is heated to melt the plastic and has a mechanism which allows the flow of the melted plastic to be turned on and off. The nozzle is mounted to a mechanical stage which can be moved in both horizontal and vertical directions.
                      <br></br>

                      <b>Utility:</b>
                      <br></br>
                      1. Generating STL files from CAD data<br></br>
                      2. Simulation of FDM Process Parameters<br></br>
                      3. Fabrication of Physical models from CAD data<br></br>
                      4. Post processing of Models using soluble support

                    </p>





                    <h3>Ultimaker 3D Printer</h3>
                    <img src="https://nitw.ac.in/api/static/files/Ultimaker_3D_Printer_2023-11-14-12-40-30.jpg" alt="" className="img-fluid mb-5" />

                    <p className="justified-text">

                      The filament on spool is pass through the nozzle where fusing takes place and consequently deposited over the build plate as per 3D dimensional data. The process repeats for each cross-sectional layer until produce final part.<br></br>
                      <br></br>
                      <b>Utility:</b>
                      <br></br>
                      1. Generating STL files from CAD data<br></br>
                      2. Simulation of FDM Process Parameters<br></br>
                      3. Fabrication of Physical models from CAD data<br></br>
                      4. Post processing of Models using soluble support

                    </p>





                    <h3>Flash Forge 3D Printer</h3>
                    <img src="https://nitw.ac.in/api/static/files/Flash_Forge_3D_Printer_2023-11-14-12-51-47.jpg" alt="" className="img-fluid mb-5" />

                    <p className="justified-text">

                      The filament on spool is pass through the nozzle where fusing takes place and deposited over the build plate as per 3D dimensional data. The process repeats for each cross sectional layer by XY movement of nozzle and Z movement of substrate to produce final part
                      <br></br>
                      <b>Utility:</b>
                      <br></br>
                      1. Generating STL files from CAD data<br></br>
                      2. Simulation of FDM Process Parameters<br></br>
                      3. Fabrication of Physical models from CAD data<br></br>
                      4. Manual post processing of Models
                    </p>




                    <h3>3D Ein Scanner</h3>
                    <img src="https://nitw.ac.in/api/static/files/3D_Ein_Scanner_2023-11-14-12-57-37.jpg" alt="" className="img-fluid mb-5" />

                    <p className="justified-text">

                      The Shining 3D EinScan-S 3D scanner uses a structured white light technology. A white light is casted by a projector on the object at the center of the rotating plate. Degree after degree the plate turns so the object can be 3D scanned from all sides. The process takes around 5 minutes and also captures the textures (colors) of objects.
                      <br></br>
                      <b>Utility:</b>
                      <br></br>
                      1. Extract data in the form of point cloud using Ein-Scan Software<br></br>
                      2. Generating STL files from CAD data<br></br>
                      3. Simulation of FDM Process Parameters<br></br>
                      4. Fabrication of Physical models from CAD data
                    </p>






                    <h3>Sense 3D Scanner</h3>
                    <img src="https://nitw.ac.in/api/static/files/Sense_3D_Scanner_2023-11-14-13-5-18.jpg" alt="" className="img-fluid mb-5" />

                    <p className="justified-text">

                      Sense 3D scanner itself is pretty light, hold it in your hand and move around the object to scan it. The scanner projects a patterned infrared (IR) beam onto the object from the bottom opening that is then detected by the middle webcam. On the PC (Windows only), the included software then translates the information into the shape of the object. At the top of the Sense is a normal webcam, which the same software uses to translate the images it captures into the surface colors of the object.<br></br>
                      <b>Utility:</b>
                      <br></br>
                      1. Extract data in the form of point cloud using 3D system sense Software<br></br>
                      2. Generating STL files from CAD data<br></br>
                      3. Simulation of FDM Process Parameters<br></br>
                      4. Fabrication of Physical models from CAD data
                    </p>




                    <h3>MIMICS 14.1 Software</h3>
                    <img src="https://nitw.ac.in/api/static/files/MIMICS_14.1_Software_2023-11-14-13-9-29.jpg" alt="" className="img-fluid mb-5" />

                    <p className="justified-text">

                      Mimics is software developed by Materialise, Belgium for medical image processing. Use Mimics for the segmentation of 3D medical images (coming from CT, MRI, micro CT, CBCT, Ultrasound, Confocal Microscopy) and the result will be highly accurate 3D models of patient’s anatomy. Use these patient-specific models for a variety of applications directly in Mimics, or export the 3D models and anatomical landmark points to 3rd party software, like statistical, CAD, or FEA packages.<br></br>
                      <b>Utility:</b>
                      <br></br>
                      1. Create accurate 3D models from medical imaging data (DICOM)<br></br>
                      2. Accurately measure in 2D and 3D<br></br>
                      3. Export 3D models in STL format for rapid prototyping<br></br>

                    </p>




                    <h3>Prusa Kit</h3>
                    <img src="https://nitw.ac.in/api/static/files/Prusa_Kit_2023-11-14-13-17-4.jpg" alt="" className="img-fluid mb-5" />

                    <p className="justified-text">

                      The filament (Diameter of 1.75 mm) on spool is pass through the nozzle where fusing takes place and deposited over the build plate as per 3D dimensional data. The process repeats for each cross sectional layer by XY movement of nozzle and Z movement of substrate to produce final part.
                      <br></br>
                      <b>Utility:</b>
                      <br></br>
                      1. Carried out X, Y, Z assembly<br></br>
                      2. Heat bed and nozzle assembly<br></br>
                      3. Arduino Mega2560 and Marlin integration with electronics<br></br>
                      4. Create G code using Slice3r software and control the machines using prontor face software<br></br>
                      5. Print the part as per 3D data<br></br>

                    </p>



                    {/* next day */}



                    <h3>Material Characterization Lab</h3>
                    <h3>Friction stir welding machine</h3>
                    <img src="https://nitw.ac.in/api/static/files/Friction_stir_welding_machine_2023-11-14-16-55-18.jpg" alt="" className="img-fluid mb-5" />


                    <h3>Computer Controlled Universal Testing Machine of 50kN capacity</h3>
                    <img src="https://nitw.ac.in/api/static/files/Universal_Testing_Machine_2023-11-15-11-34-52.jpg" alt="" className="img-fluid mb-5" />
                    <p className="justified-text">

                      1. AC Servo motor for Crosshead control & Cross head speed 0.05 to 500mm/ min along with the following Standard accessories.<br></br>
                      2. Basic machine along with one load cell and pair of compression plates<br></br>
                      3. Windows based users friendly software<br></br>
                      4. Vice cum wedge type grip<br></br>
                      5. Electronic Extensometer with software suitable for determination of youngs and 0.1% to 1.0% proof stress value. A graph of load Vs strain (%) can be printed on a printer.<br></br>
                      a. Range: 0 to 5 mm<br></br> b. Least count: 0.001mm<br></br> c. Gauge Length: 25mm & 50mm

                    </p>




                    <h3>Inverted microscope with image analyser software</h3>
                    <img src="https://nitw.ac.in/api/static/files/Inverted_microscope_with_image_analyser_software_2023-11-15-11-39-18.jpg" alt="" className="img-fluid mb-5" />
                    <p className="justified-text">
                      The stage of an inverted microscope is usually fixed, and focus is adjusted by moving the objective lens along a vertical axis to bring it closer to or further from the specimen. The focus mechanism typically has a dual concentric knob for coarse and fine adjustment. The microscope has four objective lenses of different magnifications fitted to a rotating turret known as a nosepiece. Inverted microscopes are used in micromanipulation applications where space above the specimen is required for manipulator mechanisms and the microtools they hold, and in metallurgical applications where polished samples can be placed on top of the stage and viewed from underneath using reflecting objectives.

                      <br></br>
                    </p>


                    <h3>Stereozoom microscope</h3>
                    <img src="https://nitw.ac.in/api/static/files/Stereozoom_microscope_2023-11-15-11-52-35.jpg" alt="" className="img-fluid mb-5" />
                    <p className="justified-text">

                      <br></br>
                    </p>


                    <h3>Upright microscope</h3>
                    <img src="https://nitw.ac.in/api/static/files/Upright_microscope_2023-11-15-12-1-11.jpg" alt="" className="img-fluid mb-5" />


                    <h3>3D Microscope</h3>
                    <img src="https://nitw.ac.in/api/static/files/3D_Microscope_2023-11-15-13-0-40.jpg" alt="" className="img-fluid mb-5" />
                    <p className="justified-text">
                      <img src="https://nitw.ac.in/api/static/files/3D_Microscope_1_2023-11-15-12-56-47.jpg" alt="" className="img-fluid mb-5" />
                      <img src="https://nitw.ac.in/api/static/files/3D_Microscope_2_2023-11-15-12-57-14.jpg" alt="" className="img-fluid mb-5" />

                      <img src="https://nitw.ac.in/api/static/files/3D_Microscope_3_2023-11-15-12-57-42.jpg" alt="" className="img-fluid mb-5" />


                      <img src="https://nitw.ac.in/api/static/files/3D_Microscope_4_2023-11-15-12-58-7.jpg" alt="" className="img-fluid mb-5" />
                      <img src="https://nitw.ac.in/api/static/files/3D_Microscope_5_2023-11-15-12-58-32.jpg" alt="" className="img-fluid mb-5" />
                      <img src="https://nitw.ac.in/api/static/files/3D_Microscope_6_2023-11-15-12-58-55.jpg" alt="" className="img-fluid mb-5" />




                      <h3>Rockwell Hardness Tester</h3>
                      <img src="https://nitw.ac.in/api/static/files/ROCKWELL_HARDNESS_TESTER_2023-11-15-13-6-38.jpg" alt="" className="img-fluid mb-5" />



                      <h3>Digital Impact Testing Machine</h3>
                      <img src="https://nitw.ac.in/api/static/files/DIGITAL_IMPACT_TESTING_MACHINE_2023-11-15-13-13-36.jpg" alt="" className="img-fluid mb-5" />
                      <img src="https://nitw.ac.in/api/static/files/DIGITAL_IMPACT_TESTING_MACHINE_1_2023-11-15-13-11-36.jpg" alt="" className="img-fluid mb-5" />
                      <img src="https://nitw.ac.in/api/static/files/DIGITAL_IMPACT_TESTING_MACHINE_2_2023-11-15-13-14-14.jpg" alt="" className="img-fluid mb-5" />
                    </p>


                    <p className="justified-text">
                      <h3>Medium Duty Table Top Abrasive Cutter</h3>
                      <h3>Model: BAINCUT-M</h3>
                      <img src="https://nitw.ac.in/api/static/files/Medium_Duty_Table_Top_Abrasive_Cutter_2023-11-15-13-25-46.jpg" alt="" className="img-fluid mb-5" />

                      Light alloy casting base with recirculation coolant system. FRP hood, viewing window, cleaning hose, safety lock included. Cleaning hose, safety lock included.

                    </p>


                    <p className="justified-text">
                      <h3>Mechatronics Lab</h3>
                      <h3>Lab Information </h3>
                      <p>
                        <a href="https://nitw.ac.in/api/static/files/Mechatronics_Laboratory_2023-11-16-12-30-36.pdf">[Please Click the pdf File to See the Details]</a></p>

                      <img src="https://nitw.ac.in/api/static/files/Medium_Duty_Table_Top_Abrasive_Cutter_2023-11-15-13-25-46.jpg" alt="" className="img-fluid mb-5" />

                      With the emergence of integrated circuits and computers, the frontiers of various traditional disciplines started vanishing. Today, most of the products in the market are neither purely mechanical nor electronic in nature, but are clever integration of components or systems from different engineering disciplines (multidisciplinary). Therefore, there is a pressing need to train engineers/technicians with multidisciplinary skills.
                    </p>
                    <br></br>


                    <p className="justified-text">
                      <h3>Precision Metrology Lab</h3>
                      <b>Utility: </b>


                      The Precision Metrology laboratory at NIT Warangal is to provide the students with an active learning environment for the measurement technologies used in production engineering field.  Students use the laboratory to collect and analyze measurement data, evaluate measurement methodologies, and learn the capabilities and limitations of measurement technologies.
                      <br></br>

                      <b>Lab Coverage: </b>
                      Linear Measurements, Angular Measurements, Level Measurements, Surface Roughness, Form Measurements, Gear Measurements, Thread Measurements

                      <b>Equipment Details: </b>


                      <b>Precision Metrology Laboratory </b>
                      <b>Coordinate Measuring Machine:</b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/Coordinate_Measuring_Machine_2023-11-15-15-22-5.jpg" alt="" className="img-fluid mb-5" />


                      Co-ordinate Measuring Machines (CMM) are mechanical systems consisting of a contact probe and a means of positioning the probe in three dimensional space relative to the surface of a work part in order to obtain the data concerning the part geometry.
                      <br></br>
                      <b>Portable CMM (ABSOLUTE ARM): </b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/Portable_CMM_(ABSOLUTE_ARM)_2023-11-15-15-27-3.jpg" alt="" className="img-fluid mb-5" />
                      A specialized touch measurement tool that also boasts the option for entry-level 3D scanning – the Absolute Arm 6-Axis is one of a kind. Based on technology with Absolute Encoders located at every articulation point and designed with ease-of-movement and usage as a focus, this is a portable measuring arm like no other.<br></br>
                      <br></br>

                      <b>Profile Projector: </b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/Profile_Projector_2023-11-15-15-31-40.jpg" alt="" className="img-fluid mb-5" />
                      Profile projector is widely used for complex shape stampings, gears, cams, threads and comparing the measured contour model. It's easy to use and high efficient. It is a commonly used measurement of optical instruments.
                      <br></br>


                      <b>Ball Milling machine: </b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/Ball_Milling_machine_2023-11-15-15-37-11.jpg" alt="" className="img-fluid mb-5" />
                      A ball mill, a type of grinder, is a cylindrical device used in grinding (or mixing) materials like ores, chemicals, ceramic raw materials and paints. Ball mills rotate around a horizontal axis, partially filled with the material to be ground plus the grinding medium. Different materials are used as media, including ceramic balls, flint pebbles, and stainless steel balls. An internal cascading effect reduces the material to a fine powder.



                      <br></br>


                      <b>Surface Roughness tester: </b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/Surface_Roughness_tester_2023-11-15-17-19-57.jpg" />
                      Marsurf surface roughness tester is used in measuring surface roughness of work pieces, machined surfaces through a stylus method.

                      <br></br>

                      <b>Tool Makers Microscope: </b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/Tool_Makers_Microscope_v_2023-11-15-17-22-2.jpg" />

                    </p>
                    <br></br>



                    <h3>Production Engineering Lab</h3>


                    <h3>PRODUCTION LABORATORY</h3>
                    <img src="https://nitw.ac.in/api/static/files/Stereozoom_microscope_2023-11-15-11-52-35.jpg" alt="" className="img-fluid mb-5" />





                    <p className="justified-text">
                      <b>Lab Coverage:</b>Single point cutting tool preparation with various systems, chip reduction coefficient, helical gear cutting, cutting force measurement, Temperature measurement

                      <br></br>


                      <b>Equipment Details:</b><br></br>

                      <b>ALL GEARED LATHE MACHINE</b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/CNC_LATHE_MACHINE_2023-11-15-17-42-28.jpg" alt="" className="img-fluid mb-5" />
                      <br></br>

                      <b>PIN-ON DISC MACHINE</b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/PIN-ON_DISC_MACHINE_2023-11-15-17-47-21.jpg" alt="" className="img-fluid mb-5" />
                      <br></br>

                      <b>BENCH DRILLING MACHINE</b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/BENCH_DRILLING_MACHINE_2023-11-15-17-53-3.jpg" alt="" className="img-fluid mb-5" />

                      <br></br>
                      <b>HYDRAULIC FEED UNIT FOR DEPTH CONTROL</b><br></br>
                      <b>HYDRAULIC POWER HACKSAW</b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/HYDRAULIC_POWER_HACKSAW_2023-11-15-17-57-52.jpg" alt="" className="img-fluid mb-5" />
                      <br></br>

                      <b>CNC Retrofitting VDF lathe</b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/CNC_Retrofitting_VDF_lathe_2023-11-15-18-1-12.jpg" alt="" className="img-fluid mb-5" />
                      <br></br>
                      <b>CNC MILLING MACHINE (M TAB)</b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/CNC_MILLING_MACHINE_(M_TAB)_2023-11-15-18-9-48.jpg" alt="" className="img-fluid mb-5" />
                      <br></br>
                      <b>ASRS System:</b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/ASRS_System_2023-11-16-11-3-18.jpg" alt="" className="img-fluid mb-5" />

                      <br></br>
                      <b>SIX COMPONENT DYNAMOMETER</b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/SIX_COMPONENT_DYNAMOMETER_2023-11-16-11-7-39.jpg" alt="" className="img-fluid mb-5" />


                      <br></br>
                      <b>Planetary Micro Mill premium line Pulverisette 7</b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/Planetary_Micro_Mill_premium_line_Pulverisette_7_2023-11-16-11-11-59.jpg" alt="" className="img-fluid mb-5" />



                      <br></br>

                      <b>CNC Control Training System</b><br></br>

                      <img src="https://nitw.ac.in/api/static/files/CNC_Control_Training_System_2023-11-16-11-25-48.jpg" alt="" className="img-fluid mb-5" />
                      <img src="https://nitw.ac.in/api/static/files/CNC_Control_Training_System_2023-11-16-11-25-6.jpg" alt="" className="img-fluid mb-5" />

                      <br></br>

                      <b>CNC Lathe Trainer</b><br></br>

                      <img src="https://nitw.ac.in/api/static/files/CNC_Lathe_Trainer_2023-11-16-11-26-34.jpg" alt="" className="img-fluid mb-5" />
                      <img src="https://nitw.ac.in/api/static/files/CNC_Lathe_Trainer_tble_2023-11-16-11-45-9.png" alt="" className="img-fluid mb-5" />

                      <br></br>

                      <b>CNC Mill Trainer</b><br></br>

                      <img src="https://nitw.ac.in/api/static/files/CNC_Mill_Trainer_2023-11-16-11-46-15.jpg" alt="" className="img-fluid mb-5" />
                      <img src="https://nitw.ac.in/api/static/files/CNC_Mill_Trainer_table_2023-11-16-12-19-9.png" alt="" className="img-fluid mb-5" />


                      <br></br>

                      <b>Laser Assisted Machining System</b><br></br>

                      <img src="https://nitw.ac.in/api/static/files/Laser_Assisted_Machining_System_2023-11-16-12-20-12.jpg" alt="" className="img-fluid mb-5" />
                      <img src="https://nitw.ac.in/api/static/files/CNC_Lathe_2023-11-16-12-21-36.png" alt="" className="img-fluid mb-5" />

                      <br></br>

                      <b>High temperature muffle furnace</b><br></br>

                      <img src="https://nitw.ac.in/api/static/files/High_temperature_muffle_furnace_2023-11-16-12-26-26.jpg" alt="" className="img-fluid mb-5" />

                      <br></br>
                    </p>



                    <p className="justified-text">
                      <h3>Thermal Engg. Labs</h3>
                      <h3>Energy Systems Lab </h3>
                      <a href="https://drive.google.com/open?id=0B5VjI8YmohsMZXdvamFqR1N1YlE&authuser=0">[Please Click the pdf File to See the Details]</a>



                      <h3>Heat Transfer and Fuels Laboratory</h3>
                      <a href="https://nitw.ac.in/api/static/files/Heat_Transfer_and_Fuels_Laboratory_2023-11-16-12-33-42.pdf">For More Information about the lab Click Here</a>
                      <br></br>
                      <b>Utility:</b>
                      <br></br>
                      Heat Transfer Laboratory caters to the needs of B.Tech. 3rd year Mechanical Engineering and M.Tech. Thermal Engineering. There are 8 experimental setups available in the laboratory. They are-
                      <br></br>

                      1. Natural convection apparatus<br></br>

                      2. Pin Fin apparatus<br></br>

                      3. Emissivity Apparatus
                      <br></br>
                      4. Composite Slab Apparatus
                      <br></br>
                      5. Forced convection apparatus
                      <br></br>
                      6. Heat Pipe Demonstration
                      <br></br>
                      7. Thermal Conductivity apparatus
                      <br></br>
                      8. Stephan-Boltzmann Apparatus
                      <br></br>
                      These experiments help the students to understand all the 3 modes of heat transfer, namely conduction, convection and radiation practically. These experiments also pave way for inculcating certain innovative skills in the students making them to use the above experimental setups as starting point to take up higher level experimental studies in Heat Transfer.



                      <br></br>
                      <b>I.C. Engine Lab</b><br></br>

                      <a href="https://nitw.ac.in/api/static/files/Theraml_Engineering_Laboratory_2023-11-16-12-41-12.pdf">For More Information about the lab Click Here</a>
                      <br></br>

                      <b>
                        I. Computerized single cylinder 4-stroke diesel engine.</b>
                      <p>
                        <img src="https://nitw.ac.in/api/static/files/Multi_fuel_variable_compression_ratio_engine_2023-11-16-12-52-7.jpg" alt="" className="img-fluid mb-5" />
                        <img src="https://nitw.ac.in/api/static/files/Computerized_single_cylinder_4-stroke_diesel_engine_2023-11-16-12-46-2.jpg" alt="" className="img-fluid mb-5" /> </p>
                      This setup consists of four stroke Diesel Engine coupled to a suitable eddy current type dynamometer. The engine is single cylinder, water cooled diesel engine running at constant speed. The dynamometer provides the variable load on the engine to study engine performance under variable load conditions. Exhaust gas calorimeter is used for estimation of heat carried away by exhaust gases for determination of heat balance of the engine. Air is supplied to the engine through an air box, fitted with orifice plate. The instrumentation console includes measuring instruments for Engine Speed, Torque, Air consumption, Fuel Consumption, temperature & cooling water flow rates. The parameters are recorded by the sensors & transmitted to Data Acquisition Device. The Data Acquisition Device sends the data to computer & which is displayed in the Data Acquisition Software. Windows-based software developed in LabView Platform is capable of displaying real-time acquired data in graphical & numerical format.

                      <br></br>



                      <b>II. Axial flow compressor Test rig</b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/Axial_flow_compressor_Test_rig_2023-11-16-12-49-4.jpg" alt="" className="img-fluid mb-5" />

                      An axial compressor is a gas compressor that can continuously pressurize gases. It is a rotating, airfoil based compressor in which the gas or working fluid principally flows parallel to the axis of rotation. This differs from other rotating compressors such as centrifugal compressor, axi-centrifugal compressors and mixed-flow compressors where the fluid flow will include a "radial component" through the compressor. The energy level of the fluid increases as it flows through the compressor due to the action of the rotor blades which exert a torque on the fluid. The stationary blades slow the fluid, converting the circumferential component of flow into pressure. Compressors are typically driven by an electric motor or a steam or a gas turbine. Axial flow compressors produce a continuous flow of compressed gas, and have the benefits of high efficiency and large mass flow rate, particularly in relation to their size and cross-section. They do, however, require several rows of airfoils to achieve a large pressure rise, making them complex and expensive relative to other designs (e.g. centrifugal compressors).
                      <br></br>
                      The following tests can be performed with the setup:
                      <br></br>
                      1. High hub-tip ratio rotor blades
                      <br></br>
                      2. Effect of varying tip clearance on performance at design and off-design conditions.
                      <br></br>
                      3. Effect of varying tip geometries (and clearance) on performance and design and off-design conditions.
                      <br></br>
                      4. Tip flow measurements using hot wire probes.
                      <br></br>




                      <b>III. Multi fuel variable compression ratio engine</b><br></br>


                      <img src="https://nitw.ac.in/api/static/files/Multi_fuel_variable_compression_ratio_engine_2023-11-16-12-55-5.jpg" alt="" className="img-fluid mb-5" />

                      The Multifuel VCR Engine Setup consists a single cylinder, four stroke, VCR (Variable Compression Ratio) Diesel engine connected to eddy current type dynamometer for loading. The compression ratio can be changed without stopping the engine and without altering the combustion chamber geometry by specially designed tilting cylinder block arrangement. Setup is provided with necessary instruments for combustion pressure and crank-angle measurements. These signals are interfaced to computer through engine indicator for Pθ-PV diagrams. Provision is also made for interfacing airflow, fuel flow, temperatures and load measurement. The setup has stand-alone panel box consisting of air box, two fuel tanks for blend test, manometer, fuel measuring unit, transmitters for air and fuel flow measurements, process indicator and engine indicator. Rotameters are provided for cooling water and calorimeter water flow measurement. The setup enables study of VCR engine performance for brake power, indicated power, frictional power, BMEP, IMEP, brake thermal efficiency, indicated thermal efficiency, Mechanical efficiency, volumetric efficiency, specific fuel consumption, A/F ratio and heat balance. LabVIEW based Engine Performance Analysis software package “ICEngineSoft” is provided for on line performance evaluation.<br></br>
                      The following tests can be performed with the setup:
                      <br></br>

                      <b>Experimental Capabilities:</b><br></br>
                      1. Determination of brake power & mechanical efficiency at different load conditions.
                      <br></br>
                      2. To study the effect of a change in compression ratio on Engine performance.
                      <br></br>
                      3. Determination of fuel consumption & brake specific fuel consumption, brake thermal efficiency.
                      <br></br>
                      4. Determination of air consumption & volumetric efficiency, Air/Fuel Ratios.
                      <br></br>

                      5. Calculation of Heat balance of the engine.
                      <br></br>


                      6. To study P-V Diagram & P-θ diagram for Petrol / Diesel engine.
                      <br></br>

                      7. To study Fuel Injection timing diagram for Diesel Engine 8. Study of Open ECU & Performance optimization with ECU programming.
                      <br></br>




                      <b> IV. Four-cylinder automotive CRDi variable geometry turbocharger engine</b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/Four-cylinder_automotive_CRDi_variable_geometry_turbocharger_engine_2023-11-16-12-54-18.jpg" alt="" className="img-fluid mb-5" />

                      The engine is single cylinder water cooled four stroke automotive diesel engine. Diesel fuelis injected directly into the combustion chamber with common-rail fuel injection equipment.The fuel parameters like fuel quantity, injection timing, number of injections, fuel pressure inthe rail, etc. can be changed online with the help of an Open ECU.Open ECU based variation in above parameters simplifies the research by brining so manyparameters in the preview of research. Above mentioned parameters can be optimizedby click of mouse at various rpm points to study their effect on Engine efficiency, Ignition delay, Emission etc.
                      <br></br>

                      <b>Experimental Capabilities:</b><br></br>


                      1. Study the effect of various alternative fuels (biodiesel, green diesel etc.) on engine performance, combustion and emission characteristics.<br></br>

                      2. Study the effect of variable geometry turbo (VGT) on engine performance.   <br></br>

                      3. Study of bio fuel/ dual fuel on engine performance and emission.<br></br>

                      4.  Study of methanol, ethanol, DME etc.
                      <br></br>
                      5. Study of variable geometryturbine.

                      <br></br>





                      <b> V. Four-wheeler chassis Dynamometer with Brake Retarders</b><br></br>
                      <img src="https://nitw.ac.in/api/static/files/Four-wheeler_chassis_Dynamometer_with_Brake_Retarders_2023-11-16-12-57-37.jpg" alt="" className="img-fluid mb-5" />

                      <br></br>
                      It is a mechanical device for measuring power and torque of a car, bike, truck or even an agricultural machine. Sometimes it is called as Rolling road. The torque produced by an engine is converted to traction force by transmission (gearbox, differential) at wheels. Chassis dynamometers can be used not only for simple torque and power measurement. Very common usage is fuel consumption and emissions measurement or to determine real fuel consumption under load.
                      <br></br>
                      A chassis dynamometer is a device that can simulate the resistance imposed to the wheel of a vehicle according to different driving cycles. During the testing, the vehicle is tied and running on loading controllable rollers keeping a stationary state. According to the predefined time-velocity curve shown on a monitor, the driver operates the vehicle to match the curve in different driving cycles. Then the exhaust gas will be output to an online exhaust gas analyser for emission measurement.
                      <br></br>
                      DAC, or data acquisition system is an integral part of a chassis dynamometer. It commands the load (if the brake is installed), measures rotational speeds, acceleration of rollers, resistance force generated at the electromagnetic brake. Advanced dynos offer also a possibility of measuring other parameters that are important for a car tuner or a workshop – AFR (air to fuel ratio of mixture), EGT (exhaust gas temperature), MAP (manifold absolute pressure boost)

                      <br></br>

                    </p>




                    <p className="justified-text">
                      <h3>Mechanical Measurements Laboratory</h3>

                      <b>Objective: </b>
                      <br></br>
                      To facilitate B.Tech students in understanding the working principle of mechanical measuring devices and sensors and to train them to operate and calibrate them.
                      <br></br>
                      <b>Lab Coverage:</b>
                      <br></br>
                      Displacement Measurement, Humidity Measurement, Level Measurement, Flow Measurement, Load Measurement, Velocity Measurement, Temperature Measurement and Torque Measurement.
                      <br></br>

                      <b>I. Pressure Measurement using Strain Gauge Pressure Transducer</b>

                      <img src="https://nitw.ac.in/api/static/files/Pressure_Measurement_using_Strain_Gauge_Pressure_Transducer_2023-11-16-13-1-28.jpg" alt="" className="img-fluid mb-5" />

                      Strain gauge pressure transducer is basically an electro mechanical device, especially manufactured and designed for wide range of applications in pressure measurement. A common method for converting diaphragm displacement into a measurable signal is to sense the strain induced on the diaphragm surface as it is displaced. Strain gauges, devices whose measurable resistance is proportional to their sensed strain, can be bonded directly onto the diaphragm, integrated within the diaphragm material or onto a deforming element (such as a thin beam) attached to the diaphragm so as to deform with the diaphragm and to sense strain. Metal strain gauges can be used with liquids. Strain gauge resistance is reasonably linear over a wide range of strain and can be directly related to the sensed pressure.
                      <br></br>
                      By using semiconductor technology in pressure transducer construction, we now have a variety of very fast, very small, highly sensitive strain gauge diaphragm transducers. Silicone piezo resistive strain gauges can be diffused into a single crystal of silicon wafer, which forms the diaphragm. Semiconductor strain gauges have a static sensitivity that is 50 times greater than conventional metallic strain gauges. Because the piezoresistive gauges are integral to the diaphragm, they are relatively immune to the thermoelastic strains prevalent in conventional metallic strain gauge–diaphragm constructions. Furthermore, a silicone diaphragm does not creep with age (as does a metallic gauge), thus minimizing calibration drift over time. However, uncoated silicone does not tolerate liquids.

                      <br></br>

                      <b>Experimental Capabilities:</b>
                      <br></br>
                      1. To measure pressure using strain gauge pressure transducer.
                      <br></br>
                      2. To determine error between actual applied pressure and pressure measured through strain gauge.
                      <br></br>
                      3. To plot regression and hysteresis curve for the measuring device.
                      <br></br>

                      <b>II. Flow Velocity Measurement using Pitot tube and Hot Wire Anemometer</b>
                      <br></br>
                      <img src="https://nitw.ac.in/api/static/files/Flow_Velocity_Measurement_using_Pitot_tube_and_Hot_Wire_Anemometer_2023-11-16-13-5-14.jpg" alt="" className="img-fluid mb-5" />

                      Flow measurement gives the idea in understanding the flow nature in liquid and gases. Instruments like the pitot tube and hot wire anemometers measures the local velocity at a point in the channel or duct through which fluid is flowing.
                      <br></br>
                      <b>Pitot tube: </b>It is an instrument used to measure fluid flow velocity based upon Bernoulli’s principle.  Flow velocity constitutes important parameters like kinematics and dynamics of fluid flow. This fluid velocity meter measures the local velocity at a point in the flow duct, and is used mainly for the measurement of air or gas velocities. The instrument requires frequent calibration to refine the value of Cv. Its value lies between 0.95 and 1.0.
                      <br></br>
                      <b>Hot wire anemometer: </b>Hot wire anemometer is an instrument used to measure the mean and fluctuating flow components in a flow field. The sensor is a 5 microns diameter platinum-tungsten wire welded between the two prongs of the probe and heated electrically as a part of a wheat-stone bridge circuit. When the probe is introduced into the flow field, it tends to be cooled by the instantaneous velocity and consequently there is a tendency for the electrical resistance to diminish.
                      <br></br>

                      <b>Experimental Capabilities:</b>

                      <br></br>

                      1. To determine the velocity of a flow using Pitot Tube and Hot Wire Anemometer.
                      <br></br>
                      2. To determine error between actual velocity  and the velocity measured through Hot Wire Anemometer.
                      <br></br>
                      3. To measure volume flow rate
                      <br></br>
                      4. To plot regression curve of velocity measuring device.
                      <br></br>

                      <b>III. Torque Measurement using Strain Gauges</b><p>
                        <img src="https://nitw.ac.in/api/static/files/Torque_Measurement_using_Strain_Gauges_2023-11-16-13-6-42.jpg" alt="" className="img-fluid mb-5" />
                      </p>
                      <br></br>
                      GThe measurement of torque is important in a variety of applications, including sizing of load-carrying shafts. This measurement is also a crucial aspect of the measurement of shaft power, such as in an engine dynamometer. Strain-gauge–based torque cells are constructed in a manner similar to load cells, in which a torsional strain in an elastic element is sensed by strain gauges appropriately placed on the elastic element. Figure 1show a circular shaft instrumented with strain gauges for the purpose of measuring torque, and a commercially available torque sensor. Consider the stresses created in a shaft of radius (R0) subject to a torque (T).
                      <br></br>
                      <b>Experimental Capabilities:</b>

                      <br></br>
                      1. To measure the torque using strain gauges.<br></br>
                      2. To determine error between actual applied torque and torque measured through strain gauge.<br></br>
                      3. To plot regression and hysteresis curve for the measuring device.<br></br>
                      <br></br>


                      <b>IV. Flow Measurement by Venturi and Orifice Meter</b>

                      <br></br>
                      Venturi meter and orifice meter are the commonly used flow meters for measuring mass/volumetric flow rate or velocity of the flowing fluid. These flow meters are also known as variable head meters. They are categorized as full-bore meter as the measurement of the fluid takes place when it flows through a conduit or channel.
                      <br></br>
                      <b>Venturi meter :</b> The venturi meter has a converging conical inlet, a cylindrical throat and a diverging recovery cone. It has no projections into the fluid, no sharp corners and no sudden changes in contour.

                      <img src="https://nitw.ac.in/api/static/files/Flow_Measurement_by_Venturi_and_Orifice_Meter_2023-11-16-13-10-6.jpg" alt="" className="img-fluid mb-5" />
                      <b>Orifice meter:</b> The orifice meter consists of an accurately machined and drilled plate concentrically mounted between two flanges. The orifice meter has several practical advantages when compared to venturi meters; Lower cost, smaller physical size and flexibility to change throat to pipe diameter ratio to measure a larger range of flow rates.
                      <br></br>
                      <b>Experimental Capabilities:</b><br></br>
                      1. To compare and analyse the flow measurements by Venturi and Orifice meters.<br></br>
                      2. To determine error between actual flow and the flow measured through Venturi and Orifice meters.<br></br>
                      3. To plot regression curve of flow measuring devices.<br></br>

                    </p>

                  </div>
                </div>




                <div className={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                  aria-labelledby="v-pills-Awards-tab">
                </div>

                <div className={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                  aria-labelledby="v-pills-ResearchScholars-tab">
                  <h4 className="">Ph.D. Awardee</h4> <a href="CSEDeptAssets/docs/list-of-phd-awardees.pdf"
                    target="_blank"> <span className="highlight">L</span>ist of Ph.D. Awardees <i
                      className="fa fa-link " aria-hidden="true"></i> </a>
                  <h4 className="">Ph.D. On Roll</h4> <a
                    href="CSEDeptAssets/docs/Ph.D._Students_List_2022-7-29-11-55-47.pdf" target="_blank"> <span
                      className="highlight">P</span>h.D. Students List ( Full / Part Time) <i
                        className="fa fa-link " aria-hidden="true"></i> </a>
                </div>


                <div className={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                  aria-labelledby="v-pills-PhotoGallery-tab">

                  <div className="details">

                    {/* <h4>AICTE Training and Learning Academy (ATAL) FDP on Cloud and Fog Computing Platforms for IoT Applications</h4>
                                <span>19th to 30th September 2022</span> */}
                  </div>


                  {/* <img src="CSEDeptAssets/images/atal1.jpeg" alt="" className="img-fluid mb-5"/> 

                                <img src="CSEDeptAssets/images/atal2.jpeg" alt="" className="img-fluid mb-5"/> 
                                <img src="CSEDeptAssets/images/atal3.jpeg" alt="" className="img-fluid mb-5"/> */}





                </div>


              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
      {/* <div className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="copyright">
                                 <p className="justified-text">Copyright © Centre for Digital Infrastructure and Services </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </>
  );
}

export default MechDeptPage;