import '../css/cseDeptStyle.css';
const IksPage = () => {
    return (
        <><div className="dept-wrapper">
            <div className="container">
                <section className="py-4 header">
                    <header>
                        <img
                            src="https://nitw.ac.in/api/static/files/ciks_2025-4-2-17-6-30.jpg" className="img-fluid" />
                    </header>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="intro-section">


                                <p className="justified-text"><b>Indian Knowledge Systems (IKS)</b> is an innovative cell under Ministry of Education (MoE) at AICTE, New Delhi. It is established to promote interdisciplinary research on all aspects of IKS, preserve and disseminate IKS for further research and societal applications. It will actively engage for spreading the rich heritage of our country and traditional knowledge in the field of Arts, literature, Agriculture, Basic Sciences, Engineering & Technology, Architecture, Management, Economics, etc.</p>
                                <h3>Centre for Indian Knowledge Systems (IKS) @ NIT Warangal</h3>
                                <p className="justified-text">The Centre for Indian Knowledge Systems (IKS) at NIT Warangal focuses on integrating yoga practices within the broader framework of Indian Knowledge Systems. Its primary goal is to promote innovations and societal applications of traditional Indian practices, particularly in the areas of health and wellness. The centre explores yoga not only as a physical practice but as a holistic system encompassing physical postures (asanas), breathing techniques (pranayama), and meditation, all aimed at achieving balance between the body, mind, and spirit.</p>
                                <h3>Relevance and Significance</h3>
                                <ol>
                                    <li> <p className="justified-text"><b>1.  Holistic Approach:</b> Yoga is an essential part of IKS, offering a unified approach to well-being that combines physical, mental, and spiritual practices. The Sanskrit term "yuj," meaning "to unite," reflects yoga’s goal of harmonizing body, mind, and spirit.</p></li>
                                    <li> <p className="justified-text"><b>2.  Health and Wellness:</b> Yoga contributes significantly to physical strength, flexibility, and mental well-being, with the practices serving as valuable tools for holistic development.</p></li>
                                    <li> <p className="justified-text"><b>3.  Spiritual Significance:</b> In addition to physical and mental health benefits, yoga also incorporates spiritual practices like meditation, contemplation, and self-awareness, aligning with the deeper elements of IKS</p></li>
                                    <li> <p className="justified-text"><b>4.  Global Relevance:</b> The significance of yoga extends beyond Indian culture and has global relevance, promoting wellness and offering a framework for a balanced life.</p></li>
                                </ol>
                                <p className="justified-text">Overall, the centre's research emphasizes the multifaceted benefits of yoga, blending ancient traditions with modern wellness practices.</p>
                                <h3>Glimpses of Yoga Sessions at NIT Warangal</h3>
                                <h4 className='text-center'>INTERNATIONAL DAY OF YOGA-2023</h4>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks1_2025-4-2-17-32-5.jpg' alt='' />
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks2_2025-4-2-17-32-33.jpg' alt='' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks3_2025-4-2-17-38-22.jpg' alt='' />
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks4_2025-4-2-17-39-47.jpg' alt='' />
                                    </div>
                                </div>
                                <br/>

                                <h4 className='text-center'>INTERNATIONAL DAY OF YOGA 2024</h4>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/ik5_2025-4-2-17-40-4.jpg' alt='' />
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks6_2025-4-2-17-40-24.jpg' alt='' />
                                    </div>
                                </div>
                                <br/>

                                <h4 className='text-center'>SCIENTIFIC YOGA SESSION- 2025</h4>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks7_2025-4-2-18-6-29.jpg' alt='' />
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks8_2025-4-2-18-6-56.jpg' alt='' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks9_2025-4-2-18-7-29.jpg' alt='' />
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks10_2025-4-2-18-7-57.jpg' alt='' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks11_2025-4-2-18-8-24.jpg' alt='' />
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks12_2025-4-2-18-8-50.jpg' alt='' />
                                    </div>
                                </div>
                                <br/>
                                <h4 className='text-center'>EXTRA ACADEMIC ACTIVITY CLASSES- B.TECH FIRST YEAR</h4>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks13_2025-4-2-18-11-6.jpg' alt='' />
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks14_2025-4-2-18-11-30.jpg' alt='' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks15_2025-4-2-18-11-51.jpg' alt='' />
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks16_2025-4-2-18-12-13.jpg' alt='' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks17_2025-4-2-18-12-40.jpg' alt='' />
                                    </div>
                                   
                                </div>
                                <br/>
                                <h4 className='text-center'>ALL INDIA INTER NIT YOGA COMPETITION-2025@NIT Warangal</h4>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks18_2025-4-2-18-13-36.jpg' alt='' />
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks19_2025-4-2-18-14-15.jpg' alt='' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks20_2025-4-2-18-14-44.jpg' alt='' />
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks21_2025-4-2-18-15-17.jpg' alt='' />
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks22_2025-4-2-18-15-41.jpg' alt='' />
                                    </div>
                                    <div className='col-md-6 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks23_2025-4-2-18-17-27.jpg' alt='' />
                                    </div>
                                </div>
                                <br/>
                                <h4 className='text-center'> NEP IMPLEMENTATION IN HEI’s AND WAY FORWARD</h4>
                                <div className='row'>
                                    <div className='col-md-12 col-sm-12'>
                                        <img src='https://nitw.ac.in/api/static/files/iks24_2025-4-2-18-17-59.jpg' alt='' />
                                    </div>
                                    {/* <div className='col-md-6 col-sm-12'>
                                        <img src='' alt='' />
                                    </div> */}
                                </div>






                            </div>


                        </div>
                    </div>
                </section>
            </div>
        </div>

        </>
    );
}
export default IksPage;