import React, { useState } from "react";
import PageHeader from "../components/PageHeader";
import "../css/deansw.css"; // CSS File
import { Padding, StarOutlineSharp } from "@mui/icons-material";
import StartupData from "../constants/StartupsData.json"
import { colors } from "@mui/material";


// **Reusable Components**
const Incubator = ({ incubator }) => (
    <div className="col-md-3 p-4">
        <div className="title_arrow mb-3">
            <p className="title">Lead Incubator</p>
        </div>
        <img src={incubator.img} alt={incubator.name} className="img-responsive img-circle"  width={242} height={242}/>
        <p style={{ textAlign: "justify" }}>
            <a href={incubator.profile}>
                <span className="text-danger fw-bold">{incubator.name}</span>
            </a>
        </p>
    </div>
);

const CompanyDetails = ({ startup }) => (
    <div className="col-md-3 p-4" style={{ borderLeft: "1px solid gray" }}>
        <div className="title_arrow mb-3">
            <p className="title">Start-up ID/ Name</p>
        </div>
        <header className="mt-3">
            <h4 style={{ color: '#b80924' }}>{startup.id}</h4>
            <div className="d-flex flex-column">
                <span>
                    <strong>Company Name: </strong>
                    {startup.company.name}
                </span>
                {startup.company.registrationNo && (
                    <span>
                        <strong>Registration No: </strong>
                        {startup.company.registrationNo}
                    </span>
                )}

                {startup.company.certificateNo && (
                    <span>
                        <strong>Certificate No: </strong>
                        {startup.company.certificateNo}
                    </span>
                )}
            </div>
        </header>
    </div>
);

const TeamMembers = ({ team }) => (
    <div className="col-md-5 details p-4" style={{ borderLeft: "1px solid gray" }}>
        <div className="title_arrow mb-3">
            <p className="title">Other Team Members</p>
        </div>
        <div className="row">
            {team.length === 0 ? (
                <p></p>
            ) : (
                team.map((member, index) => (
                    <div className="col-md-4" key={index}>
                        <img src={member.img} alt={member.name} className="img-fluid"  width={124} height={124}/>
                        <a href={member.profile}>
                            <h5 className="text-danger" style={{ fontSize: "1rem" }}>
                                {member.name}
                            </h5>
                        </a>
                    </div>
                ))
            )}
        </div>
    </div>
);

const Startup = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <header>
                            <h2 className="text-primary text-center">Startup</h2>
                        </header>
                    </div>
                </div>

                <div className="row">
                    {StartupData.map((startup) => (
                        <div className="col-md-12 mb-5" key={startup.id}>
                            <div className="seminar-card p-4" style={{ backgroundColor: "#e6e8e9" }}>
                                <div className="row">
                                    <Incubator incubator={startup.incubator} />
                                    <CompanyDetails startup={startup} />
                                    <TeamMembers team={startup.team} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default Startup;
