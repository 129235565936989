// RSC2025.js
import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';

import '../css/convocation2023.css'; // Import your custom CSS for styling

const ResearchScholarConfluencePage2025 = () => {
  const [activeTab, setActiveTab] = useState('AboutTECHNOZION');
  const [hoveredIndex, setHoveredIndex] = useState(null);

  // Define your data arrays here
  const headData = [
    // Your data objects for the  AboutTECHNOZION category
    // {
    //   name: "Prof. Rashmi Ranjan Rout",
    //   designation: "Head, CDIS",
    //   phoneNumber: "8332969418",
    //   emailId: "cdis_head@nitw.ac.in",
    //   image: "https://wsdc.nitw.ac.in/facultynew/media/16336/16336.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/cs-rashrr",
    // },

  ];

  const activitiesData = [

    // {
    //   name: "Prof. Ch. Sudhakar",
    //   designation: "Coordinator, Institute ERP",
    //   phoneNumber: "9440647945",
    //   emailId: "chapram@nitw.ac.in",
    //   image: "https://nitw.ac.in/api/static/files/Prof._Ch._Sudhakar_2023-7-18-15-59-43.jpg", 
    //   link: "https://erp.nitw.ac.in/ext/profile/cs-chapram",
    // },

  ];

  const staffCDISData = [
    // {
    //   name: "	Sri. P. Bhargava Reddy",
    //   designation: "Scientific/Technical Officer",
    //   phoneNumber: "8332969680",
    //   emailId: "tech_officer@nitw.ac.in ",
    //   image: "-", 
    //   link: "https://nitw.ac.in/",
    // },


  ];


  // Define your staffTrainingData, replace this with your actual data
  const staffTrainingData = [
    // {
    //   image: 'your_image_url.jpg',
    //   description: 'Training description goes here.',
    // },

  ];


  const staffComputerCentreData = [
    // Your data objects for the Staff (Computer Centre) category
    // {
    //   name: "Smt. M. Karuna Rani",
    //   designation: "Technical Assistant (SG-II)",
    //   phoneNumber: "8332969737",
    //   emailId: "karuna@nitw.ac.in",
    //   image: "-", 
    //   link: "https://nitw.ac.in/",
    // },






  ];

  const staffTelecomCentreData = [

    // {
    //   name: "Rajarapu Prakash",
    //   designation: "Office Attendant",
    //   phoneNumber: "8332969748",
    //   emailId: "Pr@nitw.ac.in",
    //   image: "https://nitw.ac.in/api/static/files/Rajarapu_Prakash_2023-10-27-15-33-57.jpg", 
    //   link: "https://nitw.ac.in/",



  ];

  const staffDataCentreData = [

    // {
    //   name: "K Vamshi",
    //   designation: "FMS for LAN (L3)",
    //   phoneNumber: "08702462926",
    //   emailId: "amc_lan@nitw.ac.in",
    //   image: "https://nitw.ac.in/api/static/files/K_Vamshi_2023-10-27-15-45-2.jpg", 
    //   link: "https://nitw.ac.in/",

    // },



  ];

  // Function to handle tab clicks
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };

  // Function to handle hover
  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  const handleHoverEnd = () => {
    setHoveredIndex(null);
  };
  // Rest of your component code...

  const activeData =
    activeTab === 'AboutTECHNOZION'
      ? headData
      : activeTab === 'ACTIVITIES'
        ? activitiesData
        : activeTab === 'SpotlightEvents'
          ? staffCDISData
          : activeTab === 'Competitions'
            ? staffComputerCentreData
            : activeTab === 'Workshops&Presentations'
              ? staffTelecomCentreData
              : activeTab === 'contact'
                ? staffTrainingData
                : staffDataCentreData;

  const facilitiesPerColumn = Math.ceil(activeData.length / 2);

  return (
    <div className="page text-center font-change-applicable static-facilities">
      <PageHeader dept="/RSC2025" />

      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <br></br>
        <div className="tabs">


          <button
            className={`tab ${activeTab === 'AboutTECHNOZION' ? 'active' : ''}`}
            onClick={() => handleTabClick('AboutTECHNOZION')}
          >
            About
          </button>

          <button
            className={`tab ${activeTab === 'SpotlightEvents' ? 'active' : ''}`}
            onClick={() => handleTabClick('SpotlightEvents')}
          >
            Poster Presentation
          </button>
          {/* <button
          className={`tab ${activeTab === 'Competitions' ? 'active' : ''}`}
          onClick={() => handleTabClick('Competitions')}
        >
        Student Talks
        </button> */}
          <button
            className={`tab ${activeTab === 'Workshops&Presentations' ? 'active' : ''}`}
            onClick={() => handleTabClick('Workshops&Presentations')}
          >
            Deadline
          </button>
          <button
            className={`tab ${activeTab === 'staffDataCentre' ? 'active' : ''}`}
            onClick={() => handleTabClick('staffDataCentre')}
          >
            Schedule
          </button>
          <button
            className={`tab ${activeTab === 'Proceedings&photos' ? 'active' : ''}`}
            onClick={() => handleTabClick('Proceedings&photos')}
          >
            Proceedings  and Photos
          </button>
          <button
            className={`tab ${activeTab === 'staffTraining' ? 'active' : ''}`}
            onClick={() => handleTabClick('staffTraining')}
          >
            Contact
          </button>

          {/* <button
            className={`tab ${activeTab === 'ACTIVITIES' ? 'active' : ''}`}
            onClick={() => handleTabClick('ACTIVITIES')}
          >
            Faculty In-Charges
          </button> */}
        </div>
        <div className="columns-wrapper row">
          {activeData.map((person, index) => (
            <div className={`column col-md-6 mb-6 ${hoveredIndex === index ? 'hovered' : ''}`} key={index}>
              <div
                className="custom-circle-image"
                onMouseEnter={() => handleHover(index)}
                onMouseLeave={handleHoverEnd}
              >
                <a href={person.link} target="_blank" rel="noopener noreferrer">
                  <img src={person.image} alt={person.name} />
                </a>
              </div>
              <div className={`custom-details-cell ${hoveredIndex === index ? 'hovered' : ''}`}>
                <h2>{person.name}</h2>
                <div className="details-wrapper">
                  <div className="details-row">
                    <span className="details-label">Designation: </span>
                    <span className="details-value">{person.designation}</span>
                  </div>
                  <div className="details-row">
                    <span className="details-label">Phone: </span>
                    <span className="details-value">{person.phoneNumber}</span>
                  </div>
                  <div className="details-row">
                    <span className="details-label">Email: </span>
                    <span className="details-value">{person.emailId}</span>
                  </div>
                </div>
              </div>
            </div>
          ))}





          {activeTab === 'staffTraining' && (
            <div style={{ textAlign: 'left' }}>
              {/* Add your image, description, and heading for staff training */}

              {/* Add a container for spacing */}

              {/* <div className="image-container" style={{ margin: '40px 0' }}>
    <img
      src="https://nitw.ac.in/api/static/files/AMC_2023-5-24-12-39-28.jpg"
      alt="Image 1"
      style={{ width: '100%', height: '500px', maxWidth: '1100px' }}
    />
  </div> */}

              {/* <h3 style={{ textAlign: 'left' }}> Contact</h3> */}
              <p style={{ textAlign: 'justify' }}>
                <b>Patron: </b><br></br>  <a href="https://www.nitw.ac.in/page/?url=/Director/DirectorNITW/">Prof. Bidyadhar Subudhi (Director)</a>
                <br></br>
                <br></br>
                <b>Convenor: </b><br></br>  <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16321">Prof. A. Sarath Babu (Dean Academic)</a>
                <br></br>
                <br></br>
                <b>Secretary: </b><br></br><a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16283">Prof. K V R Ravi Shankar (Associate Dean (Academic Research Programs))</a>
                <br></br>
                <br></br>

                Reach out to the following faculty in case you have any clarifications to make:
                <br></br>

                {/* <b>Members: </b> */}



                <a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16434">Prof. Ch. Ramreddy</a> – MA, PH and CY
                <br></br><a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16930">Prof. Manohar Kakunari</a>– CH, MM and BT
                <br></br><a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17041">Prof. Ram Krishan</a>– EE, EC and CS
                <br></br><a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16650">Prof. Rahul Thangeda</a> – HS and MS
                <br></br><a href="https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/17099">Prof. Jew Das</a>– CE and ME






              </p>
            </div>


          )}

          {/* Add the new section under "Computer Centre" */}
          {activeTab === 'Competitions' && (
            <div className="computer-centre-section">
              {/* <h3>Computer Centre</h3> */}


              {/* Add a container for spacing */}
              {/* <div className="image-container" style={{ margin: '40px 0' }}>
          <img
            src="https://nitw.ac.in/api/static/files/RSC2024_poster_2024-1-17-11-39-8.jpg"
            alt="Image 1"
            style={{ width: '100%', height: '500px', maxWidth: '1100px' }} // Customize width and height
          />
        </div> */}

              <h3 style={{ textAlign: 'left' }}>Rules for Student Talks using presentation or blackboard:</h3>
              <p style={{ textAlign: 'justify' }}>

                1. Each team selects a topic of interest about which they are passionate.<br></br>
                2. Each team shall have a maximum of 3 students.<br></br>
                3. Each team shares a well-developed and rehearsed Talk with the support of visual slides (PPT or black board) that help move the topic forward.<br></br>
                4. Teams will be assigned dates for delivering their Talk in front of the audience.<br></br>
                5. Each team will have 10 minutes to deliver their talk followed by a 5 minute Q&A session.<br></br>
                Register and submit your entries at:



              </p>
              <br />



            </div>




          )}

          {/* Add the new section under "Computer Centre" */}
          {activeTab === 'Workshops&Presentations' && (
            <div className="computer-centre-section">


              {/* Add a container for spacing */}
              {/* <div className="image-container" style={{ margin: '40px 0' }}>
    <img
      src="https://nitw.ac.in/api/static/files/Telecom_Centre_2023-7-18-12-39-38.jpg"
      alt="Image 1"
      style={{ width: '100%', height: '500px', maxWidth: '1100px' }} 
    />

  </div> */}
              {/* <h3 style={{ textAlign: 'left' }}>Deadline</h3> */}
              <p style={{ textAlign: 'justify' }}>
                Late date of submission of poster: 25<sup>th </sup> April, 2025 (17:00) <br></br><br></br>
                <b>
                  First and second best poster presentations from each Department will recieve certificate of appreciation.
                </b>
              </p>


            </div>

          )}

          {/* Add the new section under "Computer Centre" */}
          {activeTab === 'staffDataCentre' && (
            <div className="computer-centre-section">

              {/* Add a container for spacing */}
              {/* <div className="image-container" style={{ margin: '40px 0' }}>
    <img
      src="https://nitw.ac.in/api/static/files/RSC2024_poster_2024-1-17-11-39-8.jpg"
      alt="Image 1"
      style={{ width: '100%', height: '500px', maxWidth: '1100px' }} // Customize width and height
    />
  </div> */}



              {/* <h3 style={{ textAlign: 'left' }}>Schedule</h3> */}
              {/* <p style={{ textAlign: 'justify' }}>   </p> */}
              {/* Add your paragraph content here */}
              {/* For example: */}


              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <th style={{ fontSize: '18px' }}>Time</th>
                    <th style={{ fontSize: '18px' }}>Program Schedule</th>
                    <th style={{ fontSize: '18px' }}>Venue</th>
                  </tr>
                  <tr>
                    <td>09:00 AM - 9:30 AM</td>
                    <td>Registration</td>
                    <td>Ambedkar Learning Center</td>
                  </tr>
                  <tr>
                    <td>09:30 AM - 11:15 AM</td>
                    <td>Inauguration + Keynote address</td>
                    <td>Ambedkar Learning Center</td>
                  </tr>
                  {/* <tr>
      <td>11:00 - 11:15 AM</td>
      <td>Tea break</td>
      <td>ALC</td>
    </tr> */}
                  <tr>
                    <td>11:15 AM - 11:30 AM</td>
                    <td>Tea & Snacks</td>
                    <td>Foyer, Ambedkar Learning Center</td>
                  </tr>
                  <tr>
                    <td>11:30 AM - 01:00 PM</td>
                    <td>Poster Presentation (Session I)</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>01:00 PM - 02:00 PM</td>
                    <td>Lunch for registered participants</td>
                    <td>Kalam International Guest House</td>
                  </tr>



                  {/* <tr>
      <td>02:00 PM - 04:15 PM</td>
      <td>Tea break</td>
      <td>ALC</td>
    </tr> */}

                  <tr>
                    <td>02:00 PM - 05:00 PM</td>
                    <td>Poster Presentation (Session II)</td>
                    <td>-</td>
                  </tr>

                  <tr>
                    <td>05:00 PM - 05:30 PM</td>
                    <td>Valedictory</td>
                    <td>Ambedkar Learning Center</td>
                  </tr>


                  <tr>
                    <td>05:30 PM </td>
                    <td>Tea and Snacks</td>
                    <td>Foyer, Ambedkar Learning Center</td>
                  </tr>


                </tbody>
              </table>



            </div>





          )}



          {/* Add the new section under "Computer Centre" */}
          {activeTab === 'SpotlightEvents' && (
            <div className="computer-centre-section">
              {/* <div className="image-container" style={{ margin: '40px 0' }}>
      <img
      src="https://nitw.ac.in/api/static/files/Poster_Presentation_2024-1-19-11-7-17.jpg"
      alt="Image 1"
      style={{ width: '100%', height: '800px', maxWidth: '700px' }} // Customize width and height
    />
  </div> */}

              <p style={{ textAlign: 'justify' }}>
              Research scholars who have been pursuing their research for more than two years but not more than five years are eligible to present their work at the Research Scholars’ Confluence 2025. The poster presentation must be limited to their current research work.<br/><br/>

                <h4 style={{ textAlign: 'left' }}>
                  Rules :
                </h4>

                <br></br>
                <li style={{ marginBottom: '5px' }}>
                  <span style={{ display: 'inline-block', width: '5px', textAlign: 'center' }}></span>
                  The details of the <b>participant</b> should only be mentioned in the authors name section of the template.
                </li>

                <li style={{ marginBottom: '5px' }}>



                  <span style={{ display: 'inline-block', width: '5px', textAlign: 'center' }}></span>While preparing the poster, please adhere to the format of the template and you are advised to not make any changes to the format
                </li>

                o	Template can be downloaded by clicking here*

                <a href="https://docs.google.com/presentation/d/1fbEicVZTTIMpN1FptnPXMsEHWO_clwVW/edit#slide=id.p1" className="registration-button1">         Download Template</a>


                <br></br>
                <br></br>
                <li style={{ marginBottom: '5px' }}>
                  <span style={{ display: 'inline-block', width: '5px', textAlign: 'center' }}></span>
                  The recommended poster size is 4" x 3" (L x W)
                </li>


                <li style={{ marginBottom: '5px' }}>
                  <span style={{ display: 'inline-block', width: '5px', textAlign: 'center' }}></span>
                  The designated poster presenter must be present at the assigned space during the designated time to discuss the work presented.
                </li>
                <br></br>
                Last Date to register and submit your poster: 25<sup>th </sup> April, 2025 (17:00)

                {/* Submit your entries here */}
                {/* Using the following link: */}


                <a href="https://forms.gle/guSxBpXr4fn1knEX8" className="registration-button1">       Submit</a>
                <br></br>
                <br></br>

                <p className="custom-paragraph1" style={{ fontSize: '12px' }}>
                  *Please login using your institute email to access template and submission form
                </p>


              </p>






            </div>


          )}








          {/* Add the new section under "Computer Centre" */}
          {activeTab === 'AboutTECHNOZION' && (
            <div className="computer-centre-section">

              {/* Add the new sentence with left justification */}
              {/* <div className="image-container" style={{ margin: '40px 0' }}>
      <img
      src="https://nitw.ac.in/api/static/files/Research_Confluence_2024-1-19-11-1-15.jpeg"
      alt="Image 1"
      style={{ width: '100%', height: '800px', maxWidth: '600px' }} // Customize width and height
    />
  </div> */}
              {/* <h3 style={{ textAlign: 'left' }}>
       RSC2024 -About
      </h3> */}
              <p style={{ textAlign: 'justify' }}>

              The Research Scholars’ Confluence (RSC) is the annual research scholars’ event of the National Institute of Technology Warangal. It serves as a platform for research scholars of the institute to showcase their work and engage with peers, faculty, and students of NIT Warangal. Following the success of the prestigious RSC 2024, the 2nd Research Scholars’ Confluence (RSC 2025) will be held on May 02, 2025.<br/>
                <br></br>
                Along with keynote addresses from eminent researchers, Research Scholars will showcase their research work through posters to attendees, which would lead to feedback and collaborations.
                <br></br>
                <br></br>
                Research Scholars’ Confluence 2025 will host the following events:      <br></br>

                <li style={{ marginBottom: '5px' }}>
                  <span style={{ display: 'inline-block', width: '5px', textAlign: 'center' }}></span>
                  Keynote address      </li>
                <li style={{ marginBottom: '5px' }}>
                  <span style={{ display: 'inline-block', width: '5px', textAlign: 'center' }}></span>
                  Poster Presentations      </li>

                <br></br>
                <b>Who can participate:</b> Ongoing research scholars of NIT Warangal who have completed at least two years but not more than five years of their research are eligible.
                <br></br>
                <br></br>
                <b>Last Date for submission of Poster Presentations: </b>25<sup>th </sup> April, 2025 (17:00)





                <a href="https://forms.gle/guSxBpXr4fn1knEX8" className="registration-button1">              Register here</a>

              </p>







            </div>

          )}<br>
          </br>
          {/* Add the new section under "Computer Centre" */}
          {activeTab === 'Proceedings&photos' && (
            <div className="computer-centre-section">
              <div className="row mt-4">
                {/* <div className='col-md-12'>
                  <a type="button" className="btn btn-outline-info" style={{ float: "middle", color: "black" }} href="https://nitw.ac.in/api/static/files/Proceedings_RSC2024_2024-7-1-9-7-38.pdf" > Proceedings</a>
                </div> */}
              </div>
              
                <div className='col-md-12'>
                {/* <div className="row">
                  <div className='col-md-4 mt-2'>
                    <img className="img-thumbnail" src="https://nitw.ac.in/api/static/files/RSC_5894_2_2024-7-1-9-40-39.jpg"  />
                  </div>
                  <div className='col-md-4  mt-2'>
                    <img className="img-thumbnail" src="https://nitw.ac.in/api/static/files/rsc5_2024-7-1-12-16-55.jpg" class="rounded"/>
                  </div>
                 
                  <div className='col-md-4  mt-2'>
                    <img className="img-thumbnail" src="https://nitw.ac.in/api/static/files/rsc6_2024-7-1-12-17-23.jpg"/>                    
                  </div>
                  <div className='col-md-4  mt-2'>
                    <img className="img-thumbnail" src="https://nitw.ac.in/api/static/files/RSC4_2024-7-1-12-14-40.jpg"/>                    
                  </div>
                  <div className='col-md-4  mt-2'>
                    <img className="img-thumbnail" src="https://nitw.ac.in/api/static/files/rsc13_1_2024-7-1-13-20-59.jpg"/>                    
                  </div>
                  <div className='col-md-4 mt-2'>
                    <img className="img-thumbnail" src="https://nitw.ac.in/api/static/files/rsc11_2024-7-1-12-29-8.jpg"/>                    
                  </div>
                  <div className='col-md-4  mt-2'>
                    <img className="img-thumbnail" src="https://nitw.ac.in/api/static/files/rsc10_2024-7-1-12-27-56.jpg"/>                    
                  </div>

                  <div className='col-md-4  mt-2'>
                    <img className="img-thumbnail" src="https://nitw.ac.in/api/static/files/rsc13_2024-7-1-13-20-11.jpg"/>                    
                  </div>
                  <div className='col-md-4  mt-2'>
                    <img className="img-thumbnail" src="https://nitw.ac.in/api/static/files/rsc12_2024-7-1-13-19-29.jpg"/>                    
                  </div>
                  <div className='col-md-4  mt-2'>
                    <img className="img-thumbnail" src="https://nitw.ac.in/api/static/files/rsc7_2024-7-1-12-18-6.jpg"/>                    
                  </div>   
                  <div className='col-md-4  mt-2'>
                    <img className="img-thumbnail" src="https://nitw.ac.in/api/static/files/rsc9_2024-7-1-12-27-29.jpg"/>                    
                  </div> 
                  <div className='col-md-4'>
                    <img className="img-thumbnail" src=""/>                    
                  </div>
                </div> */}
              </div>
            </div>

          )}<br>
          </br>

          {/* ...remaining code... */}

        </div>



      </div>

    </div>

  );
};

export default ResearchScholarConfluencePage2025;