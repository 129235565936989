const profiles = [
    {
        id: 1,
        name: "Prof. Balaprakasa Rao Killi",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-bprao",
        imageUrl: "CSEDeptAssets/images/26.jpg",
        phone: "0870-246-2716",
        mobile: "7002457102",
        email: "bsprao[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Software Defined Networking",
            "Internet of Things",
            "Reinforcement Learning",
            "Game Theory",
            "Network Optimization",
        ],
    },
    {
        id: 2,
        name: "Prof. Ch. Sudhakar",
        designation: "Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-chapram",
        imageUrl: "CSEDeptAssets/images/8.jpg",
        phone: "0870-246-2731",
        mobile: "9440647945",
        email: "chapram[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Unix Internals",
            "Advanced Operating Systems",
            "Cloud Computing",
            "Distributed Systems",
            "High Performance Computing",
            "Operating Systems"
        ],
    },
    {
        id: 3,
        name: "Prof. D V L N Somayajulu",
        designation: "Professor (HAG)",
        profileUrl: "https://wsdc.nitw.ac.in/facultynew/facultyprofile/id/16333",
        imageUrl: "CSEDeptAssets/images/2.jpg",
        phone: "-",
        mobile: "-",
        email: "soma[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Databases",
            "Information Extraction",
            "Query Processing",
            "Big Data",
            "Privacy"
        ],
    },
    {
        id: 4,
        name: "Prof. E. Suresh Babu",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-esbabu",
        imageUrl: "CSEDeptAssets/images/20.jpg",
        phone: "0870-246-2717",
        mobile: "9440959713",
        email: "esbabu[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Wireless Networks",
            "Internet of Things (IoT)",
            "IoT Security",
            "Fog Security",
            "Edge Security",
            "Blockchain Technology"
        ],
    },
    {
        id: 5,
        name: "Prof. I. Earnest Paul",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-iep",
        imageUrl: "CSEDeptAssets/images/19.jpg",
        phone: "-",
        mobile: "9494466490",
        email: "iep[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Visual Analytics",
            "Video Content Analysis",
            "Computer Vision",
            "Deep Learning",
            "Soft Computing",
            "Machine Learning"
        ],
    },
    {
        id: 6,
        name: "Prof. Ilaiah Kavati",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-ilaiahkavati",
        imageUrl: "CSEDeptAssets/images/23.jpg",
        phone: "0870-246-2718",
        mobile: "9848916272",
        email: "ilaiahkavati[AT]nitw[DOT]ac[DOT]in",
        researchAreas: ["Image Processing", "Internet of Things", "Biometrics", "Security"],
    },
    {
        id: 7,
        name: "Prof. K. Ramesh",
        designation: "Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-kramesh",
        imageUrl: "CSEDeptAssets/images/7.jpg",
        phone: "0870-246-2712",
        mobile: "9849432598",
        email: "kramesh[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Serverless Architectures",
            "Service-oriented Computing",
            "Distributed Computing",
            "Secured Computing Environments",
            "Model Driven Framework-oriented Systems",
            "Applications Software",
        ],
    },
    {
        id: 8,
        name: "Prof. K. Venkata Krishna Rao",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-kvkrao",
        imageUrl: "https://nitw.ac.in/api/static/files/kvkrao_2024-12-3-12-17-14.jpg",
        phone: "-",
        mobile: "9724597295",
        email: "kvkrao[AT]nitw[DOT]ac[DOT]in",
        researchAreas: ["Social Networks", "Online Marketing"],
    },
    {
        id: 9,
        name: "Prof. K. V. Kadambari",
        designation: "Associate Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-kadambari",
        imageUrl: "CSEDeptAssets/images/14.jpg",
        phone: "0870-246-2740",
        mobile: "9705460461",
        email: "kadambari[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Computational Neuroscience",
            "Machine Learning",
            "Artificial Intelligence",
            "Neuro-Imaging",
        ],
    },
    {
        id: 10,
        name: "Prof. M. Sandhya",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-msandhya",
        imageUrl: "CSEDeptAssets/images/21.jpg",
        phone: "0870-246-2721",
        mobile: "9394468554",
        email: "msandhya[AT]nitw[DOT]ac[DOT]in",
        researchAreas: ["Biometrics", "Image Processing", "Pattern Recognition"],
    },
    {
        id: 11,
        name: "Prof. M. Srinivas",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-msv",
        imageUrl: "CSEDeptAssets/images/22.jpg",
        phone: "0870-246-2714",
        mobile: "-",
        email: "msv[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Machine Learning",
            "Medical Imaging",
            "Computer Vision",
            "Deep Learning",
            "Sparse Representation"
        ]
    },
    {
        id: 12,
        name: "Prof. Manish Kumar Bajpai",
        designation: "Associate Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-mkbajpai",
        imageUrl: "CSEDeptAssets/images/30.jpg",
        phone: "0870-246-2705",
        mobile: "9425156289",
        email: "mkbajpai[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "High Performance Computing",
            "Augmented Intelligence"
        ]
    },
    {
        id: 13,
        name: "Prof. Manjubala Bisi",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-manjubalabisi",
        imageUrl: "CSEDeptAssets/images/18.jpg",
        phone: "0870-246-2720",
        mobile: "9502940360",
        email: "manjubalabisi[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Software Engineering (Software Reliability Modelling and Prediction)",
            "Machine Learning",
            "Soft Computing Techniques",
            "Optimization Techniques"
        ]
    },
    {
        id: 14,
        name: "Prof. Nidhi Sonkar",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-nidhisonkar",
        imageUrl: "https://www.nitw.ac.in/CSEDeptAssets/images/31.jpg",
        phone: "0870-246-2709",
        mobile: "8871358404",
        email: "nidhisonkar[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Delay Tolerant Network",
            "Wireless Network",
            "Mobile Communication",
            "Artificial Intelligence"
        ]
    },
    {
        id: 15,
        name: "Prof. P. Radha Krishna",
        designation: "Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-prkrishna",
        imageUrl: "CSEDeptAssets/images/6.jpg",
        phone: "0870-246-2703",
        mobile: "-",
        email: "prkrishna[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Data Mining",
            "Big Data",
            "Machine Learning",
            "Databases",
            "Workflow Systems"
        ]
    },
    {
        id: 16,
        name: "Prof. P. Venkata Subba Reddy",
        designation: "Associate Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-pvsr",
        imageUrl: "CSEDeptAssets/images/15.jpg",
        phone: "0870-246-2741",
        mobile: "8332969417",
        email: "pvsr[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Algorithms",
            "Graph Theory"
        ]
    },
    {
        id: 17,
        name: "Prof. Priyanka Chawla",
        designation: "Associate Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-priyankac",
        imageUrl: "CSEDeptAssets/images/16.jpg",
        phone: "0870-246-2719",
        mobile: "7982750652",
        email: "priyankac[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Cloud Computing",
            "Fog Computing",
            "Software Engineering",
            "Nature Inspired Computing",
            "Artificial Intelligence"
        ]
    },
    {
        id: 18,
        name: "Prof. R. B. V. Subramaanyam",
        designation: "Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-rbvs66",
        imageUrl: "CSEDeptAssets/images/5.jpg",
        phone: "0870-246-2736",
        mobile: ["9491346969", "9441434454"],
        email: "rbvs66[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Data Mining - Association Rules",
            "Sequential Patterns",
            "Temporal Data Mining",
            "Fuzzy Data Mining",
            "Big Data Analytics",
            "Distributed Data Mining",
            "Graph Databases",
            "Pattern Recognition",
            "Soft Computing"
        ]
    },
    {
        id: 19,
        name: "Prof. R. Padmavathy",
        designation: "Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-rpadma",
        imageUrl: "CSEDeptAssets/images/9.jpg",
        phone: "0870-246-8738",
        mobile: "9440173819",
        email: "rpadma[AT]nitw[DOT]ac[DOT]in",
        researchAreas: ["Cryptanalysis", "Network Security"]
    },
    {
        id: 20,
        name: "Prof. Raju Bhukya",
        designation: "Associate Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-raju",
        imageUrl: "CSEDeptAssets/images/12.jpg",
        phone: "0870-246-2732",
        mobile: ["9700553922", "8332969733"],
        email: "raju[AT]nitw[DOT]ac[DOT]in",
        researchAreas: ["Bioinformatics", "Agri-Informatics", "Internet of Things"]
    },
    {
        id: 21,
        name: "Prof. Ramalingaswamy Cheruku",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-rmlswamy",
        imageUrl: "CSEDeptAssets/images/28.jpg",
        phone: "0870-246-2710",
        mobile: "9573827143",
        email: "rmlswamy[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Soft Computing",
            "Optimization",
            "Deep Learning and Its Applications",
            "Artificial Neural Networks",
            "Machine Learning",
            "Bio & Nature Inspired Algorithms",
            "Data Mining",
            "Fuzzy Logic"
        ]
    },
    {
        id: 22,
        name: "Prof. Rashmi Ranjan Rout",
        designation: "Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-rashrr",
        imageUrl: "CSEDeptAssets/images/10.jpg",
        phone: "0870-246-2742",
        mobile: "08332969418",
        email: "rashrr[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Mobile Computing",
            "Wireless Ad-hoc and Sensor Networks",
            "Online Social Networks",
            "Cyber Physical Systems",
            "Internet of Things",
            "Network Security",
            "Cloud Computing"
        ]
    },
    {
        id: 23,
        name: "Prof. S. Ravi Chandra",
        designation: "Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-ravic",
        imageUrl: "CSEDeptAssets/images/1.jpg",
        phone: "0870-246-2730",
        mobile: "-",
        email: "ravic[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Data Privacy",
            "Software Engineering",
            "Software Architecture",
            "Design Patterns",
            "Service Oriented Architecture"
        ]
    },
    {
        id: 24,
        name: "Prof. Sangharatna Godboley",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-sanghu",
        imageUrl: "CSEDeptAssets/images/29.jpg",
        phone: "0870-246-2715",
        mobile: "7013306805",
        email: "sanghu[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Program Analysis",
            "Software Testing",
            "Fuzzing",
            "Verification",
            "Smart Contracts",
            "Machine Learning"
        ]
    },
    {
        id: 25,
        name: "Prof. Sanjaya Kumar Panda",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-sanjaya",
        imageUrl: "CSEDeptAssets/images/25.jpg",
        phone: "0870-246-2723",
        mobile: "9861126947",
        email: "sanjaya[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Cloud and Fog Computing",
            "Recommender Systems",
            "Internet of Things"
        ]
    },
    {
        id: 26,
        name: "Prof. Sarath Babu",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-sarathbabu",
        imageUrl: "https://nitw.ac.in/api/static/files/sarathbabu_2025-3-11-11-8-50.jpg",
        phone: "0870-246-",
        mobile: "7994117884",
        email: "sarathbabu[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Wireless Communications and Networks (Adhoc Networks, 5G and Beyond)",
            "Vehicular Adhoc Networks (VANET)/Internet of Vehicles (IoV)",
            "Cybersecurity",
            "Next-Generation Network Architectures (SDN, NFV, etc.)"
        ]
    },
    {
        id: 27,
        name: "Prof. Shiva Darshan SL",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-shivadarshan",
        imageUrl: "https://www.nitw.ac.in/CSEDeptAssets/images/32.jpg",
        phone: "0870-246-2708",
        mobile: "9743413637",
        email: "shivadarshan[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Cyber Security Systems",
            "Malware Vulnerability Assessment",
            "Machine Learning for Security",
            "Deep Learning for Security"
        ]
    },
    {
        id: 28,
        name: "Prof. Sriram Kailasam",
        designation: "Associate Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-sriramk",
        imageUrl: "CSEDeptAssets/images/17.jpg",
        phone: "0870-246-2722",
        mobile: "8894275490",
        email: "sriramk[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
            "Distributed Systems",
            "Scalable Algorithms for Formal Concept Analysis",
            "Big Data Analytics"
        ]
    },
    {
        id: 29,
        name: "Prof. Sujit Das",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-sujit.das",
        imageUrl: "CSEDeptAssets/images/24.jpg",
        phone: "0870-246-2724",
        mobile: "9434588993",
        email: "sujit.das[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
          "Soft Computing",
          "Artificial Intelligence",
          "Evolutionary Computation",
          "Machine Learning",
          "Multi-criteria Decision Making"
        ]
      },
      {
        id: 30,
        name: "Prof. T. Ramakrishnudu",
        designation: "Associate Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-trk",
        imageUrl: "CSEDeptAssets/images/11.jpg",
        phone: "0870-246-2734",
        mobile: "9866876842",
        email: "trk[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
          "Distributed Data Mining",
          "Big Data Analytics",
          "Web Mining",
          "Text Mining",
          "Urban Computing",
          "Social Media Analysis",
          "Natural Language Processing"
        ]
      },
      {
        id: 31,
        name: "Prof. Tapan Kumar Sahoo",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-tapan",
        imageUrl: "https://nitw.ac.in/api/static/files/Prof._Tapan_Kumar_Sahoo_2024-1-23-17-23-19.jpg",
        phone: "-",
        mobile: "7978180153",
        email: "tapan[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
          "Pattern Analysis",
          "Machine Learning"
        ]
      },
      {
        id: 32,
        name: "Prof. U. S. N. Raju",
        designation: "Associate Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-usnraju",
        imageUrl: "CSEDeptAssets/images/13.jpg",
        phone: "0870-246-2739",
        mobile: "9440149146",
        email: "usnraju[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
          "Computer Vision",
          "Image Processing",
          "Digital Olfaction"
        ]
      },
      {
        id: 33,
        name: "Prof. Venkateswara Rao Kagita",
        designation: "Assistant Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-venkat.kagita",
        imageUrl: "CSEDeptAssets/images/27.jpg",
        phone: "0870-246-2713",
        mobile: "6281746931",
        email: "venkat.kagita[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
          "Recommender Systems",
          "Machine Learning",
          "Deep Learning",
          "Data Mining"
        ]
      },
      {
        id: 34,
        name: "Prof. Venkanna U",
        designation: "Associate Professor",
        profileUrl: "https://erp.nitw.ac.in/ext/profile/cs-venkannau",
        imageUrl: "https://nitw.ac.in/api/static/files/Prof._Venkanna_U_2023-11-29-16-48-39.jpg",
        phone: "0870-246-2707",
        mobile: "9440973184",
        email: "venkannau[AT]nitw[DOT]ac[DOT]in",
        researchAreas: [
          "Internet of Things (IoT)",
          "Software Defined Networks (SDN)",
          "Network Security"
        ]
      }
];

export default profiles;