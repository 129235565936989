import { useState } from "react";
import '../css/cseDeptStyle.css';
// import '../css/libraryDeptStyle.css';

const LibraryDeptPage = () => {

  const [activeTab, setActiveTab] = useState("v-pills-visionandmission");
  const [peopleTab, setPeopleTab] = useState("Faculty");

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
    console.log(tabId)
  }

  const handlePeopleTabClick = (tabId) => {
    setPeopleTab(tabId);
    console.log(tabId)
  }
  return (
    <><div class="dept-wrapper" >
      <div class="container">
        <section class="py-4 header">
          <header>
            {/* */}
            {/*<img src="CSEDeptAssets/images/CS-banner_NITW-new.png" class="img-fluid"/>*/}
            <img
              src="https://nitw.ac.in/api/static/files/library_2024-5-10-17-22-2.jpg" class="img-fluid" />
          </header>
          <div class="row">
            <div class="col-md-3" >
              {/* Tabs nav */}
              <div class="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist"
                aria-orientation="vertical" >
                {/*<a class="nav-link    active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><span>Home</span></a>*/}
                <a class={`nav-link ${activeTab === 'v-pills-visionandmission' ? 'active' : ''}`} id="v-pills-visionandmission-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-visionandmission')}
                  role="tab" aria-controls="v-pills-visionandmission"
                  aria-selected={activeTab === 'v-pills-visionandmission' ? 'true' : 'false'}
                  style={{ backgroundColor: activeTab === 'v-pills-visionandmission' ? '#c20315' : '#00FFFF' }}>
                  <span>About</span>
                </a>
                <a class={`nav-link ${activeTab === 'v-pills-academics' ? 'active' : ''}`}
                  id="v-pills-academics-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-academics')} role="tab"
                  aria-controls="v-pills-academics" aria-selected={activeTab === 'v-pills-academics' ? 'true' : 'false'}
                  style={{ backgroundColor: activeTab === 'v-pills-academics' ? '#c20315' : '#00FFFF' }}>
                  <span>Membership</span>
                </a>
                <a class={`nav-link ${activeTab === 'v-pills-Faculty' ? 'active' : ''}`} id="v-pills-Faculty-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Faculty')}
                  role="tab" aria-controls="v-pills-Faculty" aria-selected={activeTab === 'v-pills-Faculty' ? 'true' : 'false'}
                  style={{ backgroundColor: activeTab === 'v-pills-Faculty' ? '#c20315' : '#00FFFF' }}>
                  <span>People </span>
                </a>
                <a class={`nav-link ${activeTab === 'v-pills-NotableAlumni' ? 'active' : ''}`} id="v-pills-NotableAlumni-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-NotableAlumni')}
                  role="tab" aria-controls="v-pills-NotableAlumni"
                  aria-selected={activeTab === 'v-pills-NotableAlumni' ? 'true' : 'false'}
                  style={{ backgroundColor: activeTab === 'v-pills-NotableAlumni' ? '#c20315' : '#00FFFF' }}>
                  <span>Online Public Access Catalogue</span>
                </a>
                <a class={`nav-link ${activeTab === 'v-pills-Students' ? 'active' : ''}`}
                  id="v-pills-Students-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Students')} role="tab"
                  aria-controls="v-pills-Students" aria-selected={activeTab === 'v-pills-Students' ? 'true' : 'false'}
                  style={{ backgroundColor: activeTab === 'v-pills-Students' ? '#c20315' : '#00FFFF' }}>
                  <span>Services </span>
                </a>
                <a class={`nav-link ${activeTab === 'v-pills-AcademicResearchGroup' ? 'active' : ''}`} id="v-pills-AcademicResearchGroup-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-AcademicResearchGroup')}
                  role="tab"
                  aria-controls="v-pills-AcademicResearchGroup" aria-selected={activeTab === 'v-pills-AcademicResearchGroup' ? 'true' : 'false'}
                  style={{ backgroundColor: activeTab === 'v-pills-AcademicResearchGroup' ? '#c20315' : '#00FFFF' }}> <span>E-Resources</span>
                </a>
                <a class={`nav-link ${activeTab === 'v-pills-Awards' ? 'active' : ''}`} id="v-pills-Awards-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-Awards')}
                  role="tab" aria-controls="v-pills-Awards"
                  aria-selected={activeTab === 'v-pills-Awards' ? 'true' : 'false'}
                  style={{ backgroundColor: activeTab === 'v-pills-Awards' ? '#c20315' : '#00FFFF' }}> <span>Library Policies</span> </a>
                {/*<a class="nav-link ${activeTab === ? 'active' : ''}   " id="v-pills-ResearchScholars-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-ResearchScholars')} href="#v-pills-ResearchScholars" role="tab" aria-controls="v-pills-ResearchScholars" aria-selected={activeTab === 'v-pills-AcademicResearchGroup' ? 'true' : 'false'}
                style={{ backgroundColor: activeTab === 'v-pills-AcademicResearchGroup' ? '#c20315' : '#00FFFF' }}><span>Research Scholars</span></a>*/}
                <a class={`nav-link ${activeTab === 'v-pills-labs' ? 'active' : ''}`} id="v-pills-labs-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-labs')} role="tab"
                  aria-controls="v-pills-labs" aria-selected={activeTab === 'v-pills-labs' ? 'true' : 'false'}
                  style={{ backgroundColor: activeTab === 'v-pills-labs' ? '#c20315' : '#00FFFF' }}>
                  <span>Remote Access Service</span>
                </a>
                <a class={`nav-link ${activeTab === 'v-pills-PhotoGallery' ? 'active' : ''}`} id="v-pills-PhotoGallery-tab" data-toggle="pill" onClick={() => handleTabClick('v-pills-PhotoGallery')}
                  role="tab" aria-controls="v-pills-PhotoGallery"
                  aria-selected={activeTab === 'v-pills-PhotoGallery' ? 'true' : 'false'}
                  style={{ backgroundColor: activeTab === 'v-pills-PhotoGallery' ? '#c20315' : '#00FFFF' }}> <span>General Rules</span> </a>


                {/* <a class={`nav-link ${activeTab === 'v-pills-AYL' ? 'active' : ''}`} id="v-pills-labs-AYL" data-toggle="pill" onClick={() => handleTabClick('v-pills-AYL')} role="tab"
                                    aria-controls="v-pills-AYL" aria-selected={activeTab === 'v-pills-AcademicResearchGroup' ? 'true' : 'false'}
                style={{ backgroundColor: activeTab === 'v-pills-AcademicResearchGroup' ? '#c20315' : '#00FFFF' }}>
                                    <span>Ask your Librarian</span>
                                </a> */}

                <a class={`nav-link ${activeTab === 'v-pills-FIS' ? 'active' : ''}`} id="v-pills-labs-FIS" data-toggle="pill" onClick={() => handleTabClick('v-pills-FIS')} role="tab"
                  aria-controls="v-pills-FIS" aria-selected={activeTab === 'v-pills-FIS' ? 'true' : 'false'}
                  style={{ backgroundColor: activeTab === 'v-pills-FIS' ? '#c20315' : '#00FFFF' }}>
                  <span>Faculty Information System</span>
                </a>


                <a class={`nav-link ${activeTab === 'v-pills-E-Books' ? 'active' : ''}`} id="v-pills-labs-E-Books" data-toggle="pill" onClick={() => handleTabClick('v-pills-E-Books')} role="tab"
                  aria-controls="v-pills-E-Books" aria-selected={activeTab === 'v-pills-E-Books' ? 'true' : 'false'}
                  style={{ backgroundColor: activeTab === 'v-pills-E-Books' ? '#c20315' : '#00FFFF' }}>
                  <span>New E-Books</span>
                </a>





              </div>
              <div class="head_of_dept"> <a href="#"
                target="_blank"> <img src="https://nitw.ac.in/api/static/files/ysrao_2024-9-19-15-46-43.jpg" alt="" class="img-fluid" width="190" height="220" />
                <h6>Dr. Y. Srinivasa Rao</h6>
              </a>
                <p class="justified-text">Librarian <br /> <a href="mailto:librarian@nitw.ac.in"
                  style={{ color: "#808080" }}>librarian@nitw.ac.in,<br /> cenlib@nitw.ac.in </a> <br /> 9491085289 </p>
              </div>
            </div>
            <div class="col-md-9">
              {/* Tabs content */}
              <div class="tab-content" id="v-pills-tabContent">
                {/*<div class="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade    show active p-2" id="v-pills-home" role="tabpanel" aria-labelledby="v-pills-home-tab"></div>*/}

                <div class={`tab-pane fade ${activeTab === 'v-pills-visionandmission' ? 'show active' : 'alumniTabHide'} `} id="v-pills-visionandmission"
                  role="tabpanel" aria-labelledby="v-pills-visionandmission-tab">
                  <div class="intro-section">
                    {/* <h4 class=" mb-4">Welcome to Computer Science and Engineering Department</h4>*/}
                    <p class="justified-text">

                      The Central Library supports the teaching and research programs of the Institute and provides facilities for general reading and disseminates information according to the requirement of the users. It is housed in a separate building with a plinth area of 4000 Sq. Mts.  It has around 1,87,014 books, back volumes, technical pamphlets, standards, CD-ROMs, Video Cassettes, e-books etc.  The total cost of the books is Rs. 10 Crores and Central Library subscribed 3236 e-journals. It also receives 4646 e-journals through E-Shodh Sindhu consortum.

                    </p>

                    <p class="justified-text">
                      The services and operations in the Central Library are fully computerized. The database of entire Library acquisitions was created using Koha software.
                    </p>
                    <p class="justified-text">
                      Library at NIT Warangal is your window to the world of latest information in sciences, engineering, technology, School of Management. Being an integral part of academic and research work on the campus, it provides library and information services to support the teaching, learning, and research activities of the Institute by creating state-of-the-art facilities and offering innovative services. The library has a fast growing collection of books, journals, magazines both in print and digital format. Most of the in-house services of the library are fully computerized and provides access to many core subscribed online and open access resources.

                    </p>
                    <h5>Working Hours</h5>
                    <p class="justified-text">
                      The Library works on all days of the year except a few holidays of national and social importance.
                      <br></br>
                      <b>Week days: </b>9 a.m. to 9.00 p.m.
                      <br></br>
                      <b>Saturdays, Sundays & Holidays: </b> 9.00 a.m. to 5.00 p.m.
                      <br></br>
                      <b>During vacation: </b> 9 a.m. to 5.30 p.m.
                      <br></br>
                    </p><br></br>

                    <a href="http://www.ebooklibrary.nitw.ac.in:8080   " class="btn btn-info" role="button" >Ebooks@NITW </a>
                    <a href="http://ir.library.nitw.ac.in:8080/jspui/   " class="btn btn-info" role="button" >DSpace@NITW</a>
                    <a href="https://shodhganga.inflibnet.ac.in/handle/10603/349258" class="btn btn-info" role="button">Shodhganga@NITW</a>








                  </div>
                  {/* <div class="row vision-mission-section">
                    <div class="col-md-14 vision-section">
                      <h4 class="mt-2 mb-2" align='center'>Annual Research Digest of Ph.D Theses</h4><br></br>
                      <p class="  mb-4">


                      </p>
                      <p class="mb-4" >
                        <center>
                          <a href="https://nitw.ac.in/ard2019/" class="btn btn-info" role="button" >Annual Research Digest of Ph.D Theses (2019 - 2020)</a>

                          <br></br>

                          <a href="https://nitw.ac.in/ard/" class="btn btn-info" role="button">Annual Research Digest of Ph.D Theses (2018 - 2019)</a>
                        </center>
                      </p>


                    </div>

                  </div> */}
                </div>
                <div class={`tab-pane ${activeTab === 'v-pills-academics' ? 'show active' : 'alumniTabHide'} fade   " id="v-pills-academics`} role="tabpanel"
                  aria-labelledby="v-pills-academics-tab">
                  {/*<h4 class="mb-4">Academic Programmes</h4>*/}
                  {/*<div class="row">
  <div class="col-md-12">
    <h3 class="mb-3">PhD</h3>
  </div>
</div>*/}
                  <div class="row">


                    <div class="col-md-6 col-sm-12">
                      <div class="card-4">
                        <div class="card-detail">
                          <div class="head-bx">
                            <h3>Barcode Member ID Cards</h3>
                            <p class="justified-text">

                              Each bonafide user will be provided smart card with bar-code for the duration of course.  The Member ID card is not transferable.  Penalty for loss of member ID card is Rs. 500.
                            </p>


                          </div>


                        </div>
                      </div>
                    </div>


                    <div class="col-md-6 col-sm-12">
                      <div class="card-4">
                        <div class="card-detail">
                          <div class="head-bx">


                            <h3>Guide to Main Subjects</h3>

                            <p class="more"> <a class="more-info-link"
                              href="https://nitw.ac.in/api/static/files/Guide_to_Main_Subjects_2022-7-22-10-41-45.pdf"
                              target="_blank">Guide to Main Subjects<i class="fa fa-angle-right"></i> </a> </p>


                          </div>


                        </div>
                      </div>
                    </div>


                    <div class="col-md-14 col-sm-12">
                      <div class="card-4">
                        <div class="card-detail">



                          <div class="head-bx">

                            <h3 align='center'>Borrowing Privileges </h3>
                          </div>

                        </div>



                        <table className="table table-bordered">
                          <tbody>
                            <tr>
                              <th style={{ fontSize: '18px' }}>Category</th>
                              <th style={{ fontSize: '18px' }}>	Number of Books</th>
                              <th style={{ fontSize: '18px' }}>	Borrowing Period</th>
                            </tr>
                            <tr>
                              <td>UG Students</td>
                              <td>6</td>
                              <td>One Month</td>
                            </tr>

                            <tr>
                              <td>PG/M.Tech./MCA/MBA & Research Scholars</td>
                              <td>6</td>
                              <td>One Month</td>
                            </tr>
                            <tr>
                              <td>Faculty Members</td>
                              <td>12</td>
                              <td>One Semester</td>
                            </tr>
                            <tr>
                              <td>Administrative Officers</td>
                              <td>2</td>
                              <td>One Month</td>
                            </tr>

                            <tr>
                              <td>Administrative / Technical Staff</td>
                              <td>2</td>
                              <td>One Month</td>
                            </tr>


                          </tbody>
                        </table>

                      </div>
                    </div>






                  </div>

                </div>




                <div class={`tab-pane ${activeTab === 'v-pills-labs' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-labs" role="tabpanel"
                  aria-labelledby="v-pills-labs-tab">
                  <div class="row laboratory-facilities">
                    <div class="col-md-12 col-sm-6 laboratory-facilities-individual">
                      <header>
                        <h3 class="" align='center'>Remote Access Service</h3>
                      </header>

                      <p class="justified-text">Central Library, NITW introduced a new service called "Remote Access Services - MyLOFT (My Library On Fingertips)" for the benefit of Research Scholars and Faculty. MyLOFT is a mobile App. Through MyLOFT research scholars and faculty can access e-resources subscribed by the NITW anywhere and anytime. Hence, all the P.G. students, Ph.D. students and faculty are requested to utilize the facility to enhance the quality Engineering Education and Research.
                        <br></br>
                        <br></br>
                        <b> Remote Access Services Request Form: </b>
                        <a href="https://forms.gle/3UxbXJ3q9MEqY3PCA">
                          https://forms.gle/3UxbXJ3q9MEqY3PCA </a></p>


                    </div>

                  </div>
                </div>






                <div class={`tab-pane ${activeTab === 'v-pills-Phd' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Phd" role="tabpanel"
                  aria-labelledby="v-pills-Phd-tab">
                  <h4 class=" mb-4">Phd</h4> <a href="" target="_blank"> <span class="highlight">P</span>h.D.
                    Students List ( Full / Part Time) <i class="fa fa-link " aria-hidden="true"></i> </a>
                </div>
                <div class={`tab-pane ${activeTab === 'v-pills-Faculty' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Faculty" role="tabpanel"
                  aria-labelledby="v-pills-Faculty-tab">
                  <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">


                    <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'Faculty' ? 'active' : ''} `} id="nav-faculty-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-faculty"
                      aria-selected="true" onClick={() => { handlePeopleTabClick("Faculty") }} style={{
                        backgroundColor: peopleTab === 'Faculty' ? '#c20315' : '#00FFFF'
                      }}>Library Officers </a>


                    {/* <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'AdjunctFaculty' ? 'active' : ''} `}
                                            id="nav-AdjunctFaculty-tab" data-toggle="tab"
                                            role="tab" aria-controls="nav-AdjunctFaculty" aria-selected={activeTab === 'v-pills-AcademicResearchGroup' ? 'true' : 'false'}
                style={{ backgroundColor: activeTab === 'v-pills-AcademicResearchGroup' ? '#c20315' : '#00FFFF' }} onClick={() => { handlePeopleTabClick("AdjunctFaculty") }}> Adjunct Faculty</a> */}


                    <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'NonTeachingStaff' ? 'active' : ''} `}
                      id="nav-NonTeachingStaff-tab" data-toggle="tab"
                      role="tab" aria-controls="nav-NonTeachingStaff" onClick={() => { handlePeopleTabClick("NonTeachingStaff") }}
                      style={{ backgroundColor: peopleTab === 'NonTeachingStaff' ? '#c20315' : '#00FFFF' }}>Library Staff</a>


                    {/* <a class={`nav-item subtabs tab-bold nav-link ${peopleTab === 'ResearchScholars' ? 'active' : ''} `} id="nav-ResearchScholars-tab"
                                            data-toggle="tab" role="tab"
                                            aria-controls="nav-ResearchScholars" aria-selected={activeTab === 'v-pills-AcademicResearchGroup' ? 'true' : 'false'}
                style={{ backgroundColor: activeTab === 'v-pills-AcademicResearchGroup' ? '#c20315' : '#00FFFF' }} onClick={() => { handlePeopleTabClick("ResearchScholars") }}>Research Scholars</a> */}


                  </div>



                  <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent"
                    style={{ marginTop: "5%", marginLeft: "1%" }}>
                    <div class={`tab-pane ${peopleTab === 'Faculty' ? 'show active' : ''} fade`} id="nav-faculty" role="tabpanel"
                      aria-labelledby="nav-faculty-tab">
                      {/*<h4 class=" mb-4">Faculty</h4>*/}
                      {/* <a href="" target="_blank"><span class="highlight">F</span>aculty Profiles <i class="fa fa-external-link" aria-hidden="true"></i></a>*/}
                      <section id="profile-info">

                        <div class="col-md-12 profile-info-individual">
                          <div class="row">
                            <div class="col-md-3"> <a
                              href="#"
                              target="_blank"> <img src="https://nitw.ac.in/api/static/files/ysrao_2024-9-19-15-46-43.jpg" alt=""
                                class="img-fluid" width="150" height="180" /> </a> </div>
                            <div class="col-md-9">
                              <div class="card ">
                                <div class="data">
                                  <h5> <a href="#"
                                    target="_blank">Dr. Y. Srinivasa Rao</a> </h5>
                                  <span>Librarian </span>
                                  <p class="contact-info">
                                    <i class="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2900<br />
                                    <i class="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 9491085289  <br /> <i
                                        class="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:ysrao@nitw.ac.in">ysrao@nitw.ac.in</a> </p>
                                  <p class="justified-text"></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12 profile-info-individual">
                          <div class="row">
                            <div class="col-md-3"> <a
                              href="#"
                              target="_blank"> <img src="https://nitw.ac.in/api/static/files/Dr._Iranna_M._Shettar_2024-4-30-11-38-50.jpg" alt=""
                                class="img-fluid" width="150" height="180" /> </a> </div>
                            <div class="col-md-9">
                              <div class="card ">
                                <div class="data">
                                  <h5> <a href="#v"
                                    target="_blank">Dr. Iranna M. Shettar</a>
                                  </h5> <span>Deputy Librarian</span>
                                  <p class="contact-info">
                                    <i class="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2906<br />

                                    <i class="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 8050209252 <br /> <i
                                        class="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:imshettar@nitw.ac.in ">imshettar@nitw.ac.in </a>
                                  </p>
                                  <p class="justified-text"></p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="col-md-12 profile-info-individual">
                          <div class="row">
                            <div class="col-md-3"> <a
                              href="#"
                              target="_blank" class="img-fluid"> <img
                                src="https://nitw.ac.in/api/static/files/G._Balakrishna_2024-5-10-17-3-43.jpg" alt="" class="img-fluid" width="150" height="180" /> </a>
                            </div>
                            <div class="col-md-9">
                              <div class="card ">
                                <div class="data">
                                  <h5> <a href="#"
                                    target="_blank">G. Balakrishna</a> </h5>
                                  <span>Assistant Librarian </span>
                                  <p class="contact-info">

                                    <i class="fa fa-phone"
                                      aria-hidden="true"></i> 0870-246-2902<br />

                                    <i class="fa fa-mobile fa-lg"
                                      aria-hidden="true"></i> 8332969272 <br /> <i
                                        class="fa fa-envelope" aria-hidden="true"></i> <a
                                          href="mailto:balakrishna@nitw.ac.in">balakrishna@nitw.ac.in</a>
                                  </p>
                                  <p class="justified-text"></p>
                                </div>
                              </div>
                            </div>
                          </div>





                        </div>

                      </section>



                    </div>




















                    <div class={`tab-pane ${peopleTab === 'NonTeachingStaff' ? 'show active' : ''} fade`} id="nav-NonTeachingStaff" role="tabpanel"
                      aria-labelledby="nav-NonTeachingStaff-tab">
                      <div class="row academic_group">
                        <div class="col-md-6">
                          <div class="card">
                            <div class="d-flex align-items-center">
                              <div class="image"> <img src="https://nitw.ac.in/api/static/files/Shri_Kanishka_Samantha_2024-5-6-12-29-17.jpg" alt=""
                                class="img-fluid" /> </div>
                              <div class="ml-3 w-100">
                                <h6 class="mb-0 mt-0">Shri Kanishka Samanta</h6> <span>Library and Information Assistant</span>
                                <div class=" mt-2">
                                  <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                    aria-hidden="true"></i> 7013738754 <br /> <i
                                      class="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kanishkas@nitw.ac.in">kanishkas@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="card">
                            <div class="d-flex align-items-center">
                              <div class="image"> <img src="https://nitw.ac.in/api/static/files/Shri_P.Dayakar_2024-5-6-12-36-38.jpg" alt=""
                                class="img-fluid" /> </div>
                              <div class="ml-3 w-100">
                                <h6 class="mb-0 mt-0">Shri P.Dayakar</h6>
                                <span>Assistant S.G-II</span>
                                <div class=" mt-2">
                                  <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                    aria-hidden="true"></i> 9849335149 <br /> <i
                                      class="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:pdayakar@nitw.ac.in">pdayakar@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div class="col-md-6">
                          <div class="card">
                            <div class="d-flex align-items-center">
                              <div class="image"> <img src="https://nitw.ac.in/api/static/files/Smt._B._Jayasree_2024-5-6-12-38-16.jpg" alt=""
                                class="img-fluid" /> </div>
                              <div class="ml-3 w-100">
                                <h6 class="mb-0 mt-0">Smt. B. Jayasree</h6>
                                <span>Technician (UG)</span>
                                <div class=" mt-2">
                                  <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                    aria-hidden="true"></i> 9701051351 <br /> <i
                                      class="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:-">-</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="col-md-6">
                          <div class="card">
                            <div class="d-flex align-items-center">
                              <div class="image"> <img src="https://nitw.ac.in/api/static/files/Shri_G.Sambaiah_2024-5-6-12-39-42.jpg" alt=""
                                class="img-fluid" /> </div>
                              <div class="ml-3 w-100">
                                <h6 class="mb-0 mt-0">Shri G.Sambaiah</h6>
                                <span>Sr. Caretaker S.G.-II</span>
                                <div class=" mt-2">
                                  <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                    aria-hidden="true"></i> 9866848826 <br /> <i
                                      class="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:sambaiah.nit@gmail.com">sambaiah.nit@gmail.com</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div class="col-md-6">
                          <div class="card">
                            <div class="d-flex align-items-center">
                              <div class="image"> <img src="https://nitw.ac.in/api/static/files/Shri_T.Thirupathi_2024-5-6-12-41-16.jpg" alt=""
                                class="img-fluid" /> </div>
                              <div class="ml-3 w-100">
                                <h6 class="mb-0 mt-0">Shri T.Thirupathi</h6>
                                <span>Senior Caretaker</span>
                                <div class=" mt-2">
                                  <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                    aria-hidden="true"></i> 8332969640 <br /> <i
                                      class="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:-">-</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="col-md-6">
                          <div class="card">
                            <div class="d-flex align-items-center">
                              <div class="image"> <img src="https://nitw.ac.in/api/static/files/Shri_K.Mallesham_2024-5-6-12-42-49.jpg" alt=""
                                class="img-fluid" /> </div>
                              <div class="ml-3 w-100">
                                <h6 class="mb-0 mt-0">Shri K.Mallesham</h6>
                                <span>Office Attendant</span>
                                <div class=" mt-2">
                                  <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                    aria-hidden="true"></i> 9989614297 <br /> <i
                                      class="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kandaktlam@nitw.ac.in">kandaktlam@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>



                        <div class="col-md-6">
                          <div class="card">
                            <div class="d-flex align-items-center">
                              <div class="image"> <img src="https://nitw.ac.in/api/static/files/Shri_N.Kumara_Swamy_2024-5-6-12-44-19.jpg" alt=""
                                class="img-fluid" /> </div>
                              <div class="ml-3 w-100">
                                <h6 class="mb-0 mt-0">Shri N.Kumara Swamy</h6>
                                <span>Office Attendant</span>
                                <div class=" mt-2">
                                  <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                    aria-hidden="true"></i> 9542063572 <br /> <i
                                      class="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:kumaraswamy77@nitw.ac.in">kumaraswamy77@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="col-md-6">
                          <div class="card">
                            <div class="d-flex align-items-center">
                              <div class="image"> <img src="https://nitw.ac.in/api/static/files/Shri_K.Devendra_Chary_2024-5-6-12-46-47.jpg" alt=""
                                class="img-fluid" /> </div>
                              <div class="ml-3 w-100">
                                <h6 class="mb-0 mt-0">Shri K.Devendra Chary</h6>
                                <span>Lab Attendant</span>
                                <div class=" mt-2">
                                  <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                    aria-hidden="true"></i> 9550587293 <br /> <i
                                      class="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:chary@nitw.ac.in">chary@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>


                        <div class="col-md-6">
                          <div class="card">
                            <div class="d-flex align-items-center">
                              <div class="image"> <img src="https://nitw.ac.in/api/static/files/Shri_A.Durga_Prasad_2024-5-6-12-48-2.jpg" alt=""
                                class="img-fluid" /> </div>
                              <div class="ml-3 w-100">
                                <h6 class="mb-0 mt-0">Shri A.Durga Prasad</h6>
                                <span>Lab Attendant</span>
                                <div class=" mt-2">
                                  <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                    aria-hidden="true"></i> 9676073339 <br /> <i
                                      class="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:prasad6@nitw.ac.in">prasad6@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                        <div class="col-md-6">
                          <div class="card">
                            <div class="d-flex align-items-center">
                              <div class="image"> <img src="https://nitw.ac.in/api/static/files/Shri_N.Kumar_2024-5-6-12-49-25.jpg" alt=""
                                class="img-fluid" /> </div>
                              <div class="ml-3 w-100">
                                <h6 class="mb-0 mt-0">Shri N.Kumar</h6>
                                <span>Office Attendant</span>
                                <div class=" mt-2">
                                  <p class="contact-info"> <i class="fa fa-mobile fa-lg"
                                    aria-hidden="true"></i> 9963355460 <br /> <i
                                      class="fa fa-envelope" aria-hidden="true"></i> <a
                                        href="mailto:nkumar100676@nitw.ac.in">nkumar100676@nitw.ac.in</a>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>




                      </div>
                    </div>








                    <div class={`tab-pane ${peopleTab === 'ResearchScholars' ? 'show active' : ''} fade`} id="nav-ResearchScholars" role="tabpanel"
                      aria-labelledby="nav-ResearchScholars-tab">

                      <div class="row phd-std-row">

                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Yogesh_Patil_2023-11-1-13-9-56.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Advanced Oxidation Processes(AOPs) for wastewater treatment </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">Yogesh Patil </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 8668501914 <br /> <span> <a href="mailto:yp712123@student.nitw.ac.in ">yp712123@student.nitw.ac.in </a> </span></p>


                          </div>
                        </div>


                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Indranil_Dey_2023-11-1-16-42-44.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Control strategy for wastewater treatment plant  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">Indranil Dey </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 8670533812 <br /> <span> <a href="mailto:indracool.1990@gmail.com">indracool.1990@gmail.com</a></span> </p>


                          </div>
                        </div>






                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/K_Sunil_Kumar_2023-11-1-16-44-34.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Studies on Stage separation system  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">K Sunil Kumar  </h6>
                            <span>Part-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 9492924910 <br /> <span> <a href="mailto:ksunildrdo@gmail.com">ksunildrdo@gmail.com</a></span> </p>


                          </div>
                        </div>




                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Irshad_2023-11-1-16-46-27.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Energy storage systems  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">Irshad </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 7207219424 <br /> <span> <a href="mailto:irshad.ash.khan@gmail.com">irshad.ash.khan@gmail.com</a> </span></p>


                          </div>
                        </div>

                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Upare_Vishal_Baburao_2023-11-1-16-47-39.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Study of Nanostructured Catalyst for Carbon Monoxide Oxidation Reaction</b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">Upare Vishal Baburao </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 9623235854 <br /><span>  <a href="mailto:uv721082@student.nitw.ac.in">uv721082@student.nitw.ac.in</a> </span></p>


                          </div>
                        </div>


                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Snigdha_Saha_2023-11-1-16-49-4.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Vanadium Redox Flow Batteries </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">Snigdha Saha  </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 8319245176 <br /> <span> <a href="mailto:snigdhasaha1023@gmail.com">snigdhasaha1023@gmail.com</a> </span></p>


                          </div>
                        </div>




                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mullapudi_Siva_2023-11-1-16-50-17.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Identification and Control strategies to improve performance of PEM Fuelcell System </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">Mullapudi Siva</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 7730826079 <br /> <span> <a href="mailto:ms3456phd@student.nitw.ac.in">ms3456phd@student.nitw.ac.in</a> </span></p>


                          </div>
                        </div>




                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/V_Tarun_2023-11-1-16-51-36.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Advanced composites </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">V Tarun </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 8897340165 <br /> <span> <a href="mailto:tarun_jrf0623@nitw.ac.in">tarun_jrf0623@nitw.ac.in</a> </span></p>


                          </div>
                        </div>



                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Rayavarapu_Appala_Naidu_2023-11-1-16-52-46.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Molecular simulation of soft and hard particles  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">Rayavarapu Appala Naidu</h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 8247881158 <br /> <span> <a href="mailto:ra22chr1r01@student.nitw.ac.in">ra22chr1r01@student.nitw.ac.in</a> </span></p>


                          </div>
                        </div>






                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Rahul_B_Gaikwad_2023-11-1-16-54-6.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Identification of process using relay feedback approach  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">Rahul B Gaikwad </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 7095621899 <br /> <span> <a href="mailto:grahul6@student.nitw.ac.in">grahul6@student.nitw.ac.in</a> </span></p>


                          </div>
                        </div>


                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Sivaprakash_S_2023-11-1-16-55-19.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Development of Electrically conductive adhesives  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">Sivaprakash S </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 9940733520 <br /> <span> <a href="mailto:ss21chrer06@student.nitw.ac.in">ss21chrer06@student.nitw.ac.in</a> </span></p>


                          </div>
                        </div>






                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/A_Adithya_2023-11-1-16-57-31.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Process Control  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">A Adithya </h6>
                            <span>Part-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 9704112891 <br /> <span> <a href="mailto:aadithya.nitw@gmail.com">aadithya.nitw@gmail.com</a> </span></p>


                          </div>
                        </div>




                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Kiran_Donthula_2023-11-1-16-58-54.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Energy Storage and Conversion  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">Kiran Donthula </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 9032145552 <br /> <span> <a href="mailto:kirandonthulaa@gmail.com">kirandonthulaa@gmail.com</a> </span></p>


                          </div>
                        </div>



                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Malkapuram_Surya_Teja_2023-11-1-17-0-9.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Wastewater Treatment </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">Malkapuram Surya Teja </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 6301254117 <br /> <span> <a href="mailto:ms720069@student.nitw.ac.in">ms720069@student.nitw.ac.in</a> </span></p>


                          </div>
                        </div>


                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Geddam_Rajesh_2023-11-1-17-1-30.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Modelling and simulation of waste water treatment plants </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">Geddam Rajesh </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 9000637850 <br /> <span> <a href="mailto:gr21chrer04@student.nitw.ac.in">gr21chrer04@student.nitw.ac.in</a> </span></p>


                          </div>
                        </div>


                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/B._Dinesh_2023-11-1-17-3-5.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Energy storage (Supercapacitors)  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">B. Dinesh </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 7386043755 <br /> <span> <a href="mailto:bd720072@student.nitw.ac.in">bd720072@student.nitw.ac.in</a> </span></p>


                          </div>
                        </div>




                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/D_Pandarinath_2023-11-1-17-4-37.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Fire hazard analysis of pyrophoric materials  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">D Pandarinath </h6>
                            <span>Part-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 8106560506 <br /> <span> <a href="mailto:dp22chr1p01@student.nitw.ac.in">dp22chr1p01@student.nitw.ac.in</a> </span></p>


                          </div>
                        </div>




                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Vaishnavi_Srinivasagopal_2023-11-1-17-5-58.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Biochemistry and wastewater treatment  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">Vaishnavi Srinivasagopal </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 9944104531 <br /> <span> <a href="mailto:vs23chr1r02@student.nitw.ac.in ">vs23chr1r02@student.nitw.ac.in </a> </span></p>


                          </div>
                        </div>





                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Thota_Kamala_2023-11-1-17-6-36.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Oil/water separation using nanofibers  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title">Thota Kamala  </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 9550802244<br /> <span> <a href="mailto:tk720068@student.nitw.ac.in ">tk720068@student.nitw.ac.in </a> </span></p>


                          </div>
                        </div>


                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Amala_J_2023-11-1-17-7-56.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Catalysts  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> Amala J  </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 9496955280<br /> <span> <a href="mailto:aj22chr2r01@student.nitw.ac.in">aj22chr2r01@student.nitw.ac.in </a> </span></p>


                          </div>
                        </div>



                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Bidesh_Kirtania_2023-11-1-17-9-6.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Energy and Environment  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> Bidesh Kirtania </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 8331842340<br /> <span> <a href="mailto:bideshk@student.nitw.ac.in">bideshk@student.nitw.ac.in </a> </span></p>


                          </div>
                        </div>





                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Bhagya_Lakshmi_Chinnam_2023-11-1-17-10-27.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Waste water treatment  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> Bhagya Lakshmi Chinnam </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 9177078029<br /> <span> <a href="mailto:BC712121@student.nitw.ac.in">BC712121@student.nitw.ac.in </a> </span></p>


                          </div>
                        </div>



                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Mrs._Suvarna_P._Dhongade_2023-11-1-17-11-48.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Waste water treatment  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> Mrs. Suvarna P. Dhongade </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 9960520092<br /> <span> <a href="mailto:sp22chr1q01@student.nitw.ac.in">sp22chr1q01@student.nitw.ac.in </a> </span></p>


                          </div>
                        </div>


                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Zade_Anita_Dnyanba_2023-11-1-17-12-56.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Polymer Composites </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> Zade Anita Dnyanba </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 9834510255<br /> <span> <a href="mailto:azade@student.nitw.ac.in">azade@student.nitw.ac.in </a> </span></p>


                          </div>
                        </div>





                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Irshad_2023-11-1-17-15-15.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Energy storage systems  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> Irshad </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 7207219424<br /> <span> <a href="mailto:irshad@student.nitw.ac.in">irshad@student.nitw.ac.in </a> </span></p>


                          </div>
                        </div>


                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Priyanka_Anil_Jagnade_2023-11-1-17-16-46.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Design and Development of Al-ion Hybrid Capacitor </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> Priyanka Anil Jagnade </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 9552313143<br /> <span> <a href="mailto:jp23chr1r06@student.nitw.ac.in ">jp23chr1r06@student.nitw.ac.in  </a> </span></p>


                          </div>
                        </div>



                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Shabnam_Siddiqui_2023-11-1-17-18-9.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>3D Printing and Waste Water Treatment  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> Shabnam Siddiqui </h6>
                            <span>Part-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 8095749186<br /> <span> <a href="mailto:shabnamsiddiqui.che@bmsce.ac.in ">shabnamsiddiqui.che@bmsce.ac.in  </a> </span></p>


                          </div>
                        </div>


                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/P_Dilipkumar_2023-11-1-17-23-19.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Colloidal solution </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> P Dilipkumar  </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 8885858259<br /> <span> <a href="mailto:dilipkmr834@gmail.com  ">dilipkmr834@gmail.com   </a> </span></p>


                          </div>
                        </div>



                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Easam_Ramkishore_2023-11-1-17-25-3.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Photocatalyis  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> Easam Ramkishore  </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 8332993163<br /> <span> <a href="mailto:kishore.dhora@gmail.com">kishore.dhora@gmail.com </a> </span></p>


                          </div>
                        </div>





                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Hanumanthu_Jenne_2023-11-1-17-26-8.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Process control  </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> Hanumanthu Jenne  </h6>
                            <span>Part-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 7013570933<br /> <span> <a href="hj712127@student.nitw.ac.in">hj712127@student.nitw.ac.in </a> </span></p>


                          </div>
                        </div>



                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ravindar_Nunavath_2023-11-1-17-27-46.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Industrial separation   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> Ravindar Nunavath</h6>
                            <span>Part-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 9436543811<br /> <span> <a href="rn23chr1p01@student.nitw.ac.in">rn23chr1p01@student.nitw.ac.in </a> </span></p>


                          </div>
                        </div>




                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Ragamaye_Tigiripalli_2023-11-1-17-29-16.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Chemical engineering   </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> Ragamaye Tigiripalli </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 8341929916<br /> <span> <a href="mailto:rtigiripalli@student.nitw.ac.in ">rtigiripalli@student.nitw.ac.in </a> </span></p>


                          </div>
                        </div>




                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Dharavath_Narendhar_2023-11-1-17-30-32.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Pharmaceutics </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> Dharavath Narendhar </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 7780264947<br /> <span> <a href="mailto:dnaren22@gmail.com">dnaren22@gmail.com </a> </span></p>


                          </div>
                        </div>




                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/Dubashi_Bharath_2023-11-1-17-31-41.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>Pharmaceutics </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> Dubashi Bharath  </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 7893178965<br /> <span> <a href="mailto:Bharathdubashi@gmail.com">Bharathdubashi@gmail.com</a> </span></p>


                          </div>
                        </div>



                        <div class="col-md-3 col-6 col-sm-6 mb-5 phd-std-wrap">
                          <div class="phd-std-member">
                            <div class="phd-std-img">
                              <img src="https://nitw.ac.in/api/static/files/A_Ajay_Raj_2023-11-1-17-32-43.jpg" class="img-fluid image" alt="" width="150" height="180" />
                              <div class="overlay">
                                <div class="std-details ">
                                  <p class="justified-text">
                                    Research Area : <b>SOFC electrolyte material </b>
                                  </p>


                                </div>
                              </div>
                            </div>
                            <h6 class="std-title"> A Ajay Raj  </h6>
                            <span>Full-Time Research Scholar</span>
                            <hr />

                            <p class="contact-info"> 9676953112<br /> <span> <a href="mailto:aa22chr1r04@student.nitw.ac.in">aa22chr1r04@student.nitw.ac.in</a> </span></p>

                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>







                {/*<div class="tab-pane ${activeTab === 'v-pills-' ? 'active' : ''} fade   " id="v-pills-NonTeachingStaff" role="tabpanel" aria-labelledby="v-pills-NonTeachingStaff-tab"><h4 class=" mb-4">Non-Teaching Staff</h4><table class="table table-sm "><thead><tr><th>Sl. No</th><th>Name of the Staff Member</th><th>Designation</th><th>Division</th></tr></thead><tbody><tr><td>1</td><td>Smt. K.S.S.S. Padmasri</td><td>Senior Technical Assistant</td></tr><tr><td>2</td><td>Smt. Shalini Avala</td><td>Technical Assistant</td></tr><tr><td>3</td><td>Sri . R Shivamallikarjun</td><td>Technical Assistant</td><td>CSE</td></tr><tr><td>4</td><td> Sri R. Jeevan kumar</td><td>Senior Technician</td></tr><tr><td>5</td><td>Sri. D. Ramesh</td><td>Senior Technician</td></tr><tr><td>6</td><td>Sri. B. Ramesh</td><td>Junior Assistant</td></tr><tr><td>7</td><td>K. Venkatamma</td><td> Technician (upgd)</td></tr><tr><td>8</td><td> K. Jayaraj</td><td>Caretaker SG-II</td></tr><tr><td>9</td><td>B. Sunil kumar</td><td>Electrician (Daily wage)</td></tr><tr><td>10</td><td>T. Varsha Sree</td><td>Skilled</td></tr><tr><td>11</td><td> M. Padma Rao</td><td>Skilled</td></tr><tr><td>12</td><td>P. Suma </td><td>Skilled</td></tr><tr><td>13</td><td>M. Mahendar</td><td>Un-Skilled</td></tr><tr><td>14</td><td>K. Srinivasulu</td><td>Un-Skilled</td><td>CSE</td></tr><tr><td>15</td><td>T. Sampath </td><td> Un-Skilled</td></tr></tbody></table></div>*/}
                <div class={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                  aria-labelledby="v-pills-Students-tab">
                  <div class="row std-society">
                    <div class={`tab-pane ${activeTab === 'v-pills-Students' ? 'show active' : 'alumniTabHide'} fade  `} id="v-pills-Students" role="tabpanel"
                      aria-labelledby="v-pills-Students-tab">
                      <div class="row std-society">
                        <div class="col-md-3 col-6">

                        </div>
                        <div class="col-md-3 col-6">

                        </div>

                        <p class="justified-text">


                          <img src="https://nitw.ac.in/api/static/files/download_lib_2022-8-6-11-3-20.png" alt="profile-image" class="profile" /><br></br>

                          Document Delivery Service through which any library member can send a request to the library for obtaining photocopies of journals articles which are not available in our central library. This service is provided free of charge to all library members of NIT Warangal for academic and research purpose only.
                        </p>
                        <br></br>
                        <p class="justified-text">
                          To avail this service, library users are requested to fill Google Form with full bibliographic details of the documents (not more than two articles at a time) <br></br>
                          <a href="https://forms.gle/r92Q3rcm31bzJsTW6"> https://forms.gle/r92Q3rcm31bzJsTW6</a>

                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class={`tab-panel ${activeTab === 'v-pills-FIS' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-FIS" role="tabpanel"
                  aria-labelledby="v-pills-FIS-tab">
                  <div class="row FIS">

                    {/* <h4 align='center'>Faculty Information System</h4><br></br><br></br> */}
                    <p class="justified-text">
                      The Central Library has implemented a new “Faculty Information System” under the Indian Research Information Network System (IRINS) to showcase the academic and research activities of the institute faculty members and provide an opportunity to create a scholarly research network.</p>
                    <br></br>
                    <p class="justified-text">
                      The NITW- Indian Research Information Network System (IRINS) will support the integration of the existing research management system such as HR system, course management, grant management system, institutional repository, Open and commercial citation databases, scholarly publishers, etc. This will be integrated with the academic identities such as ORCID ID, Scopus ID, Research ID, Microsoft Academic ID, and Google Scholar ID for ingesting the scholarly publication from various sources. This Faculty Information System can become the source for the NIRF, NAC and other international raking systems. The Faculty Information System can be accessed by everyone through the following link:
                    </p>

                    <p class="justified-text"><b>Website: </b><a href="https://nitw.irins.org/">https://nitw.irins.org/</a></p>



                  </div>
                </div>




                <div class={`tab-panel ${activeTab === 'v-pills-E-Books' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-E-Books" role="tabpanel"
                  aria-labelledby="v-pills-E-Books-tab">
                  <div class="row E-Books">

                    <p class="more"> <a class="more-info-link"
                      href="https://nitw.ac.in/api/static/files/New_E-Books_2023-1-3-12-9-10.pdf" target="_blank">New E-Books <i class="fa fa-angle-right"></i> </a> </p>


                  </div>
                </div>


                <div class={`tab-panel ${activeTab === 'v-pills-AYL' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-AYL" role="tabpanel"
                  aria-labelledby="v-pills-AYL-tab">
                  <div class="row AYL">
                    <p class="justified-text">
                      <img src="https://nitw.ac.in/api/static/files/Ask_a_Libraria_2022-7-22-10-26-13.png" alt="profile-image" class="profile" /><br></br>
                      Due to the Covid-19 pandemic, the Central library of NIT Warangal was close down for the public. However, we do not want the students and faculty suffer from getting any information or help from the library. Hence, the central library has created an online, real time ‘virtual reference service’ facility in the library webpage. ‘Ask a Librarian’ is a free online web-based service through email.

                    </p>
                  </div>
                </div>

                <div class={`tab-pane ${activeTab === 'v-pills-AcademicResearchGroup' ? 'show active' : ''} fade `} id="v-pills-AcademicResearchGroup" role="tabpanel"
                  aria-labelledby="v-pills-AcademicResearchGroup-tab">
                  <div class="row academic_group">
                    <div class="col-md-12">
                      <header>
                        <h4 align='center'>E-Resources provided through ONOS</h4>
                      </header>
                    </div>
                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <th style={{ fontSize: '18px' }}>Sl. No.</th>
                          <th style={{ fontSize: '18px' }}>Name of the Database / Publishers </th>
                          <th style={{ fontSize: '18px' }}>URL</th>
                        </tr>
                        <tr><td>1</td><td>AAAS- Science</td><td><a href="https://www.aaas.org/science-journals">https://www.aaas.org/science-journals</a></td></tr>
                        <tr><td>2</td><td>ACM Digital Library</td><td><a href="http://dl.acm.org/">http://dl.acm.org/</a></td></tr>
                        <tr><td>3</td><td>American Chemical Society Journals</td><td><a href="https://pubs.acs.org/">https://pubs.acs.org/</a></td></tr>
                        <tr><td>4</td><td>American Institute of Physics Journals</td><td><a href="https://pubs.aip.org/">https://pubs.aip.org/</a></td></tr>
                        <tr><td>5</td><td>American Mathematical Society Journals</td><td><a href="http://www.ams.org/journals">http://www.ams.org/journals</a></td></tr>
                        <tr><td>6</td><td>Annual Reviews Journals</td><td><a href="http://arjournals.annualreviews.org">http://arjournals.annualreviews.org</a></td></tr>
                        <tr><td>7</td><td>ASCE Journals Online</td><td><a href="http://ascelibrary.org/">http://ascelibrary.org/</a></td></tr>
                        <tr><td>8</td><td>ASME Journals Online</td><td><a href="http://asmedigitalcollection.asme.org/">http://asmedigitalcollection.asme.org/</a></td></tr>
                        <tr><td>9</td><td>Bentham Science Journals</td><td><a href="https://www.benthamscience.com/bypublication">https://www.benthamscience.com/bypublication</a></td></tr>
                        <tr><td>10</td><td>BMJ Journals</td><td><a href="https://journals.bmj.com/home">https://journals.bmj.com/home</a></td></tr>
                        <tr><td>11</td><td>Cambridge University Press Journals</td><td><a href="https://www.cambridge.org/core">https://www.cambridge.org/core</a></td></tr>
                        <tr><td>12</td><td>Elsevier ScienceDirect Journals</td><td><a href="http://www.sciencedirect.com/">http://www.sciencedirect.com/</a></td></tr>
                        <tr><td>13</td><td>Emerald Publishing Journals</td><td><a href="https://www.emerald.com/insight/">https://www.emerald.com/insight/</a></td></tr>
                        <tr><td>14</td><td>ICE Publishing Journals</td><td><a href="http://www.icevirtuallibrary.com">http://www.icevirtuallibrary.com</a></td></tr>
                        <tr><td>15</td><td>IEEE Journals</td><td><a href="http://ieeexplore.ieee.org/">http://ieeexplore.ieee.org/</a></td></tr>
                        <tr><td>16</td><td>IndianJournals.com</td><td><a href="http://indianjournals.com/">http://indianjournals.com/</a></td></tr>
                        <tr><td>17</td><td>Institute of Physics Journals</td><td><a href="http://iopscience.iop.org/journals">http://iopscience.iop.org/journals</a></td></tr>
                        <tr><td>18</td><td>Lippincott Williams & Wilkins (Wolters Kluwer) Journals</td><td><a href="https://oce.ovid.com/">https://oce.ovid.com/</a></td></tr>
                        <tr><td>19</td><td>Oxford University Press Journals</td><td><a href="https://academic.oup.com/journals/">https://academic.oup.com/journals/</a></td></tr>
                        <tr><td>20</td><td>Project Muse</td><td><a href="http://muse.jhu.edu/">http://muse.jhu.edu/</a></td></tr>
                        {/* <tr><td>20</td><td>Sage Publishing Journals</td><td><a href="http://journals.sagepub.com/">http://journals.sagepub.com/</a></td></tr> */}
                        <tr><td>21</td><td>Sage Publishing Journals</td><td><a href="http://journals.sagepub.com/">http://journals.sagepub.com/</a></td></tr>
                        <tr><td>22</td><td>SPIE Digital Library</td><td><a href="https://www.spiedigitallibrary.org/">https://www.spiedigitallibrary.org/</a></td></tr>
                        <tr><td>23</td><td>Springer Nature Journals</td><td><a href="http://link.springer.com/">http://link.springer.com/</a></td></tr>
                        <tr><td>24</td><td>Taylor and Francis Journals8</td><td><a href="https://www.tandfonline.com/">https://www.tandfonline.com/</a></td></tr>
                        <tr><td>25</td><td>Wiley Journals</td><td><a href="https://onlinelibrary.wiley.com/">https://onlinelibrary.wiley.com/</a></td></tr>


                      </tbody>
                    </table>


                    {/* eshodh */}
                    {/* <div class="col-md-12">
                      <header>
                        <h4 align='center'>E-Resources provided by e-SHODH SINDHU</h4>
                      </header>
                    </div> */}
                    {/* <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <th style={{ fontSize: '18px' }}>Sl. No.</th>
                          <th style={{ fontSize: '18px' }}>Name of the Database / Publishers </th>
                          <th style={{ fontSize: '18px' }}>URL</th>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>ACM Digital Library</td>
                          <td><a href="https://dl.acm.org/">https://dl.acm.org/</a></td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>ASCE Journal Online</td>
                          <td><a href="https://ascelibrary.org/">https://ascelibrary.org/</a></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>ASME Journals Online</td>
                          <td><a href="	https://asmedigitalcollection.asme.org/">https://asmedigitalcollection.asme.org/</a></td>
                        </tr> */}
                    {/* <tr>
                          <td>4</td>
                          <td>Economic & Political Weekly</td>
                          <td><a href="http://epw.in/">http://epw.in/</a></td>
                        </tr> */}
                    {/* <tr>
                          <td>4</td>
                          <td>Institute for Studies in Industrial Development (ISID) Database</td>
                          <td><a href="https://db.isid.org.in/">https://db.isid.org.in/</a></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>JGate Plus (JCCC)</td>
                          <td><a href="https://jgateplus.com/search/">https://jgateplus.com/search/</a></td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>JSTOR</td>
                          <td><a href="https://www.jstor.org/">https://www.jstor.org/</a></td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Oxford University Press</td>
                          <td><a href="https://academic.oup.com/journals">https://academic.oup.com/journals</a></td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>Web of Science Lease Access (ESS)</td>
                          <td><a href="https://www.webofscience.com/wos/">https://www.webofscience.com/wos/</a></td>
                        </tr>
                      </tbody>
                    </table> */}




                    <div class="col-md-12">
                      <header>
                        <h4 align='center'>E-Resources Subscribed NIT Warangal

                        </h4>
                      </header>
                    </div>
                    {/* <div class="col-md-6">
                                            <div class="card">
                                           
                                            </div>
                                        </div> */}



                    <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <th style={{ fontSize: '18px' }}>Sl. No.</th>
                          <th style={{ fontSize: '18px' }}>Name of the Database / Publishers</th>
                          <th style={{ fontSize: '18px' }}>URL</th>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Canadian Journal of Civil Engineering</td>
                          <td><a href="http://www.nrcresearchpress.com/journal/cjce">http://www.nrcresearchpress.com/journal/cjce</a></td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Canadian Geotechnical Journal</td>
                          <td><a href="http://www.nrcresearchpress.com/journal/cgj">http://www.nrcresearchpress.com/journal/cgj</a></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>International Journal of Computational Intelligence and Applications</td>
                          <td><a href="https://www.worldscientific.com/doi/10.1142/S1469026821500255">https://www.worldscientific.com/doi/10.1142/S1469026821500255</a></td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Journal of Mechanics in Medicine and Biology</td>
                          <td><a href="https://www.worldscientific.com/worldscinet/jmmb">https://www.worldscientific.com/worldscinet/jmmb</a></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>MathSciNet</td>
                          <td><a href="https://mathscinet.ams.org/mathscinet/index.html">https://mathscinet.ams.org/mathscinet/index.html</a></td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>Royal Society of Chemistry</td>
                          <td><a href="https://pubs.rsc.org/">https://pubs.rsc.org/</a></td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>SciFinder</td>
                          <td><a href="http://scifinder-n.cas.org/">http://scifinder-n.cas.org/</a></td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>SIAM Journal on Discrete Mathematics</td>
                          <td><a href="https://epubs.siam.org/journal/sidma/about">https://epubs.siam.org/journal/sidma/about</a></td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>SIAM Journal on Optimization</td>
                          <td><a href="https://epubs.siam.org/journal/siopt/about">https://epubs.siam.org/journal/siopt/about</a></td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>Scopus</td>
                          <td><a href="https://www.scopus.com/search/form.uri?display=basic#basic">https://www.scopus.com/search/form.uri?display=basic#basic</a></td>
                        </tr>

                      </tbody>
                    </table>





                    {/* <div class="col-md-6">
                                            <div class="card">
                                           
                                            </div>
                                        </div> */}



                    {/* <table className="table table-bordered">
                      <tbody>
                        <tr>
                          <th style={{ fontSize: '18px' }}>Sl. No.</th>
                          <th style={{ fontSize: '18px' }}>Resource Name</th>
                          <th style={{ fontSize: '18px' }}>URL</th>
                        </tr>
                        <tr>
                          <td>1</td>
                          <td>Advanced energy materials</td>
                          <td><a href="https://onlinelibrary.wiley.com/journal/16146840">https://onlinelibrary.wiley.com/journal/16146840</a></td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Advanced engineering materials</td>
                          <td><a href="https://onlinelibrary.wiley.com/journal/15272648">https://onlinelibrary.wiley.com/journal/15272648</a></td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Energy technology</td>
                          <td><a href="https://onlinelibrary.wiley.com/journal/21944296">	https://onlinelibrary.wiley.com/journal/21944296</a></td>
                        </tr>
                        <tr>
                          <td>4</td>
                          <td>Fuel cells</td>
                          <td><a href="https://onlinelibrary.wiley.com/journal/16156854">https://onlinelibrary.wiley.com/journal/16156854</a></td>
                        </tr>
                        <tr>
                          <td>5</td>
                          <td>Hydrological processes</td>
                          <td><a href="	https://onlinelibrary.wiley.com/journal/10991085">	https://onlinelibrary.wiley.com/journal/10991085</a></td>
                        </tr>
                        <tr>
                          <td>6</td>
                          <td>International journal for numerical and analytical methods in geomechanics</td>
                          <td><a href="https://onlinelibrary.wiley.com/journal/10969853">https://onlinelibrary.wiley.com/journal/10969853</a></td>
                        </tr>
                        <tr>
                          <td>7</td>
                          <td>Water resources research</td>
                          <td><a href="https://agupubs.onlinelibrary.wiley.com/journal/19447973">https://agupubs.onlinelibrary.wiley.com/journal/19447973</a></td>
                        </tr>
                        <tr>
                          <td>8</td>
                          <td>International journal of applied ceramic technology</td>
                          <td><a href="https://onlinelibrary.wiley.com/journal/17447402">https://onlinelibrary.wiley.com/journal/17447402</a></td>
                        </tr>
                        <tr>
                          <td>9</td>
                          <td>International journal of applied glass science</td>
                          <td><a href="https://onlinelibrary.wiley.com/journal/20411294">https://onlinelibrary.wiley.com/journal/20411294</a></td>
                        </tr>
                        <tr>
                          <td>10</td>
                          <td>Journal of the american ceramic society</td>
                          <td><a href="https://onlinelibrary.wiley.com/journal/15512916">https://onlinelibrary.wiley.com/journal/15512916</a></td>
                        </tr>
                        <tr>
                          <td>11</td>
                          <td>Quarterly journal of the royal meteorological society</td>
                          <td><a href="https://rmets.onlinelibrary.wiley.com/journal/1477870X">https://rmets.onlinelibrary.wiley.com/journal/1477870X</a></td>
                        </tr>
                        <tr>
                          <td>12</td>
                          <td>2D Materials </td>
                          <td><a href="https://iopscience.iop.org/journal/2053-1583">https://iopscience.iop.org/journal/2053-1583</a></td>
                        </tr>
                        <tr>
                          <td>13</td>
                          <td>Journal of Physics D: Applied Physics</td>
                          <td><a href="https://iopscience.iop.org/journal/0022-3727">https://iopscience.iop.org/journal/0022-3727</a></td>
                        </tr>
                        <tr>
                          <td>14</td>
                          <td>Fluid Dynamics Research</td>
                          <td><a href="https://iopscience.iop.org/journal/1873-7005">https://iopscience.iop.org/journal/1873-7005</a></td>
                        </tr>
                        <tr>
                          <td>15</td>
                          <td>International Journal of Computational Intelligence and Applications</td>
                          <td><a href="https://www.worldscientific.com/doi/10.1142/S1469026821500255">https://www.worldscientific.com/doi/10.1142/S1469026821500255</a></td>
                        </tr>
                        <tr>
                          <td>16</td>
                          <td>Journal of Mechanics in Medicine and Biology</td>
                          <td><a href="https://www.worldscientific.com/worldscinet/jmmb">https://www.worldscientific.com/worldscinet/jmmb</a></td>
                        </tr>
                        <tr>
                          <td>17</td>
                          <td>Biomicrofluidics</td>
                          <td><a href="https://aip.scitation.org/journal/bmf">https://aip.scitation.org/journal/bmf</a></td>
                        </tr>
                        <tr>
                          <td>18</td>
                          <td>Physics of Fluids</td>
                          <td><a href="https://aip.scitation.org/journal/phf">https://aip.scitation.org/journal/phf</a></td>
                        </tr>

                        <tr>
                          <td>19</td>
                          <td>SIAM Journal on Discrete Mathematics</td>
                          <td><a href="https://epubs.siam.org/journal/sidma/about">https://epubs.siam.org/journal/sidma/about</a></td>
                        </tr>
                        <tr>
                          <td>20</td>
                          <td>SIAM Journal on Optimization</td>
                          <td><a href="https://epubs.siam.org/journal/siopt/about">https://epubs.siam.org/journal/siopt/about</a></td>
                        </tr>
                        <tr>
                          <td>21</td>
                          <td>MathSciNet</td>
                          <td><a href="https://mathscinet.ams.org/mathscinet/index.html">https://mathscinet.ams.org/mathscinet/index.html</a></td>
                        </tr>
                        <tr>
                          <td>22</td>
                          <td>Ground Improvement</td>
                          <td><a href="https://www.icevirtuallibrary.com/toc/jgrim/current">https://www.icevirtuallibrary.com/toc/jgrim/current</a></td>
                        </tr>
                        <tr>
                          <td>23</td>
                          <td>Geotechnique Letters</td>
                          <td><a href="	http://www.icevirtuallibrary.com/content/serial/geolett">http://www.icevirtuallibrary.com/content/serial/geolett</a></td>
                        </tr>
                        <tr>
                          <td>24</td>
                          <td>Canadian Journal of Civil Engineering</td>
                          <td><a href="http://www.nrcresearchpress.com/journal/cjce">http://www.nrcresearchpress.com/journal/cjce</a></td>
                        </tr>
                        <tr>
                          <td>25</td>
                          <td>Canadian Geotechnical Journal</td>
                          <td><a href="http://www.nrcresearchpress.com/journal/cgj">http://www.nrcresearchpress.com/journal/cgj</a></td>
                        </tr>
                        <tr>
                          <td>26</td>
                          <td>Transactions of the Indian National Academy of Engineering </td>
                          <td><a href="https://link.springer.com/journal/41403">https://link.springer.com/journal/41403</a></td>
                        </tr>
                        <tr>
                          <td>27</td>
                          <td>TESOL Quarterly </td>
                          <td><a href="https://onlinelibrary.wiley.com/journal/15457249">https://onlinelibrary.wiley.com/journal/15457249</a></td>
                        </tr>
                      </tbody>
                    </table> */}



                  </div>


                </div>



                <div class={`tab-pane ${peopleTab === 'AdjunctFaculty' ? 'show active' : ''} fade`} id="nav-AdjunctFaculty" role="tabpanel"
                  aria-labelledby="nav-AdjunctFaculty-tab">

                  <div class="col-md-12 profile-info-individual">
                    <div class="row">

                      <div class="col-md-9">
                        <div class="card">
                          <div class="d-flex">
                            <div class=""> <img src="https://nitw.ac.in/api/static/files/Prof._Tata_Narasinga_Rao_2023-10-20-18-32-20.png" alt=""
                              class="" width="150" height="180" /> </div>
                            <div class="ml-3 w-100">
                              <h6 class="mb-0 mt-0"> Prof. Tata Narasinga Rao </h6>

                              <span>Director, International Advanced Research Centre for Powder Metallurgy & New Materials (ARCI), Hyderabad</span>
                              <div class=" mt-2">
                                <p class="contact-info">  <i
                                  class="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:tata@arci.res.in">director@arci.res.in, tata@arci.res.in</a> </p>
                                <p class="justified-text">Nanotechnology, Materials science, Energy materials, Li ion Batteries</p>

                                <p class="justified-text"><b> Website: </b> <a href="https://www.arci.res.in/people-pages/tn-rao-cv.pdf">https://www.arci.res.in/people-pages/tn-rao-cv.pdf</a></p>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>



                      <div class="col-md-9">
                        <div class="card">
                          <div class="d-flex">
                            <div class=""> <img src="https://nitw.ac.in/api/static/files/Prof._Pisupati_V_Sharma_2023-10-20-18-32-39.png" alt=""
                              class="img-fluid" width="150" height="180" /> </div>
                            <div class="ml-3 w-100">
                              <h6 class="mb-0 mt-0"> Prof. Pisupati V Sharma </h6>

                              <span>Professor of Energy and Mineral Engineering, and Chemical Engineering, Director, Center for Critical Minerals,
                                The Pennsylvania State University, USA</span>
                              <div class=" mt-2">
                                <p class="contact-info">  <i
                                  class="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:sxp17@psu.edu">sxp17@psu.edu</a> </p>
                                <p class="justified-text">Chemical kinetics, mass, and heat transfer in combustion processes; CFD modeling of combustion and air pollution systems; oxy-fuel combustion; coal processing and mineral matter transformations in gasification; coal, biomass, and petroleum coke gasification and recovery of rare earth and critical elements from secondary sources and industrial wastes</p>

                                <p class="justified-text"><b>Website: </b><a href="https://www.eme.psu.edu/directory/sarma-v-pisupati">https://www.eme.psu.edu/directory/sarma-v-pisupati</a></p>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>






                      <div class="col-md-9">
                        <div class="card">
                          <div class="d-flex">
                            <div class=""> <img src="https://nitw.ac.in/api/static/files/Prof._Ajay_K_Dalai_2023-10-20-18-32-55.png" alt=""
                              class="img-fluid" width="150" height="180" /> </div>
                            <div class="ml-3 w-100">
                              <h6 class="mb-0 mt-0"> Prof. Ajay K Dalai </h6>

                              <span>Distinguished Professor and Canada Research Chair in Bioenergy & Environmentally Friendly Chemical Processing, Department of Chemical and Biological Engineering
                                College of Engineering, University of Saskatchewan, SK Canada</span>
                              <div class=" mt-2">
                                <p class="contact-info">  <i
                                  class="fa fa-envelope" aria-hidden="true"></i> <a
                                    href="mailto:ajay.dalai@usask.ca">ajay.dalai@usask.ca</a> </p>
                                <p class="justified-text">Nanocatalysis, chemical reaction engineering, bio and petro-fuels, renewable energy, hydroprocessing, gas-to-liquid and environmental technologies</p>

                                <p class="justified-text"><b>Website:</b><a href="https://sens.usask.ca/people/faculty/associate-faculty/ajay-dalai.php">https://sens.usask.ca/people/faculty/associate-faculty/ajay-dalai.php</a></p>


                              </div>
                            </div>
                          </div>
                        </div>
                      </div>








                    </div>
                  </div>


                </div>



                <div class={`tab-panel ${activeTab === 'v-pills-NotableAlumni' ? 'show active' : 'alumniTabHide'} fade `} id="v-pills-NotableAlumni" role="tabpanel"
                  aria-labelledby="v-pills-NotableAlumni-tab">

                  {/* <h3>Web OPAC </h3> */}
                  <div class="row notable-alumni">

                    <p class="more"> <a class="more-info-link"
                      href="http://opac.library.nitw.ac.in:8081/" target="_blank"> Web OPAC <i class="fa fa-angle-right"></i> </a> </p>


                  </div>
                </div>



                <div class={`tab-pane ${activeTab === 'v-pills-Awards' ? 'show active' : ''} fade  `} id="v-pills-Awards" role="tabpanel"
                  aria-labelledby="v-pills-Awards-tab">
                  <h4 align='center'>Library Policies</h4><br></br><br></br>
                  <p class="mb-4" >
                    <center>
                      <a href="https://nitw.ac.in/api/static/files/LibraryPolicy_Final_2025-3-21-17-14-52.pdf" class="btn btn-info" role="button">Books and Journals Acquisition Policy</a>
                      <br></br>
                      <a href="https://nitw.ac.in/api/static/files/NITW_Institutional_Repository_Policy_Guidelines_2024-4-29-17-2-0.pdf" class="btn btn-info" role="button">NITW Institutional Repository Policy Guidelines</a>
                    </center>
                  </p>


                </div>

                <div class={"tab-pane ${activeTab === 'v-pills-ResearchScholars' ? 'active' : ''} fade  "} id="v-pills-ResearchScholars" role="tabpanel"
                  aria-labelledby="v-pills-ResearchScholars-tab">

                </div>

                <div class={`tab-pane ${activeTab === 'v-pills-PhotoGallery' ? 'show active' : ''} fade  `} id="v-pills-PhotoGallery" role="tabpanel"
                  aria-labelledby="v-pills-PhotoGallery-tab">

                  <div class="details">




                    {/* <h5>General Rules</h5> */}
                    <p class="justified-text">
                      -Library is a place for study and research. Strict silence is to be observed.
                      <br></br>
                      -A user has to show identity card whenever he / she enters the Library.
                      <br></br>
                      -All the personal belongings should be deposited at the counter.
                      <br></br>
                      -The user has to pay Rs. 50/- towards loss of token of personal belongings counter.
                      <br></br>
                      -Users are requested to allow frisking of person at the Library exit gate while leaving the Library.
                      <br></br>
                      -Personal books, printed material, photocopy material are not allowed inside the Library.
                      <br></br>
                      -Outsiders are not allowed into the Library.
                      <br></br>
                      -Smoking is strictly prohibited.

                    </p>
                  </div>

                </div>

              </div>
            </div>
          </div>
        </section>
      </div>
    </div>

    </>
  );
}

export default LibraryDeptPage;