import React, { useState } from 'react';
import PageHeader from '../components/PageHeader';
import '../css/deansw.css'; // Update the CSS file name


export const DeanMHW = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [activeTab, setActiveTab] = useState('deans');

  const deans = [
    {
      Name: "Chairman",
      Designation: " Prof. G. Amba Prasad Rao",
      phoneNumber: " 8332969315",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/me-gap",
      link: "https://erp.nitw.ac.in/ext/profile/me-gap",
      email: " cmhwelbeing@nitw.ac.in",
    },

    // ... other deans
  ];

  const associateDeans = [
    {
      Name: "Associate Dean ",
      Designation: " Prof. Sailaja Kumari M",
      phoneNumber: " -",
      image: "https://wsdc.nitw.ac.in/facultynew/media/16234/16234.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ee-sailaja",
      email: "  sailaja@nitw.ac.in  ",
    },

    // ... add more items ...
    // ... other associate deans
  ];

  const researchAdvisoryCommittee = [




    {
      Name: "Department of Civil Engineering",
      Designation: " Prof. Chinthala Sumanth",
      phoneNumber: " 9278275215",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/ce-sumanthchinthala",
      link: "https://erp.nitw.ac.in/ext/profile/ce-sumanthchinthala",
      email: "  sumanthchinthala@nitw.ac.in  ",
    },

    {
      Name: "Department of Electrical Engineering ",
      Designation: " Prof. A.V Giridhar",
      phoneNumber: " 9000742219",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/ee-giridhar",
      link: "https://erp.nitw.ac.in/ext/profile/ee-giridhar",
      email: "  sgiridhar@nitw.ac.in  ",
    },

    {
      Name: "Department of Mechanical Engineering ",
      Designation: " Prof. Gaurav Kumar Sharma",
      phoneNumber: " 9611241015",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/me-gaurav",
      link: "https://erp.nitw.ac.in/ext/profile/me-gaurav",
      email: "   gaurav@nitw.ac.in   ",
    },
    // {
    //   Name: "Department of Mechanical Engineering ",
    //   Designation: " Prof. Ravi Kumar Peetala",
    //   phoneNumber: " 9884889299",
    //   image: "https://nitw.ac.in/api/static/files/Prof._Ravi_Kumar_Peetala_2024-3-1-13-2-41.jpg",
    //   link: "https://erp.nitw.ac.in/ext/profile/me-ravipeetala",
    //   email:"   ravipeetala@nitw.ac.in   ",
    // },



    {
      Name: "Department of Electronics and Communication Engineering ",
      Designation: " Prof. Gopi Ram",
      phoneNumber: " 9679983382",
      image: "https://nitw.ac.in/api/static/files/Prof._Gopi_Ram_2024-3-1-13-7-10.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ec-gopi.ram",
      email: "  gopi.ram@nitw.ac.in   ",
    },


    {
      Name: "Department of Metallurgical and Materials Engineering ",
      Designation: " Prof. R. Arockia Kumar",
      phoneNumber: " 8332969479",
      image: "https://nitw.ac.in/api/static/files/Prof._R._Arockia_Kumar_2024-3-1-13-3-58.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/mm-arockia",
      email: "  arockia@nitw.ac.in   ",
    },


    // {
    //   Name: "Department of Chemical Engineering ",
    //   Designation: " Prof. Puttapati Sampath Kumar",
    //   phoneNumber: " 8497968889",
    //   image: "https://erp.nitw.ac.in/ext/profile/getUserImage/ch-pskr",
    //   link: "https://erp.nitw.ac.in/ext/profile/ch-pskr",
    //   email:"  pskr@nitw.ac.in   ",
    // },
    {
      Name: "Department of Chemical Engineering ",
      Designation: " Prof. K S Raj Mohan",
      phoneNumber: " 9994646610",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/ch-rajmohan",
      link: "https://erp.nitw.ac.in/ext/profile/ch-rajmohan",
      email: "  rajmohan@nitw.ac.in   ",
    },


    {
      Name: "Department of Computer Science and Engineering",
      Designation: " Prof. Priyanka Chawla",
      phoneNumber: " 7982750652",
      image: "https://nitw.ac.in/api/static/files/Prof._Priyanka_Chawla_2024-3-1-13-0-18.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/cs-priyankac",
      email: "  priyankac@nitw.ac.in   ",
    },



    {
      Name: "Department of Biotechnology ",
      Designation: " Prof. Surajbhan Sevda",
      phoneNumber: " 9929565697",
      image: "https://wsdc.nitw.ac.in/facultynew/media/17052/17052.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/bt-sevdasuraj",
      email: "  sevdasuraj@nitw.ac.in    ",
    },


    {
      Name: "Department of Mathematics",
      Designation: " Prof. J Pranitha",
      phoneNumber: " 9885146885",
      image: "https://nitw.ac.in/api/static/files/Prof._J_Pranitha_2024-3-1-13-5-26.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/ma-pranitha",
      email: "  pranitha@nitw.ac.in    ",
    },


    {
      Name: "Department of Physics",
      Designation: " Prof. Sourabh Roy",
      phoneNumber: " 8332969474",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/ph-sroy",
      link: "https://erp.nitw.ac.in/ext/profile/ph-sroy",
      email: "  sroy@nitw.ac.in   ",
    },


    {
      Name: "Department of Chemistry",
      Designation: " Prof. Srinivas Basavoju",
      phoneNumber: " 9703351571",
      image: "https://nitw.ac.in/api/static/files/Prof._Srinivas_Basavoju_2024-3-1-12-57-54.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/cy-basavojusrinivas",
      email: "   basavojusrinivas@nitw.ac.in    ",
    },


    {
      Name: "Department of Management Studies",
      Designation: " Prof. Rahul Thangeda",
      phoneNumber: " 8978264848",
      image: "https://erp.nitw.ac.in/ext/profile/getUserImage/sm-rahult",
      link: "https://erp.nitw.ac.in/ext/profile/sm-rahult",
      email: "    rahult@nitw.ac.in    ",
    },


    {
      Name: "Department of Humanities and Social Sciences",
      Designation: " Prof. M Raja Vishwanathan",
      phoneNumber: " 9908858942",
      image: "https://nitw.ac.in/api/static/files/Prof._M_Raja_Vishwanathan_2024-3-1-13-8-28.jpg",
      link: "https://erp.nitw.ac.in/ext/profile/hs-vishwanathan",
      email: "   vishwanathan@nitw.ac.in   ",
    },



    // ... more RAC members
  ];
  const officeStaff = [

    // {
    //   Name: " Asst. S.G-II ",
    //   person1: " R. Srinivas ",
    //   image: "https://nitw.ac.in/api/static/files/R.SRINIVAS_2023-7-31-11-54-9.jpg",
    //   link: "#",
    // },



  ];

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    setHoveredIndex(null);
  };

  const handleHover = (index) => {
    // setHoveredIndex(index);
  };

  const handleHoverEnd = () => {
    // setHoveredIndex(null);
  };

  const activeData =
    activeTab === 'deans'
      ? deans
      : activeTab === 'associateDeans'
        ? associateDeans
        : activeTab === 'researchAdvisoryCommittee'
          ? researchAdvisoryCommittee
          : officeStaff;
  const facilitiesPerColumn = Math.ceil(activeData.length / 2);

  return (
    <div className="page text-left font-change-applicable static-facilities">
      <PageHeader dept="/DeanMHW" />
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div className="tabs">
          <button
            className={`tab ${activeTab === 'deans' ? 'active' : ''}`}
            onClick={() => handleTabClick('deans')}
          >
            Head
          </button>
          {/* <button
            className={`tab ${activeTab === 'associateDeans' ? 'active' : ''}`}
            onClick={() => handleTabClick('associateDeans')}
          >
            Associate Dean
          </button> */}
          <button
            className={`tab ${activeTab === 'researchAdvisoryCommittee' ? 'active' : ''}`}
            onClick={() => handleTabClick('researchAdvisoryCommittee')}
          >
            Faculty Advisers
          </button>
          <button
            className={`tab ${activeTab === 'officeStaff' ? 'active' : ''}`}
            onClick={() => handleTabClick('officeStaff')}
          >
            Activites
          </button>
        </div>
        <div className="columns-wrapper row">
          {activeData.map((person, index) => (
            <div className={`column col-md-6 mb-6 ${hoveredIndex === index ? 'hovered' : ''}`} key={index}>
              <div
                className="custom-circle-image"
                onMouseEnter={() => handleHover(index)}
                onMouseLeave={handleHoverEnd}
              >


                <a href={person.link} target="_blank" rel="noopener noreferrer">
                  <img src={person.image} alt={person.Name} />
                </a>
              </div>


              <div className={`custom-details-cell ${hoveredIndex === index ? 'hovered' : ''}`}>
                <h2>{person.Name}</h2>
                <div className="details-wrapper">
                  {person.Designation && (
                    <div className="details-row">
                      <span className="details-label">Name: </span>
                      <span className="details-value">{person.Designation}</span>
                    </div>
                  )}
                  {person.phoneNumber && (
                    <div className="details-row">
                      <span className="details-label">Phone: </span>
                      <span className="details-value">{person.phoneNumber}</span>
                    </div>
                  )}
                  {person.email && (
                    <div className="details-row">
                      <span className="details-label">Email: </span>
                      <span className="details-value">{person.email}</span>
                    </div>
                  )}
                  {person.department && (
                    <div className="details-row">
                      <span className="details-label">Department: </span>
                      <span className="details-value">{person.department}</span>
                    </div>
                  )}
                  {person.institute && (
                    <div className="details-row">
                      <span className="details-label">Institute: </span>
                      <span className="details-value">{person.institute}</span>
                    </div>
                  )}
                  {person.person1 && (
                    <div className="details-row">
                      <span className="details-label">  </span>
                      <span className="details-value">{person.person2}</span>
                    </div>
                  )}
                  {person.person1 && (
                    <div className="details-row">
                      <span className="details-label">  </span>
                      <span className="details-value">{person.person1}</span>
                    </div>
                  )}

                </div>
              </div>
            </div>
          ))}






          {/* Add the new section under "Computer Centre" */}
          {activeTab === 'researchAdvisoryCommittee' && (
            <div className="computer-centre-section">

              {/* ... (content for "Powers of Board" under Computer Centre) */}
            </div>
          )}


          {/* ...remaining code... */}





          {/* Add the new section under "Computer Centre" */}
          {activeTab === 'officeStaff' && (
            <div className="computer-centre-section">
               <h4 align='left'>First National Mental Wellbeing was held at IITHyderabad from 9-10 November, 2024.</h4>
              {/* ... (content for "Powers of Board" under Computer Centre) */}

              <img
                src="https://nitw.ac.in/api/static/files/mental_2024-12-20-18-42-9.jpg"
                alt="Image 1"
                style={{ width: '100%', height: '400px', maxWidth: '1100px' }} // Customize width and height
              />
              <h4 align='left'>A 2-day FDP is conducted on "Capacity Building for Enhanced Productivity" (23-25 August, 2024)</h4>
              {/* ... (content for "Powers of Board" under Computer Centre) */}

              <img
                src="https://nitw.ac.in/api/static/files/fdp_2024-9-2-12-4-44.jpg"
                alt="Image 1"
                style={{ width: '100%', height: '400px', maxWidth: '1100px' }} // Customize width and height
              />

              <h4 align='left'>Heartfulness-Kanha Shanti Vanam, Hyderabad</h4>
              {/* ... (content for "Powers of Board" under Computer Centre) */}

              <img
                src="https://nitw.ac.in/api/static/files/fdp1_2024-9-2-12-5-43.jpg"
                alt="Image 1"
                style={{ width: '100%', height: '400px', maxWidth: '1100px' }} // Customize width and height
              />

              <h4 align='left'>Dhyan Mahotsavam (9<sup>th</sup> Feb, 2024)</h4>
              {/* ... (content for "Powers of Board" under Computer Centre) */}

              <img
                src="https://nitw.ac.in/api/static/files/DHYAN_MAHOTSAVAM_2024-2-27-17-3-6.jpg"
                alt="Image 1"
                style={{ width: '100%', height: '400px', maxWidth: '1100px' }} // Customize width and height
              />

              




            </div>
          )}


          {/* ...remaining code... */}





        </div>
      </div>
    </div>


















  );
};

export default DeanMHW;