// import React, { useState } from 'react';
// import '../css/FacultyPositions.css'; // Import the new CSS file

// export const FacultyPositions = () => {
//   const [activeTab, setActiveTab] = useState('announcements'); // Set default tab

//   const announcements = [
//     // {
//     //   Title: "10 Jan 2024: Rolling Advertisement for Faculty Recruitment",
//     //   Content: "-",
//     //   Date: "-",
//     //   Link: "https://recruit.nitw.ac.in/",
//     // },
//     {
//       Title: "Previous Recruitment Details",
//       Content: "-",
//       Date: "-",
//       Link: "https://nitw.ac.in/page/?url=/jobrecruitment/FacultyRecruitments",
//     },
//     // Add more announcements
//   ];

//   const notices = [
//     // Add more notices
//     {
//         Title: "-",
//         Content: "-",
//         Date: "-",
//         Link: "-",
//       },
//   ];

//   const Applyonlineportal = [
//     {
//       Title: "Apply online portal",
//       Content: "-",
//       Date: "-",
//       Link: "https://recruit.nitw.ac.in/register?next=/",
//     },
//     // Add more Apply online portal items
//   ];

//   const renderTabContent = () => {
//     let tabContent = null;

//     if (activeTab === 'announcements') {
//       tabContent = announcements.map((announcement, index) => (
//         <li key={index}>
//           <h4>
//             <a href={announcement.Link} target="_blank" rel="noopener noreferrer">
//               {announcement.Title}
//             </a>
//           </h4>
//           <hr />
//         </li>
//       ));
//     } else if (activeTab === 'notices') {
//       tabContent = notices.map((notice, index) => (
//         <li key={index}>
//           <h4>
//             <a href={notice.Link} target="_blank" rel="noopener noreferrer">
//               {notice.Title}
//             </a>
//           </h4>
//           <hr />
//         </li>
//       ));
//     } else if (activeTab === 'applyOnline') {
//       tabContent = Applyonlineportal.map((item, index) => (
//         <li key={index}>
//           <h4>
//             <a href={item.Link} target="_blank" rel="noopener noreferrer">
//               {item.Title}
//             </a>
//           </h4>
//           <hr />
//         </li>
//       ));
//     }

//     return <ul>{tabContent}</ul>;
//   };

//   const handleTabClick = (tab) => {
//     if (tab === 'applyOnline') {
//       window.open("https://recruit.nitw.ac.in/", '_blank'); // Open the online portal link in a new tab
//     } else {
//       setActiveTab(tab);
//     }
//   };

//   return (
//     <div className="page text-left font-change-applicable static-facilities">
//       <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
//         <div>
//           <h3><b>Faculty Positions</b></h3>
//           <p style={{ textAlign: 'justify' }}>
//             NITW, an Institute of national importance in
//             teaching, research and development, invites application from Indian nationals,
//             possessing excellent academic background along with commitment to quality teaching and
//             potential for carrying out outstanding research for faculty position at the level of
//             Professor, Associate Professor, and Assistant Professor. NIT Warangal has been
//             instrumental for providing opportunity to every faculty in achieving academic excellence,
//             developing all-round personality and realizing his or her full potential.
//           </p>
//         </div>
//         <br />
//         <div className="tabs">
//           <button
//             className={`tab ${activeTab === 'announcements' ? 'active' : ''}`}
//             onClick={() => handleTabClick('announcements')}
//           >
//             Announcements
//           </button>
//           {/* <button
//             className={`tab ${activeTab === 'notices' ? 'active' : ''}`}
//             onClick={() => handleTabClick('notices')}
//           >
//             Notices
//           </button> */}
//           <button
//             className={`tab ${activeTab === 'applyOnline' ? 'active' : ''}`}
//             onClick={() => handleTabClick('applyOnline')}
//           >
//             Apply Online Portal
//           </button>
//         </div>
//         <br />
//         <div className="tab-content">
//           {renderTabContent()}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FacultyPositions;


import React, { useState } from 'react';
import '../css/FacultyPositions.css'; // Import the new CSS file

export const FacultyPositions = () => {
  const [activeTab, setActiveTab] = useState('notices'); // Set default tab

  const announcements = [
    {
      Title: "- Rolling Faculty Recruitment -Notification",
      Content: "-.",
      Date: "June 7, 2024",
      Link: "https://nitw.ac.in/api/static/files/Rolling_Faculty_Recruitment_-Notification_2024-6-7-10-12-41.pdf",
    },
    {
      Title: "- Instructions for Filling Online Application Form",
      Content: "-",
      Date: "June 7, 2024",
      Link: "https://nitw.ac.in/api/static/files/Instructions_for_Filling_OAF_2024-6-7-10-15-19.pdf",
    },
    {
      Title: "- Implementation of Recruitment Rules for Faculty of NITS and IIEST- issue of clarifications as per recommendations of the Oversight Committe",
      Content: "-",
      Date: "June 7, 2024",
      Link: "https://nitw.ac.in/api/static/files/R2024_clarification_2024-6-7-10-17-57.pdf", // You can provide a link if needed or use "#" for no link
    },
    {
      Title: "- Department-wise vacancies",
      Content: "-",
      Date: "June 7, 2024",
      Link: "https://nitw.ac.in/api/static/files/Department-wise_vacancies_2024-6-7-10-18-54.pdf", // You can provide a link if needed or use "#" for no link
    },
    {
      Title: "1. To candidates who have submitted applications: Please keep visiting the portal for further updates. However, no queries can be entertained regarding the further course of recruitment.",
      Content: "-",
      Date: "June 7, 2024",
      Link: "#", // You can provide a link if needed or use "#" for no link
    },
    {
      Title: "2. Inviting applications for Faculty positions will be on hold until further notice.",
      Content: "-",
      Date: "June 7, 2024",
      Link: "#", // You can provide a link if needed or use "#" for no link
    },
    // Add more notices if needed
    // Add more announcements
    {
      Title: "Previous Recruitment Details",
      Content: "-",
      Date: "-",
      Link: "https://nitw.ac.in/page/?url=/jobrecruitment/FacultyRecruitments",
    },
  ];

  const notices = [
    {
      Title: "- Rolling Faculty Recruitment -Notification",
      Content: "-.",
      Date: "June 7, 2024",
      Link: "https://nitw.ac.in/api/static/files/Rolling_Faculty_Recruitment_-Notification_2024-6-7-10-12-41.pdf",
    },
    {
      Title: "- Instructions for Filling Online Application Form",
      Content: "-",
      Date: "June 7, 2024",
      Link: "https://nitw.ac.in/api/static/files/Instructions_for_Filling_OAF_2024-6-7-10-15-19.pdf",
    },
    {
      Title: "- Implementation of Recruitment Rules for Faculty of NITS and IIEST- issue of clarifications as per recommendations of the Oversight Committe",
      Content: "-",
      Date: "June 7, 2024",
      Link: "https://nitw.ac.in/api/static/files/R2024_clarification_2024-6-7-10-17-57.pdf", // You can provide a link if needed or use "#" for no link
    },
    {
      Title: "- Department-wise vacancies",
      Content: "-",
      Date: "June 7, 2024",
      Link: "https://nitw.ac.in/api/static/files/Department-wise_vacancies_2024-6-7-10-18-54.pdf", // You can provide a link if needed or use "#" for no link
    },
    {
      Title: "1. To candidates who have submitted applications: Please keep visiting the portal for further updates. However, no queries can be entertained regarding the further course of recruitment.",
      Content: "-",
      Date: "June 7, 2024",
      Link: "#", // You can provide a link if needed or use "#" for no link
    },
    {
      Title: "2. Inviting applications for Faculty positions will be on hold until further notice.",
      Content: "-",
      Date: "June 7, 2024",
      Link: "#", // You can provide a link if needed or use "#" for no link
    },
    // Add more notices if needed
  ];


  const Applyonlineportal = [
    {
      Title: "Apply online portal",
      Content: "-",
      Date: "-",
      Link: "https://recruit.nitw.ac.in/register?next=/",
    },
    // Add more Apply online portal items
  ];

  const renderTabContent = () => {
    let tabContent = null;

    if (activeTab === 'announcements') {
      tabContent = (<div>

        <div className='container'>
          <a href='https://nitw.ac.in/page/?url=/jobrecruitment/FacultyRecruitments'> <button type="button" class="btn btn-primary btn-block">View Closed Advertisements</button></a>
        </div>
        <ol >
          <li>
            <h4>
              <a href="https://nitw.ac.in/api/static/files/Rolling_Faculty_Recruitment_-Notification_2024-6-7-10-12-41.pdf" target='_blank'>
                1. Rolling Faculty Recruitment -Notification

              </a>
            </h4>
          </li>
          <li>
            <h4>
              <a href="https://nitw.ac.in/api/static/files/Instructions_for_Filling_OAF_2024-6-7-10-15-19.pdf" target='_blank'>
                2. Instructions for Filling Online Application Form

              </a>
            </h4>
          </li>
          <li>
            <h4>
              <a href="https://nitw.ac.in/api/static/files/R2024_clarification_2024-6-7-10-17-57.pdf" target='_blank'>
                3. Implementation of Recruitment Rules for Faculty of NITS and IIEST- issue of clarifications as per recommendations of the Oversight Committe
              </a>
            </h4>
          </li>
          <li>
            <h4>
              <a href="https://nitw.ac.in/api/static/files/Department-wise_vacancies_2024-6-7-10-18-54.pdf" target='_blank'>
                4. Department-wise vacancies

              </a>
            </h4>
          </li>
          <li>
            <h4 >
              <a href="https://nitw.ac.in/api/static/files/Faculty_Selection-2024_2024-10-4-10-17-32.pdf" target='_blank'>
                5. 04-Oct-2024: Shortlisted candidates for Faculty Selection -Advt. No. NITW/Admn/T01/Rolling/2024/, dt. 10th January, 2024.

              </a>
            </h4>
          </li>
          <li>
            <h4 >
              <a href="https://nitw.ac.in/api/static/files/faculty_result_2024_2024-11-27-21-18-34.pdf" target='_blank'>
                6. 27-Nov-2024: Results of Faculty Selection against Rolling Advt. No. NITW/Admn/T01/Rolling/2024.

              </a>
            </h4>
          </li>
        </ol>


        <h3 style={{ color: "red" }}><u>28-04-2024</u></h3>
        <ul >
          <li className='pt-2'><h5 style={{ color: "green" }}>1. To candidates who have submitted applications: Please keep visiting the portal for further updates. However,
            no queries can be entertained regarding the further course of recruitment.</h5></li>
          <li className='pt-2'><h5 style={{ color: "green" }}>2.Inviting applications for Faculty positions will be on hold until further notice.</h5></li>

        </ul>

      </div>)

      // tabContent = announcements.map((announcement, index) => (<div>
      //   <li key={index}>
      //     <h4>
      //       <a href={announcement.Link} target="_blank" rel="noopener noreferrer">
      //         {announcement.Title}
      //       </a>
      //     </h4>
      //     <hr />
      //   </li><br/></div>
      // ));
    } else if (activeTab === 'notices') {
      tabContent = (
        // <li key={index}>

        //   <h4>
        //     <a href={notice.Link} target="_blank" rel="noopener noreferrer">
        //       {notice.Title}

        //     </a>

        //   </h4>
        //   <br/>
        // </li>
        <div>
          <h3 style={{ color: "red" }}><u>10-03-2025</u></h3>
          <ol >
            <li><h4 ><a href="https://nitw.ac.in/api/static/files/Vacancy_matrix-2025_2025-3-10-16-48-45.pdf" target='_blank'>1. Department-wise vacancies</a></h4>
            </li>
            </ol>

          <h3 style={{ color: "red" }}><u>01-03-2025</u></h3>
          <ol >
            <li><h4 ><a href="#" target='_blank'>1. To candidates who have submitted applications: Please keep visiting the portals (https://nitw.ac.in/faculty) for further updates . However, no queries can be entertained regarding the further course of recruitment.</a></h4>
            </li>
            <li><h4>2. Inviting applications for Faculty positions will be on hold until further notice.</h4></li>
            
          </ol>
          <h3 style={{ color: "red" }}><u>13-02-2025</u></h3>
          <ol >
            <li><h4><a href="#" target='_blank'>1. Cut-off date extended to 28.02.2025 11:59 pm IST. </a></h4>
            </li>
            <li><h4>2. Candidates who have submitted the applications may modify / update the same. </h4></li>
            <li><h4>3. Applications submitted already, without any further modifications / updates will be submitted automatically after the cut-off date. </h4></li>
            <li><h4>4. New applications (those not submitted earlier) require submission by candidates.  </h4></li>
            <li><h4>5. Please keep visiting the portal for further updates.</h4></li>
          </ol>


          {/* <h3 style={{ color: "red" }}><u>28-01-2025</u></h3>
          <ol >
            <li>
              <h4 style={{ color: "#FF5833", animation: "blinker 2.0s linear infinite" }}>
                <a href="#" target='_blank'>
                  1. 'Submit' button is enabled now. The cut-off date is 15.2.2025 11:59 pm IST. No application can be submitted or modified after the cut-off date. 

                </a>
              </h4>
            </li>
            </ol> */}

          <h3 style={{ color: "red" }}><u>22-12-2024</u></h3>
          <ol >
            <li>
              <h4 style={{ color: "#FF5833", animation: "blinker 2.0s linear infinite" }}>
                <a href="https://nitw.ac.in/api/static/files/fac_adv_2024_dec_2024-12-22-8-20-34.pdf" target='_blank'>
                  1. Rolling Faculty Recruitment -Notification

                </a>
              </h4>
            </li>
            <li>
              <h4>
                <a href="https://nitw.ac.in/api/static/files/Instructions_for_Filling_OAF_2024-6-7-10-15-19.pdf" target='_blank'>
                  2. Instructions for Filling Online Application Form

                </a>
              </h4>
            </li>
            <li>
              <h4>
                <a href="https://nitw.ac.in/api/static/files/R2024_clarification_2024-6-7-10-17-57.pdf" target='_blank'>
                  3. Implementation of Recruitment Rules for Faculty of NITS and IIEST- issue of clarifications as per recommendations of the Oversight Committe
                </a>
              </h4>
            </li>
          </ol>
          <h3 style={{ color: "rgb(241, 97, 39)" }}><u>Enquiries</u></h3>
          <div className='row'>
            {/* <div className='col-md-6 col-sm-12 pt-2'>
              <div class="card " style={{ backgroundColor: "#f3efe8" }}>
                <div class="card-body">
                  <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                      <img  style={{ width:"180px", height:"160px"}} src='https://erp.nitw.ac.in/ext/profile/getUserImage/ch-avv' />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <h5 style={{color: "rgb(241, 97, 39)"}}><a href='https://erp.nitw.ac.in/ext/profile/ch-avv'> Prof. A Venu Vinod</a></h5>
                      <b><a href='https://erp.nitw.ac.in/ext/profile/ch-avv'>   Dean (Faculty Welfare)</a></b><br/>                      
                      <b style={{color: "rgb(241, 97, 39)"}}><i className='fa fa-solid fa-phone' style={{color:"rgb(241, 97, 39)"}}></i></b> (+91)870-2462018 <br/>
                      <b style={{color: "rgb(241, 97, 39)"}}><i className='fa fa-solid fa-envelope' style={{color:"rgb(241, 97, 39)"}}></i></b> dean_fa[at]nitw.ac.in
                     
                    </div>

                  </div>
                </div>
              </div>
            </div> */}
            <div className='col-md-6 col-sm-12 pt-2'>
              <div class="card" style={{ backgroundColor: "#f3efe8" }}>
                <div class="card-body">
                  <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                      <img style={{ width: "180px", height: "160px" }} src='https://erp.nitw.ac.in/ext/profile/getUserImage/cy-harikokatla' />
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <h5 style={{ color: "rgb(241, 97, 39)" }}><a href='https://erp.nitw.ac.in/ext/profile/ch-harikokatla'> Prof. K Hari Prasad</a></h5>
                      <b><a href='https://erp.nitw.ac.in/ext/profile/ch-avv'>  Associate Dean (Faculty Welfare)</a></b>

                      <b style={{ color: "rgb(241, 97, 39)" }}><i className='fa fa-solid fa-phone' style={{ color: "rgb(241, 97, 39)" }}></i></b> 9491296456 <br />
                      <b style={{ color: "rgb(241, 97, 39)" }}><i className='fa fa-solid fa-envelope' style={{ color: "rgb(241, 97, 39)" }}></i></b> assodean_fw[at]nitw.ac.in

                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div><br /><br />
          <div className='container'>
            <a href='https://nitw.ac.in/page/?url=/jobrecruitment/FacultyRecruitments'> <button type="button" class="btn btn-primary btn-block">View Closed Advertisements</button></a>
          </div>
        </div>

      );
    } else if (activeTab === 'applyOnline') {
      tabContent = Applyonlineportal.map((item, index) => (
        <li key={index}>
          <h4>
            <a href={item.Link} target="_blank" rel="noopener noreferrer">
              {item.Title}
            </a>
          </h4>
          <hr />
        </li>
      ));
    }

    return <ul>{tabContent}</ul>;
  };

  const handleTabClick = (tab) => {
    if (tab === 'applyOnline') {
      window.open("https://recruit.nitw.ac.in/", '_blank'); // Open the online portal link in a new tab
    } else {
      setActiveTab(tab);
    }
  };

  return (
    <div className="page text-left font-change-applicable static-facilities">
      <div className="p-3 container" style={{ backgroundColor: '', width: '100%' }}>
        <div>
          <h3><b>Faculty Positions</b></h3>
          <p style={{ textAlign: 'justify' }}>
            NITW, an Institute of national importance in
            teaching, research and development, invites application from Indian nationals,
            possessing excellent academic background along with commitment to quality teaching and
            potential for carrying out outstanding research for faculty position at the level of
            Professor, Associate Professor, and Assistant Professor. NIT Warangal has been
            instrumental for providing opportunity to every faculty in achieving academic excellence,
            developing all-round personality and realizing his or her full potential.
          </p>
        </div>
        <br />
        <div className="tabs">

          <button
            className={`tab ${activeTab === 'notices' ? 'active' : ''}`}
            onClick={() => handleTabClick('notices')}
          >
            Faculty Recruitment December 2024
          </button>

          <button
            className={`tab ${activeTab === 'applyOnline' ? 'active' : ''}`}
            onClick={() => handleTabClick('applyOnline')}
          >
            Apply Online Portal
          </button>

          <button
            className={`tab ${activeTab === 'announcements' ? 'active' : ''}`}
            onClick={() => handleTabClick('announcements')}
          >
            Announcements
          </button>


        </div>
        <br />
        <div className="tab-content">
          {renderTabContent()}
        </div>
      </div>
    </div>
  );
};

export default FacultyPositions;