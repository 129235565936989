export const CampusActivityData = [
    {
        url: 'https://nitw.ac.in/api/static/files/Ek_Ped_Maa_Ke_Nam_2024-9-20-18-48-19.jpg',
       text: 'Ek Ped Maa Ke Nam',
       isactive:'yes'
    },

    {
        url: 'https://nitw.ac.in/api/static/files/Swachhata_Campaign2_2024-9-20-18-51-30.jpg',
       text: 'Swachhata Campaign',
       isactive:'yes'
    },
    {
        url: 'https://nitw.ac.in/api/static/files/Swachhata_Campaign1_2024-9-20-18-51-4.jpg',
       text: 'Swachhata Campaign',
       isactive:'yes'
    },
    {
        url: 'https://nitw.ac.in/api/static/files/Ek_Ped_Maa_Ke_Nam2_2024-9-20-18-50-30.jpg',
       text: 'Ek Ped Maa Ke Nam',
       isactive:'yes'
    },
    {
        url: 'https://nitw.ac.in/api/static/files/Ek_Ped_Maa_Ke_Nam1_2024-9-20-18-48-46.jpg',
       text: 'Ek Ped Maa Ke Nam',
       isactive:'yes'
    },
    
    {
        url: 'https://nitw.ac.in/api/static/files/Swachhata_Pledge_2024-9-20-18-47-45.jpg',
       text: 'Swachhata Pledge',
       isactive:'yes'
    },

];

export const GoI_Images = [

    // {
    //     image: '',
    //    title: '',
    //    isactive:'Yes'
    // },
    
    {
        image: 'https://nitw.ac.in/api/static/files/goi_6_2025-2-24-12-51-1.jpg',
       title: 'Swachhata Pakhwada  2024',
       isactive:'Yes'
    },
    
    {
        image: 'https://nitw.ac.in/api/static/files/goi_5_2025-2-24-12-50-40.jpg',
       title: 'Swachhata Pakhwada  2024',
       isactive:'Yes'
    },
    
    {
        image: 'https://nitw.ac.in/api/static/files/goi_4_2025-2-24-12-50-9.jpg',
       title: 'Swachhata Pakhwada  2024',
       isactive:'Yes'
    },
    
    {
        image: 'https://nitw.ac.in/api/static/files/goi_3_2025-2-24-12-49-43.jpg',
       title: 'Har Ghar Tiranga 2024',
       isactive:'Yes'
    },
    
    {
        image: 'https://nitw.ac.in/api/static/files/goi_2_2025-2-24-12-49-15.jpg',
       title: 'Yoga 2024',
       isactive:'Yes'
    },
    
    {
        image: 'https://nitw.ac.in/api/static/files/goi_1_2025-2-24-12-48-2.jpg',
       title: 'Ek Ped Maa Ke Nam 2024',
       isactive:'Yes'
    },

    {
        image: 'https://nitw.ac.in/api/static/files/Ek_Ped_Maa_Ke_Nam_2024-9-20-18-48-19.jpg',
       title: 'Ek Ped Maa Ke Nam 2024',
       isactive:'Yes'
    },

    {
        image: 'https://nitw.ac.in/api/static/files/Swachhata_Campaign2_2024-9-20-18-51-30.jpg',
        title: 'Swachhata Campaign 2024',
       isactive:'Yes'
    },
    {
        image: 'https://nitw.ac.in/api/static/files/Swachhata_Pledge_2024-9-20-18-47-45.jpg',
        title: 'Swachhata Pledge 2024',
       isactive:'Yes'
    },
];