import React, { useState, useEffect } from 'react';
import { BaseUrl, MainServerUrl, OldServerUrl } from '../BasePath'
import "../css/NewsScroll.css";
import "../css/NewScroll2.css";
import "../css/PhdSeminars.css";

const NewScroll3 = (props) => {
    const [seminars, setSeminars] = useState([]);
    const [isOpen7, setIsOpen7] = useState(false); // Collapse state for the first seminar

    const formatDate = (date) => {
        if (!date) return "Invalid Date";

        const parsedDate = new Date(date); // Ensure the input is parsed as a Date object
        if (isNaN(parsedDate)) return "Invalid Date"; // Handle invalid dates

        const months = [
            "January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];

        const day = parsedDate.getDate();
        const month = months[parsedDate.getMonth()];
        const year = parsedDate.getFullYear();

        let hours = parsedDate.getHours();
        const minutes = parsedDate.getMinutes().toString().padStart(2, "0");
        const ampm = hours >= 12 ? "PM" : "AM";

        hours = hours % 12 || 12; // Convert to 12-hour format
        const time = `${hours}:${minutes} ${ampm}`;

        return `${day}-${month}-${year} | ${time}`;
    };


    // Fetch the seminar data from the API
    useEffect(() => {
        const fetchSeminars = async () => {
            try {
                const response = await fetch(`${MainServerUrl}/api/seminar/active`);
                const data = await response.json();
                setSeminars(data); // Store the fetched data in the state
            } catch (error) {
                console.error("Error fetching seminar data:", error);
            }
        };

        fetchSeminars();
    }, []); // Empty dependency array means this runs only once when the component mounts

    const toggleCollapse7 = () => {
        setIsOpen7(!isOpen7);
    };

    return (
        <div className="flex">
            <h4 className="fw-bold subchildEventsPublication">{props.carouselTitle}</h4>
            <div className="text-subline"></div>
            <br />
            <div className="bar">
                <div id="carouselExampleControls" className="carousel slide" data-bs-ride="carousel">
                    <div className="carousel-inner">
                        {seminars.slice(0, 10).map((seminar, index) => (
                            <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={seminar._id}>
                                <div className="card profile-card">
                                    <div className="card-img-block">
                                        <img className="img-fluid" src="https://nitw.ac.in/api/static/files/ribbon_light_strip_2023-6-15-11-2-51.jpg" width="100%" alt="Card image cap" />
                                    </div>
                                    <div className="card-body pt-5">
                                        <img
                                            src={seminar.student_img || 'https://nitw.ac.in/api/static/files/avatar2_2024-7-29-17-28-1.jpg'}
                                            alt="profile-image"
                                            className="profile"
                                        /><br />
                                        <span>{seminar.presentation_title}</span>

                                        <h5 className="card-title">{seminar.student_name}</h5>
                                        <p className="card-text">{seminar.thesis_title}</p>

                                        <div className="seminar-info-section">
                                            {seminar.presentation_title === 'Ph.D. Pre-Synopsis Open Seminar' || seminar.presentation_title === 'Ph.D. Viva Voce Examination' ? (
                                                <p className="date-info">
                                                    <b>Supervisor:</b> {seminar.student_supervisor}<br />
                                                    <b>Venue:</b> {seminar.venue} <br />
                                                    <b><i>{seminar.department}</i></b><br />
                                                    <b>{formatDate(seminar.datetime)}</b>
                                                </p>
                                            ) : (
                                                <p className="date-info">
                                                    <b>Venue:</b> {seminar.venue} <br />
                                                    <b>{formatDate(seminar.datetime)}</b>
                                                </p>
                                            )}
                                        </div>


                                        <p>
                                            <a
                                                className="btn  abstract-btn"
                                                onClick={toggleCollapse7}
                                                aria-expanded={isOpen7 ? "true" : "false"}
                                                aria-controls="collapsesix"
                                                 style={{ backgroundColor:"#2b4675", color:"#fff"}}
                                            >
                                                {seminar.presentation_title === 'Ph.D. Pre-Synopsis Open Seminar' || seminar.presentation_title === 'Ph.D. Viva Voce Examination' || seminar.presentation_title === 'Institute Lecture Series' ? (
                                                    <var> Abstract </var>
                                                ) : (
                                                    <var> Biography </var>
                                                )}
                                                <i className="fa fa-angle-down" aria-hidden="true"></i>
                                            </a>
                                        </p>
                                        <div className={isOpen7 ? "collapse show" : "collapse"} id="collapsesix">
                                            <div className="card card-body">
                                                <p style={{ textAlign: "justify" }}>
                                                    {seminar.thesis_abstract}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default NewScroll3;
