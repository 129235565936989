import React from 'react';
import PageHeader from '../components/PageHeader';
import { colors } from '@mui/material';
// import '../css/deansw.css'; // Update the CSS file name

const Staffrecruitment = () => {
    return (
        <div class="container my-4">
            <center><h4 style={{ textDecoration: "underline" }}>Staff Recruitment</h4></center>

            <div class="row">
            <div class="card mb-2">
                    <div class="card-header bg-primary text-white"><h5 style={{ color: "white" }}>Advertisement [No. 01/2025 dated : 18.02.2025]</h5></div>
                    <div class="card-body">
                    {/* <p style={{color:"red"}}>Online application is closed ....</p> */}
                        <a href="https://nitw.ac.in/api/static/files/se_deputation_2025-2-18-10-18-37.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i> <font style={{ color: "#b80947" }}>Detailed Advertisement & GENERAL INSTRUCTIONS </font> <font style={{ color: "green" }}>[No. 01/2025 dated 18.02.2025]</font></a>
                        <p>Last date: <i className='fa fa-calendar'></i> 17.03.2025</p>
                       
                        <table className="table table-bordered table-responsive">
                            <tbody>
                                <tr>
                                    <th>S.no </th>
                                    <th>Post Name</th>
                                    {/* <th>Syllabus & Scheme of Examination</th> */}
                                    <th colSpan={3}>Shortlisting  Status</th>
                                    <th>Selected</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Superintending Engineer (Deputation ISTC)</td>
                                    {/* <td>--</td> */}
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                            </tbody>
                        </table>
                        <a href="https://careers.nitw.ac.in/register/?next=/" className='btn btn-primary mt-2'> Online Application Link </a>
                    </div>

                </div>
            <div class="card mb-2">
                    <div class="card-header bg-warning text-white"><h5 style={{ color: "white" }}>Advertisement [No. 05/2024 dated : 29.11.2024]</h5></div>
                    <div class="card-body">
                    <p style={{color:"red"}}>Online application is closed ....</p>
                        
                        {/* <p style={{color:"red"}}>Online application is closed ....</p> */}
                        <a href="https://nitw.ac.in/api/static/files/advt5_2024_2024-11-29-15-55-18.pdf"><i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i> <font style={{ color: "#b80947" }}>Detailed Advertisement & GENERAL INSTRUCTIONS </font> <font style={{ color: "green" }}>[No. 05/2024 dated : 29.11.2024]</font></a>
                        <p>Last date: <i className='fa fa-calendar'></i> 07.01.2025</p>

                        <a href="https://nitw.ac.in/api/static/files/office_court_2024-12-27-18-7-12.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red" }}> 27.12.2024: Update on recruitment of Office Attendant / Lab Attendant positions notified against Recruitment Advertisement No. 05/2024</font> </a><br/><br/>

                        <a href="https://nitw.ac.in/api/static/files/Examination_Syllabus_2025-1-21-11-55-4.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red" }}> 21.01.2025: Pattern of Examination and Indicative Syllabus for the Group 'B' & 'C' posts notified on Direct Recruitment basis against Advt. No. 05/2024 dated 29.11.2024
                        </font> </a><br/><br/>
                        <a href="https://nitw.ac.in/api/static/files/web_notice_05_2024_2025-2-3-17-30-39.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red    "}}></i><font style={{ color: "red",}}> 03.02.2025: Web Notice & List of provisionally shortlisted / not-shortlisted candidates for Group 'A' posts and Group 'B' (Deputation) posts against Advt. No. 05/2024 dated 29.11.2024

                        </font> </a><br/><br/>


                        <a href="https://nitw.ac.in/api/static/files/adv_05_adm_2025-2-21-9-51-47.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red" }}> 20.02.2025: Addendum to the list of provisionally shortlisted candidates for the Group 'A' posts and Group 'B' (Deputation) posts notified vide Advt. No. 05/2024 dated 29.11.2024
                        
                        </font> </a><br/><br/>
                        <a href="https://nitw.ac.in/api/static/files/adv52024result_2025-3-24-18-50-58.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red" }}> 24.03.2025: The results of selection process for the Group 'A' posts and Group 'B' (Deputation) posts notified against Advt. No. 05/2024 dt.29.11.2024
                        
                        </font> </a><br/><br/>

                        <a href="https://nitw.ac.in/api/static/files/web_notice_2025-4-2-19-12-52.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red",animation: "blinker 2s step-start infinite"}}></i><font style={{ color: "blue",animation: "blinker 2s step-start infinite" }}> 02.04.2025: Web Notice & the list of candidates provisionally shortlisted/ not-shortlisted for the Group 'B' & 'C' posts on Direct Recruitment basis notified against Advt. No. 05/2024 dated 29.11.2024 and the post of Senior Assistant notified against Advt. No. 01/2023 dated 01.02.2023
                        
                        </font> </a><br/><br/>
                        <a href="https://nitw.ac.in/api/static/files/disability_2025-4-2-19-13-18.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red",animation: "blinker 2s step-start infinite"}}></i><font style={{ color: "blue",animation: "blinker 2s step-start infinite" }}> Format-Disability Certificate
                        
                        </font> </a><br/><br/>


                        {/* <a href="https://nitw.ac.in/api/static/files/Notice_2024-9-24-19-28-14.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red" }}> Notice dt.24.09.2024 - Instructions for the candidates who have submitted their applications against Advertisement No. 03/2024 dt.16.08.2024</font> </a><br/><br/>
                        <a href="https://nitw.ac.in/api/static/files/Addendum03_2024-10-1-20-5-50.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red"}}></i><font style={{ color: "red"}}> 01.10.2024: Addendum to the list of provisionally shortlisted candidates for the posts notified vide Advt. No. 03/2024 dt.16.08.2024</font> </a><br/> */}
                        <table className="mt-2 table table-bordered table-responsive">
                            <tbody>
                                <tr>
                                    <th>S.no </th>
                                    <th>Post Name</th>
                                    {/* <th>Syllabus & Scheme of Examination</th> */}
                                    <th colSpan={3}>Shortlisting  Status</th>
                                    <th>Selected</th>
                                </tr>
                                <tr>
                                    <td>1</td>
                                    <td>Principal Scientific/ Technical Officer</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/pto_2025-2-3-17-34-57.pdf"> <font style={{ color: "green" }}>Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>Principal Students Activity and Sports (SAS) Officer</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/PSASO_2025-2-3-17-34-16.pdf"> <font style={{ color: "green" }}>Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>Deputy Registrar (Deputation ISTC)</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/dr_deputation_2025-2-3-17-33-27.pdf"> <font style={{ color: "green" }}>Level 1</font></a> </td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>Executive Engineer (Civil) (Deputation ISTC)</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/ee_deputation_2025-2-3-17-32-34.pdf"> <font style={{ color: "green" }}>Level 1</font></a>  </td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>Assistant Registrar (Deputation ISTC)</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/ar_deputation_2025-2-3-17-33-4.pdf"> <font style={{ color: "green" }}>Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>6</td>
                                    <td>Assistant Engineer (Deputation ISTC)</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/ae_deputation_2025-2-3-17-31-54.pdf"> <font style={{ color: "green" }}>Level 1</font></a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>7</td>
                                    <td>Superintendent</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/sp_2025_2025-4-2-19-26-22.pdf"> <font style={{ color: "green" }}>Level 1</font> <font style={{ color: "red",animation: "blinker 2s step-start infinite" }}>New</font> </a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>8</td>
                                    <td>Junior Engineer</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/je2025_2025-4-2-19-26-42.pdf"> <font style={{ color: "green" }}>Level 1</font> <font style={{ color: "red",animation: "blinker 2s step-start infinite" }}>New</font> </a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>9</td>
                                    <td>Library & Information Assistant</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/lia2025_2025-4-2-19-27-3.pdf"> <font style={{ color: "green" }}>Level 1</font> <font style={{ color: "red",animation: "blinker 2s step-start infinite" }}>New</font> </a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>10</td>
                                    <td>Students Activity and Sports (SAS) Assistant</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/sas2025_2025-4-2-19-27-23.pdf"> <font style={{ color: "green" }}>Level 1</font> <font style={{ color: "red",animation: "blinker 2s step-start infinite" }}>New</font> </a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>11</td>
                                    <td>Senior Assistant</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/sa2025_2025-4-2-19-31-46.pdf"> <font style={{ color: "green" }}>Level 1</font> <font style={{ color: "red",animation: "blinker 2s step-start infinite" }}>New</font> </a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>12</td>
                                    <td>Junior Assistant</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/ja2025_2025-4-2-19-28-45.pdf"> <font style={{ color: "green" }}>Level 1</font> <font style={{ color: "red",animation: "blinker 2s step-start infinite" }}>New</font> </a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>13</td>
                                    <td>Office Attendant</td>
                                    {/* <td>--</td> */}
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                                <tr>
                                    <td>14</td>
                                    <td>Lab Attendant</td>
                                    {/* <td>--</td> */}
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <a href="https://careers.nitw.ac.in/register/?next=/" className='btn btn-warning mt-2'> Online Application Link </a> */}
                    </div>

                </div>


                <div class="card mb-2">
                    <div class="card-header bg-primary text-white"><h5 style={{ color: "white" }}>Advertisement [No. 01/2023 dated : 01.02.2023]</h5></div>
                    <div class="card-body">
                    <p style={{color:"red"}}>Online application is closed.</p>
                    <a href="https://nitw.ac.in/api/static/files/web_notice_2025-4-2-19-12-52.pdf"> <i className='fa fa-file-pdf-o fa-2x' style={{color:"red",animation: "blinker 2s step-start infinite"}}></i><font style={{ color: "blue",animation: "blinker 2s step-start infinite" }}> 02.04.2025: Web Notice & the list of candidates provisionally shortlisted/ not-shortlisted for the Group 'B' & 'C' posts on Direct Recruitment basis notified against Advt. No. 05/2024 dated 29.11.2024 and the post of Senior Assistant notified against Advt. No. 01/2023 dated 01.02.2023
                        
                        </font> </a><br/><br/>
                       
                        <table className="table table-bordered table-responsive">
                            <tbody>
                                <tr>
                                    <th>S.no </th>
                                    <th>Post Name</th>
                                    <th colSpan={3}>Shortlisting  Status</th>
                                    <th>Selected</th>
                                </tr>
                               

                                <tr>
                                    <td>01</td>
                                    <td>Senior Assistant</td>
                                    {/* <td>--</td> */}
                                    <td><a href="https://nitw.ac.in/api/static/files/sa2023_2025-4-2-19-29-46.pdf"> <font style={{ color: "green" }}>Level 1</font> <font style={{ color: "red",animation: "blinker 2s step-start infinite" }}>New</font> </a></td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>--</td>
                                </tr>
                               
                            </tbody>
                        </table>
                        {/* <a href="https://careers.nitw.ac.in/register/?next=/" className='btn btn-primary mt-2'> Online Application Link </a> */}
                    </div>

                </div>
               
                
              
                <div class="card mb-2">
                    
                    <div class="card-header">

                <a href="https://nitw.ac.in/oldstaffrecruit"className='btn btn-primary mt-2'>Click on the button to view the old advertisements</a>
                </div>
                </div>
            

                

            </div>
        </div>
    )
}
export default Staffrecruitment;