import React, { useState } from 'react';
import '../css/MilestonesTimeline.css';
import { colors } from '@mui/material';



const Iaic2025page = () => {


    const styles = {
        global: {
            fontFamily: "Signika !important",
            color: "#000 !important"
        },
        title: {
            color: '#2037ff',
        },
        paragraph: {
            fontSize: '16px',
        },

        ulBulletes: {
            color: "#000"
        }
    };




    return (
        <>
            <section><img className='img-fluid' alt='' src='https://nitw.ac.in/api/static/files/iaic2025_2025-2-27-15-46-15.jpg' /></section>
            <ul className="nav nav-tabs justify-content-center aboutus-tabs" role="tablist">
                <li className="nav-item" role="presentation">
                    <a className="nav-link  about-tab active" id="tab-0" data-bs-toggle="tab" href="#tabpanel-0" role="tab" aria-controls="tabpanel-0" aria-selected="true">Home</a>
                </li>
                {/* <li className="nav-item" role="presentation">
                    <a className="nav-link about-tab" id="tab-6" data-bs-toggle="tab" href="#tabpanel-6" role="tab" aria-controls="tabpanel-6" aria-selected="false">About ANRF</a>
                </li> */}
                <li className="nav-item" role="presentation">
                    <a className="nav-link about-tab" id="tab-4" data-bs-toggle="tab" href="#tabpanel-4" role="tab" aria-controls="tabpanel-4" aria-selected="false">Objectives</a>
                </li>

                <li className="nav-item" role="presentation">
                    <a className="nav-link about-tab" id="tab-5" data-bs-toggle="tab" href="#tabpanel-5" role="tab" aria-controls="tabpanel-5" aria-selected="false">About NITW</a>
                </li>

                <li className="nav-item" role="presentation">
                    <a className="nav-link about-tab" id="tab-2" data-bs-toggle="tab" href="#tabpanel-2" role="tab" aria-controls="tabpanel-2" aria-selected="false">Partner with us</a>
                </li>
                <li className="nav-item" role="presentation">
                    <a className="nav-link about-tab" id="tab-3" data-bs-toggle="tab" href="#tabpanel-3" role="tab" aria-controls="tabpanel-3" aria-selected="false">Schedule</a>
                </li>
                {/* <li className="nav-item" role="presentation">
                    <a className="nav-link about-tab" id="tab-1" data-bs-toggle="tab" href="#tabpanel-1" role="tab" aria-controls="tabpanel-1" aria-selected="false">Organizers</a>
                </li> */}


            </ul>

            <div className="container">
                <div className="tab-content pt-5 pb-5" id="tab-content">
                    <div className="tab-pane active" id="tabpanel-0" role="tabpanel" aria-labelledby="tab-0">

                        <div className='row'>
                            <div className='col-md-12'>

                                <h3 style={styles.title}>About IAIC 2025</h3>
                                <p style={styles.paragraph}>IAIC-2025 focusses on bringing together industry leaders, investors, and academia to foster innovation and collaboration. The conclave aims to bridge the gap between industry and academia, providing a platform for knowledge exchange, networking, and creating impactful partnerships.</p>

                                <p style={styles.paragraph}>The event will feature panel discussions, startup pitching sessions, exhibitions, and networking opportunities for all stakeholders in the innovation ecosystem.</p>
                                {/* <h4 style={styles.title}>Themes</h4>
                                <p style={styles.paragraph}>This year's symposium will focus on key themes addressing critical advancements and challenges in engineering. These topics will foster interdisciplinary dialogue and collaboration, bringing together thought leaders from academia, industry, and research to explore the future of engineering and technology.</p>
                                <ul class="visbullets" style={styles.ulBulletes}>

                                    <li>Additive Manufacturing and Automation</li>
                                    <li>Smart Grid: Power Electronic Converters, control and Protection</li>
                                    <li>Green Hydrogen and Storage Technologies</li>
                                    <li>Quantum Computing, Artificial Intelligence and Machine Learning</li>

                                </ul>
                                <h4 style={styles.title}>IMP</h4>
                                NatFoE will be co-located with the Innovation in Manufacturing Processes (IMP), a national project competition open to engineering students and practitioners. The competition welcomes participation from undergraduates, postgraduates, PhD. students in engineering & technology and incubators, providing a platform to showcase innovative solutions in manufacturing processes. IMP aims to foster creativity and technical excellence, offering participants an opportunity to present their projects to a distinguished audience of industry experts, researchers, and academics
                                <h4 style={styles.title}>Important dates</h4>
                                <p style={styles.paragraph}>Last date to submit Posters :9 November 2024</p>
                                <p style={styles.paragraph}>Last date to Submit abstract for IMP: 9 November 2024</p>
                                <p style={styles.paragraph}>Symposium on 16-17 November 2024</p> */}


                            </div>
                        </div>
                        {/* ABOUT US END*/}

                    </div>

                    {/* ORGANIZERS START */}
                    <div className="tab-pane" id="tabpanel-1" role="tabpanel" aria-labelledby="tab-1">

                        <div className='row'>


                            <h3 style={styles.title} className='mt-3'>Patron</h3>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-title" style={{ paddingLeft: "0px" }}>Prof. Indranil Manna</h5>
                                    <p className="card-text" style={{ marginTop: "0px" }}>FNAE, President, INAE</p>
                                </div>
                            </div>
                            <h3 style={styles.title} className='mt-3'>Coordinators</h3>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Bidyadhar Subudhi</h5>
                                    <p className="card-text" style={{ marginTop: "0px" }}>FNAE, Director, NIT Warangal</p>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Sivaji Chakravorti</h5>
                                    <p className="card-text" style={{ marginTop: "0px" }}>FNAE, Vice President INAE</p>
                                </div>
                            </div>
                            <h3 style={styles.title} className='mt-3'>Core Team</h3>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Shirish H Sonawane</h5>
                                    <p className="card-text" style={{ marginTop: "0px" }}>Dean (Research & Development) - Convenor </p>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. P Ratish Kumar</h5>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Kartik Balasubramanian</h5>
                                </div>
                            </div>



                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof.S Shankar</h5>
                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Bharat Bandi</h5>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Ram Krishan</h5>
                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. V Sreedevi</h5>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Sreehari Rao Patri</h5>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Shri Gajanan Devkate</h5>
                                </div>
                            </div>

                            <h3 style={styles.title} className='mt-3'>Theme Conveners</h3>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Additive Manufacturing & Automation</h5>
                                    <p className="card-text">Prof. Adepu Kumar <br />Prof .Y Ravi Kumar <br />Prof. Shivraman</p>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Smart Grid: Power Electronic Converters, control and Protection </h5>
                                    <p className="card-text">Prof. V T Somasekhar <br />Prof. Ram krishan</p>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Green Hydrogen and Storage Technologies </h5>
                                    <p className="card-text">Prof. P V Suresh  <br />Prof. K Manohar</p>
                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Quantum Computing , Artificial Intelligence and Machine Learning </h5>
                                    <p className="card-text">Prof. P Radhakrishna  <br />Prof. U Venkanna  <br /> Prof. Manish K Bajpai</p>
                                </div>
                            </div>


                            <h3 style={styles.title} className='mt-3'>IMP Start-ups /Exhibits / Posters </h3>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}> Prof. Y Ravi Kumar</h5>

                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Ravi Kumar J</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. L Anjaneyulu</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Sreehari Rao Patri</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. P Subhas Chandra Bose</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. P Vamsi Krishna</h5>

                                </div>
                            </div>


                            <h3 style={styles.title} className='mt-3'>Organising Team</h3>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Rashmi Ranjan Rout</h5>

                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. T Phanirama Tezeswi</h5>

                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. G. Uday Bhaskar</h5>

                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. K S Raj Mohan</h5>

                                </div>
                            </div>



                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. P Sampath Kumar</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Sanjit Biswas</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}>Prof. Sanjaya K Panda</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}> Prof. Jew Das</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}> Shri Adesh Srivastava</h5>

                                </div>
                            </div>


                            <div className='col-md-4'>
                                <div className="card-body" style={{ paddingLeft: "0px" }}>
                                    <h5 className="card-text" style={{ marginTop: "0px" }}> Prof. T Rahul </h5>

                                </div>
                            </div>


                        </div>


                    </div>
                    {/* ORGANIZERS START */}

                    {/* SPEAKERS START */}
                    <div className="tab-pane" id="tabpanel-2" role="tabpanel" aria-labelledby="tab-2">
                        <div className='row'>

                            <h3 style={styles.title}>Benefits in Partnership</h3>
                            <ul class="visbullets" style={styles.ulBulletes}>
                                <li>Support the growth of India’s startup ecosystem and foster innovation for a better future</li>
                                <li>Participate as a panelist or keynote speaker to share your insights and expertise.</li>
                                <li>Showcase your brand to a wide audience, including startups, investors, academia, and industry professionals.</li>
                                <li>Build partnerships with startups and explore innovative solutions.</li>
                                <li>Connect with a pool of highly skilled students, researchers, and innovators from NIT Warangal</li>
                            </ul>
                            <h3 style={styles.title}>Categories</h3>


                            <div class="table-responsive">
                                <table class="table table-bordered table-striped">
                                    <thead class="table-warning">
                                        <tr>
                                            <th>Category</th>
                                            <th>Contribution (INR)</th>
                                            <th>Benefits</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Title Partner</td>
                                            <td>1,50,000</td>
                                            <td>Prime branding, exclusive keynote slot, prominent logo placement, and 10 passes</td>
                                        </tr>
                                        <tr>
                                            <td>Gold Partner</td>
                                            <td>1,00,000</td>
                                            <td>Panelist opportunity, logo on all event materials, 7 passes</td>
                                        </tr>
                                        <tr>
                                            <td>Silver Partner</td>
                                            <td>50,000</td>
                                            <td>Logo on brochures, 5 free passes, Stall exhibition space</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>




                        </div>




                    </div>
                    {/* SPEAKERS END */}
                    <div className="tab-pane" id="tabpanel-3" role="tabpanel" aria-labelledby="tab-3">



                        <div className='row'>
                            
                          
                            <h3 style={styles.title}>Program Schedule</h3>

                            <h4><a href='https://nitw.ac.in/api/static/files/iaic_schedule_2025_2025-3-11-10-31-2.pdf' target='_blank'  className="blink" style={{ color: "brown" }}>Click here to Program Schedule.</a></h4>
                            {/* <p>To be updated</p> */}
                            <h4><a href='https://nitw.ac.in/api/static/files/iaic2025b_2025-2-27-16-8-37.pdf' target='_blank' style={{ color: "red" }}>Click here to download the brochure.</a></h4>
                        </div>





                    </div>
                    {/* ABOUT INAE START */}
                    <div className="tab-pane" id="tabpanel-6" role="tabpanel" aria-labelledby="tab-6">
                        <div className='row'>
                            <div className='row'>
                                <div className='col-md-12'>
                                    <h3 style={styles.title}>About ANRF</h3>
                                    <p>The Anusandhan National Research Foundation (ANRF) has been established with Anusandhan National Research Foundation (ANRF) 2023 Act. The ANRF aims to seed, grow and promote research and development (R&D) and foster a culture of research and innovation throughout India’s universities, colleges, research institutions, and R&D laboratories. ANRF will act as an apex body to provide high-level strategic direction of scientific research in the country as per recommendations of the National Education Policy (NEP). With the establishment of ANRF, the Science and Engineering Research Board (SERB) established by an act of Parliament in 2008 has been subsumed into ANRF. ANRF will forge collaborations among the industry, academia, and government departments and research institutions, and create an interface mechanism for participation and contribution of industries and State governments in addition to the scientific and line ministries.</p>
                                    <p>For more details, please visit <a href='https://dst.gov.in/anusandhan-national-research-foundation-anrf' target='_blank'>https://dst.gov.in/anusandhan-national-research-foundation-anrf</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* ABOUT INAE END */}


                    {/* Objectives START */}
                    <div className="tab-pane" id="tabpanel-4" role="tabpanel" aria-labelledby="tab-4">
                        <div className='row'>
                            <div className='row'>
                                <div className='col-md-12'>

                                    <p>The Industry-Academia Innovation Conclave (IAIC-2025) at NIT Warangal aims to foster collaboration between industry and academia to drive innovation, research, and technological advancements </p>

                                    <h3 style={styles.title}>Objectives</h3>
                                    <ul class="visbullets" style={styles.ulBulletes}>

                                        <li>Bridging the Industry-Academia Gap – Strengthening partnerships between academia and industries to address real-world challenges through research-driven solutions. </li>
                                        <li>Promoting Technology Transfer & Commercialization – Facilitating discussions on technology licensing, intellectual property rights, and startup incubation to promote commercialization of research outcome from NIT Warangal.</li>
                                        <li>Encouraging Industry-Sponsored Projects – Identifying areas for industry-funded research and consultancy projects, fostering collaboration on problem-solving initiatives relevant to industries. </li>
                                        <li>Startup & Entrepreneurship Development – Creating opportunities for startups, entrepreneurs, and innovators to network with industry leaders, investors, and policymakers for funding and mentorship.</li>
                                        <li>Panel Discussions & Expert Talks – Hosting discussions on latest technologies/Entrepreneurship</li>


                                    </ul>

                                    <h3 style={styles.title}> Highlights of the program</h3>
                                    <ul class="visbullets" style={styles.ulBulletes}>
                                        <li>Panel Discussions: Industry leaders and experts on emerging trends</li>
                                        <li>Startup Pitching Sessions: A platform for innovative startups to present their ideas</li>
                                        <li>Exhibition & Showcase: Cutting-edge solutions and technology displays</li>
                                        <li>Networking Sessions: Meet potential partners and collaborators</li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Objectives END */}


                    {/* ABOUT NITW START */}
                    <div className="tab-pane" id="tabpanel-5" role="tabpanel" aria-labelledby="tab-5">
                        <div className='row'>
                            <div className='col-md-12'>
                                <h3 style={styles.title}>About NIT Warangal</h3>
                                <p>National Institute of Technology Warangal (NIT Warangal) is one of India’s premier
                                    institutions for higher education and research. Known for its excellence in engineering,
                                    technology, and innovation, NIT Warangal fosters a dynamic ecosystem that bridges
                                    industry and academia, promoting sustainable growth and technological advancements.</p>
                                <p>For more details, please visit <a href='https://www.nitw.ac.in/' target='_blank'>https://www.nitw.ac.in/</a></p>
                            </div>
                        </div>
                    </div>
                    {/* ABOUT NITW END */}


                </div>



            </div>

        </>
    );



}

export default Iaic2025page
