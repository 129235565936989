export const CommitteData = [
  {
    id: "Institute Affairs Committee",
    title: "Institute Affairs Committee",
    multiple: "no",
    content: [
      { role: 'Chairman', name: 'Director', department: '' },
      { role: 'Member', name: 'Registrar', department: '' },
      { role: 'Member', name: 'All Deans', department: '' },
      { role: 'Member', name: 'BoG Members', department: '' },
      { role: 'Member', name: 'Chief Vigilance Officer', department: '' },
      { role: 'Member', name: 'Head, CCPD', department: '' },
      { role: 'Member', name: 'Superintending Engineer, Estate Office', department: '' },
      { role: 'Member', name: 'Professor In-charge, Guest House', department: '' },
      { role: 'Member', name: 'Chairperson, Space Allocation Committee', department: '' },
      { role: 'Member', name: 'Chairperson, Campus Development Committee', department: '' },
      { role: 'Member', name: 'Chairperson, Campus Business Committee', department: '' },
      { role: 'Member', name: 'Chairperson, Campus Waste Management Committee', department: '' },
      { role: 'Member', name: 'Chairperson, Campus Water Supply', department: '' },
      { role: 'Member', name: 'Chairperson, Institute Infrastructure Committee', department: '' },
      { role: 'Member', name: 'Chairperson, Classrooms & Facilities Committee', department: '' },
      { role: 'Member', name: 'Chairperson, House Allotment Committee  ', department: '' },
      { role: 'Member', name: 'Chairperson, Medical Management Committee', department: '' },
      { role: 'Member', name: 'Head, CRIF', department: '' },
      { role: 'Member', name: 'Head, CDIS', department: '' },
      { role: 'Member', name: 'Head, CTL', department: '' },
      { role: 'Member', name: 'Chief Warden, NITW Hostels ', department: '' },
      { role: 'Member', name: 'Security Officer', department: '' },
    ]
  },



  // 
  {
    id: "Institution Infrastructure Committee",
    title: "Institution Infrastructure Committee",
    multiple: "no",
    content: [
      { role: 'Chairman', name: 'Dean (Planning & Development)', department: '' },
      { role: 'Member', name: 'Professor I/c., Civil Maintenance', department: '' },
      { role: 'Member', name: 'Professor I/c., Electrical Maintenance ', department: '' },
      { role: 'Member', name: 'Chairman, Campus Development Committee ', department: '' },
      { role: 'Member', name: 'Chairman, Space Allocation Committee  ', department: '' },
      { role: 'Member', name: 'Head, Centre for Digital Infrastructure and Services ', department: '' },
      { role: 'Member', name: 'Internal Board of Governors Member-1', department: '' },
      { role: 'Member', name: 'Internal Board of Governors Member-2', department: '' },
      { role: 'Member', name: 'Registrar', department: '' },
      { role: 'Member', name: 'Chief Warden', department: '' },
      { role: 'Member', name: 'Executive Engineer (Civil), Estate Office', department: '' },
      { role: 'Member', name: 'Executive Engineer (Electrical), Estate Office   ', department: '' },
      { role: 'Convenor', name: 'Superintending & Engineer, Estate Office   ', department: '' },

    ]
  },

  //   
{
  id: "Space Allocation Committee",
  title: "Space Allocation Committee",
  multiple: "no",
  content: [
    { role: 'Chairman', name: 'Prof. Srinivasa Rao S', department: 'Mechanical Engineering' },
    { role: 'Member', name: 'Dean (Planning & Development)', department: '' },
    { role: 'Member', name: 'Prof. S. Venkateswara Rao', department: 'Civil Engineering' },
    { role: 'Member', name: 'Prof. P. Radha Krishna', department: 'Computer Science and Engineering' },
    { role: 'Member', name: 'Prof. Kasi Viswanadham', department: 'Mathematics' },
    { role: 'Member', name: 'Superintending Engineer, Estate Office', department: '' },
    
  ]
},
{
  id: "Internal Complaints Committee",
  title: "Internal Complaints Committee",
  multiple: "no",
  content: [
    { role: 'Chairperson', name: 'Prof. Hari Ponnamma Rani', department: 'Mathematics' },
    { role: 'Member', name: 'Prof. V. Rama Devi', department: 'Management Studies' },
    { role: 'Member', name: 'Prof. S. Anuradha', department: 'Electronics and Communication Engineering' },
    { role: 'Member', name: 'Prof. P. Hari Krishna', department: 'Civil Engineering' },
    { role: 'External Member', name: 'Dr. V. Usha Rani', department: 'Govt. Medical College (GMC), Narasampet, Telangana' },
    { role: 'Members', name: 'Three student council members', department: '' },
    
    
  ]
},
  // {
  //   id: "Quality Assurance Committee",
  //   title: "Quality Assurance Committee",
  //   multiple: "no",
  //   content: [
  //     { role: 'Chairperson', name: 'Prof. N. Venkatathri', department: 'Chemistry' },
  //     { role: 'Member', name: 'Prof. G. Uday Bhaskar', department: 'Chemical Engineering' },
  //     { role: 'Member', name: 'Prof. Porpandiselvi S', department: 'Electrical Engineering' },
  //     { role: 'Member', name: 'Prof. Himansu Shekhar Pradhan,', department: 'Electronics and Communication Engineering' },
  //     { role: 'Member', name: 'Prof. Ashish A Prabhu,', department: 'Biotechnology' },
  //     { role: 'Member', name: 'Prof. Sanghamitra Moharana,', department: 'Metallurgical and Materials Engineering' },
  //   ]
  // },
  {
    id: "Campus Business Committee",
    title: "Campus Business Committee",
    multiple: "no",
    content: [
      { role: 'Chairperson', name: 'Prof. Raghu Chitta', department: 'Chemistry' },
      { role: 'Member', name: 'Prof. K. Kaladhar', department: 'Mathematics' },
      { role: 'Member', name: 'Prof. E. Suresh Babu', department: 'Computer Science and Engineering' },
      { role: 'Member', name: 'Prof. P Sampath Kumar', department: 'Chemical Engineering' },
      { role: 'Member', name: 'Prof. P. Ramachandra Gopal', department: 'Management Studies' },
      { role: 'Member', name: 'Executive Engineer (Electrical)', department: 'Estate Office' },
      { role: 'Member', name: 'Assistant Engineer (Civil)', department: 'Estate Office' },
      { role: 'Convener', name: 'Deputy Registrar (P&S)', department: 'Deputy Registrar (P&S)' },
      
    ]
  },
    // {
    //   id: "Convocation",
    //   title: "Convocation",
    //   multiple: "yes",
    //   content: [
    //     {
    //       subcommittename: "CONVOCATION CORE COMMITTEE",
    //       subcommittecontent: [
    //         { role: 'Chairman', name: 'Prof. Bidyadhar Subudhi', department: 'Director' },
    //         { role: 'Member', name: 'Prof. A. Sarath Babu', department: 'Dean (AC)' },
    //         { role: 'Member', name: 'Prof. A. Venu Vinod', department: 'Dean (FW)' },
    //         { role: 'Member', name: 'Prof. S. Srinivasa Rao', department: 'Dean (IA)' },
    //         { role: 'Member', name: 'Prof. P. Ratish Kumar', department: 'Dean (PD)' },
    //         { role: 'Member', name: 'Prof. Sonawane Shirish', department: 'Dean (RD)' },
    //         { role: 'Member', name: 'Prof. D. Srinivasacharya', department: 'Dean (SW)' },
    //         { role: 'Member', name: 'Prof. N.V. Umamahesh', department: 'Registrar In-charge' },
    //         { role: 'Member', name: 'Prof. K. Kiran Kumar', department: 'PIC (Exams)' },
            
    //       ]
    //     },
    //     {
    //         subcommittename: "Certificates and Awards",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. BKN Srinivasa Rao", department: '' },
    //           { role: "Coordinator (Exams)", "name": "Prof. D. Bhargavi" , department: '' },
    //           { role: "Member", name: "Shri J. Bhaskar", "department": "Superintendent (Exams)" },
    //           { role: "Member", name: "Shri S. Venkateswarlu", "department": "Assistant (SG-I)" },
    //           { role: "Member", name: "Shri M. Sridhar Rao", "department": "Assistant (SG-I)" },
    //           { role: "Member", name: "Shri T. Dileep Kumar", "department": "Assistant (SG-I)" },
    //           { role: "Member", name: "Shri Y. Mahesh Kumar", "department": "Assistant (SG-II)" },
    //           { role: "Member", name: "Shri Md. Afzal", "department": "Assistant (SG-I)" },
    //           { role: "Member", name: "Shri Sambasivudu", "department": "Office Attendant" }
    //         ]
    //       },
    //       {
    //         subcommittename: "Registration",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. K.V.R. Ravi Shankar", department: "Associate Dean (PhD Program)" },
    //           { role: "Member", name: "Prof. B. Raghuram Kadali", department: " Civil Engineering" },
    //           { role: "Member", name: "Prof. JeyaSenthil", department: " Electrical Engineering" },
    //           { role: "Member", name: "Prof. Satyanand Abraham", department: " Mechanical Engineering" },
    //           { role: "Member", name: "Prof. Prakash Kodali", department: " Electronics and Communication Engineering" },
    //           { role: "Member", name: "Prof. Ch. Ramalingaswamy", department: " Computer Science and Engineering" },
    //           { role: "Member", name: "Prof. B. Srinivasa Rao", department: " Metallurgical and Materials Engineering" },
    //           { role: "Member", name: "Prof. B. Praveen Kumar", department: " Chemical Engineering" },
    //           { role: "Member", name: "Prof. K. Kaladhar", department: " Mathematics" },
    //           { role: "Member", name: "Prof. Sourabh Roy", department: " Physics" },
    //           { role: "Member", name: "Prof. G. Rajesh Khanna", department: " Chemistry" },
    //           { role: "Member", name: "Prof. Onkara Perumal", department: " Biotechnology" },
    //           { role: "Member", name: "Prof. R. Vennela", department: " Humanities and Social Sciences" },
    //           { role: "Member", name: "Prof. S. Lakshmi Tulasi Devi", department: " Management Studies" }
    //         ]
    //       },
          
    //       {
    //         subcommittename: "Seating Arrangement & Procession",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. R. Satish Babu", department: " Biotechnology" },
    //           { role: "Member", name: "Prof. P. Hari Prasad Reddy", department: " Civil Engineering" },
    //           { role: "Member", name: "Prof. U. Venkanna", department: " Computer Science and Engineering" },
    //           { role: "Member", name: "Prof. A. Leela Manohar", department: " Chemical Engineering" },
    //           { role: "Member", name: "Prof. V. Sreedevi", department: " Metallurgical and Materials Engineering" },
    //           { role: "Member", name: "Prof. Jew Das", department: " Civil Engineering" },
    //           { role: "Member", name: "Prof. Y. Sudheer Kumar", department: "NCC Officer,  Civil Engineering" },
    //           { role: "Member", name: "Dr. P. Prasanth", department: "Senior SAS Officer" },
    //         ]
    //       },
          
          
    //       {
    //         subcommittename: "Accommodation & Hospitality",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. P. Abdul Azeem", department: "Chief Warden" },
    //           { role: "Member", name: "Prof. Sanjit Biswas", department: "Professor In Charge (Guest House)" },
    //           { role: "Member", name: "Prof. J. Pranitha", department: " Mathematics" },
    //           { role: "Member", name: "Prof. P. Venkata Subba Reddy", department: " Computer Science and Engineering" },
    //           { role: "Member", name: "Prof. B. Venugopal Reddy", department: " Electrical Engineering" },
    //         ]
    //       },
          
    //       {
    //         subcommittename: "Convocation Report",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. Madhavi Reddy", department: " Humanities and Social Sciences" },
    //           { role: "Member", name: "Prof. Karthik Balasubramanian", department: " Mechanical Engineering" },
    //           { role: "Member", name: "Prof. Vishnu Shanker", department: " Chemistry" },
    //           { role: "Member", name: "Prof. Ajey Kumar Patel", department: " Civil Engineering" },
    //         ]
    //       },
          
    //       {
    //         subcommittename: "Procurement & Processing",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. Kasi Viswanadham KNS", department: " Mathematics" },
    //           { role: "Member", name: "Shri Vijay Anavaratham", department: "Deputy Registrar (FA)" },
    //           { role: "Member", name: "Shri V. Upendar", department: "Assistant Registrar (PS)" },
    //           { role: "Member", name: "Shri T. Sreenivas", department: "Assistant Registrar (Academics)" },
    //           { role: "Member", name: "Shri N. Nagaraju", department: "Assistant Registrar (Accounts)" },
    //           { role: "Member", name: "Shri Ashok Kasthuri", department: "Assistant Registrar (IA)" },
    //           { role: "Member", name: "Shri Sathish Mandala", department: "Superintendent (PS)" },
    //           { role: "Member", name: "Shri Ashwin Kumar Dave", department: "Senior Assistant (IA)" },
    //           { role: "Member", name: "Shri Nitin Raj", department: "Junior Assistant (IA)" },
    //         ]
    //       },
          
    //       {
    //         subcommittename: "Invitation, Protocol & Reception",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. V. Ramadevi", department: " Management Studies" },
    //           { role: "Member", name: "Prof. Spoorthi", department: " Humanities and Social Sciences" },
    //           { role: "Member", name: "Prof. Bhargava M", department: " Mechanical Engineering" },
    //           { role: "Member", name: "Prof. Vijay Choyal", department: " Mechanical Engineering" },
    //         ]
    //       },
          
    //     {
    //       subcommittename: "Medals",
    //       subcommittecontent: [
    //         { role: 'Convener', name: 'Prof. J V Ramana Murthy', department: 'Mathematics' },
    //         { role: 'Member', name: 'Prof. Syed Ismail', department: 'Associate Dean (UG Programs)' },
    //         { role: 'Member', name: 'Shri T. Sreenivas', department: 'AR (Acad.)' },
    //         { role: 'Member', name: 'Shri A. Sravan', department: 'Jr. Asst. (PS)' },

    //       ]
    //     },{
    //         subcommittename: "Website, Internet & Live Streaming",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. Rashmi Ranjan Rout", department: "Head, Centre for Digital Infrastructure and Services" },
    //           { role: "Member", name: "Prof. Ch. Sudhakar", department: " Computer Science and Engineering" },
    //           { role: "Member", name: "Prof. Sanjay Kumar Panda", department: " Computer Science and Engineering" },
    //           { role: "Member", name: "Shri P. Bhargava Reddy", department: "Senior Technical Officer, Centre for Digital Infrastructure and Services" },
    //           { role: "Member", name: "Shri D. Govinda Rao", department: "Technical Officer, Centre for Digital Infrastructure and Services" },
    //           { role: "Member", name: "Shri Sudipta Manna", department: "Technical Officer, Centre for Digital Infrastructure and Services" }
    //         ]
    //       },
          
          
    //       {
    //         subcommittename: "Multimedia & Audio Visual",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. L. Anjaneyulu", department: " Electronics and Communication Engineering" },
    //           { role: "Member", name: "Prof. P. Muralidhar", department: "Vice President (F&M)" },
    //           { role: "Member", name: "Prof. M. Satish", department: " Electronics and Communication Engineering" },
    //           { role: "Member", name: "Shri K. Vijay Kumar", department: "Technical Assistant, AV Section" }
    //         ]
    //       },
          
    //       {
    //         subcommittename: "Press & Media",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. T. Rahul", department: "Public Relations Officer" },
    //           { role: "Member", name: "Prof. Iranna M. Shettar", department: "Deputy Librarian" },
    //           { role: "Member", name: "Prof. K.S. Raj Mohan", department: " Chemical Engineering" }
    //         ]
    //       },
          
    //       {
    //         subcommittename: "Pre-Convocation Cultural Programme",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. P. Hari Krishna", department: "President, (SAC)" },
    //           { role: "Member", name: "Prof. P. Prithvi", department: " Electronics and Communication Engineering" },
    //           { role: "Member", name: "Prof. Thyageshwar Chandran", department: " Biotechnology" }
    //         ]
    //       },
          
    //       {
    //         subcommittename: "Venue Preparation",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. Venkaiah Chowdary", department: " Civil Engineering" },
    //           { role: "Member", name: "Prof. S. Venkateswara Rao", department: " Civil Engineering" },
    //           { role: "Member", name: "Shri Tamma Venugopal Reddy", department: "SE,EMU" },
    //           { role: "Member", name: "Prof. Prashanth Anand Kr.Lam", department: " Mechanical Engineering" },
    //           { role: "Member", name: "Shri G. Ramesh", department: " EE (Civil),EMU" },
    //           { role: "Member", name: "Shri Pelluru Suresh", department: "EE (Electrical),EMU" }
    //         ]
    //       },
          
    //       {
    //         subcommittename: "Transportation & Logistics",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. T. Sadasiva Rao", department: "Professor In Charge (MT Section)" },
    //           { role: "Member", name: "Shri Peela Krishna Chaitanya", department: "Assistant Registrar (Administration)" }
    //         ]
    //       },
          
    //       {
    //         subcommittename: "Health Care",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. N. Narasaiah", department: "Chairman, Medical Management" },
    //           { role: "Member", name: "Dr. Radha Rukmini", department: "Senior Medical Officer" },
    //           { role: "Member", name: "Dr. Ch. Karthik", department: "Medical Officer" },
    //           { role: "Member", name: "Dr. Pradeep Dodda", department: "Medical Officer" },
    //           { role: "Member", name: "Dr. Anchoori Karthik", department: "Medical Officer" }
    //         ]
    //       },
          
          
          
          
    //       {
    //         subcommittename: "Security & Safety Arrangements",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. J. Ravi Kumar", department: "Professor In Charge (Security),  Electronics and Communication Engineering" },
    //           { role: "Member", name: "Shri Pellulu Suresh", department: "EE (Electrical), EMU" },
    //           { role: "Member", name: "Shri S. Venugopal", department: "Technician (SG-I)" }
    //         ]
    //       },
          
    //       {
    //         subcommittename: "Electricity, AC & Fans",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. B.L. Narasimha Raju", department: "Professor In Charge (Electrical),  Electrical Engineering" },
    //           { role: "Member", name: "Prof. Prashanth Anand Kr.Lam", department: " Mechanical Engineering" },
    //           { role: "Member", name: "Shri Pelluru Suresh", department: "EE (Electrical), EMU" }
    //         ]
    //       },
          
          
    //       {
    //         subcommittename: "Campus Cleaning & Hygiene",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. S. Shankar", department: "Professor In Charge (Campus Hygiene)" },
    //           { role: "Member", name: "Prof. E. Satyanarayana", department: " Mathematics" },
    //           { role: "Member", name: "Prof. Tapan Kumar Sahoo", department: " Computer Science and Engineering" }
    //         ]
    //       },
          
          
    //       {
    //         subcommittename: "Convocation Dress",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. D. Jaya Krishna", department: " Mechanical Engineering" },
    //           { role: "Member", name: "Prof. P. Prithvi", department: " Electronics and Communication Engineering" },
    //           { role: "Member", name: "Prof. Manish Kumar Bajpai", department: " Computer Science and Engineering" },
    //           { role: "Member", name: "Prof. Urmila Saxena", department: " Biotechnology" }
    //         ]
    //       },
          
          
    //       {
    //         subcommittename: "Arrangements for VIPs & Special Invitees",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. N.V. Uma Mahesh", department: "Registrar In-charge" },
    //           { role: "Member", name: "Prof. Sanjit Biswas", department: "Professor In Charge (Guest House)" },
    //           { role: "Member", name: "Shri Devkate Gajanan V", department: "Assistant Registrar (Directorate)" },
    //           { role: "Member", name: "Shri P. Aravind", department: "Superintendent (Administration)" }
    //         ]
    //       },
          
    //       {
    //         subcommittename: "Convocation Lunch",
    //         subcommittecontent: [
    //           { role: "Convener", name: "Prof. G. Raghavendra", department: "Professor In Charge - Mess,  Mechanical Engineering" },
    //           { role: "Member", name: "Prof. G. Siva Kumar", department: "Professor In Charge - Mess,  Electrical Engineering" },
    //           { role: "Member", name: "Prof. A. Ramya", department: " Chemical Engineering" }
    //         ]
    //       },
    //   ]
    // },
    
 
    // {
    //   id: "Faculty Club committee",
    //   title: "Faculty Club committee",
    //   multiple: "no",
    //   content: [
    //     { role: 'Professor', name: 'John Doe', department: 'Computer Science' },
    //     { role: 'Professor', name: 'Jane Doe', department: 'Electrical Engineering' }
    //   ]
    // },
  ];
  